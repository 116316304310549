import React from "react";
import {
  Flex,
  VStack,
  HStack,
  Text,
  Button,
  IconButton,
} from "@chakra-ui/react";
import {
  FaWhatsapp
} from "react-icons/fa";
import { FaEarthAmericas } from "react-icons/fa6";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import {
  MdOutlineApartment,
  MdOutlineCalendarToday,
  MdOutlineMail,
  MdOutlinePhoneIphone,
} from "react-icons/md";

export const InformationSection = () => {
  const { userSelected } = useAuth();

  const calculateAge = (birthdate: string) => {
    const birthDate = new Date(birthdate);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return "Não informado";

    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleWhatsappRedirect = () => {
    const phoneNumber = userSelected?.contato?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const message = `Olá ${userSelected?.nome}, como vai?`;
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <Flex
      align={"flex-start"}
      p={[2, 5]}
      bg="white"
      w="100%"
      borderWidth="1px"
      borderRadius="lg"
    >
      <VStack align="start" spacing={3} w="100%">
        <HStack
          align="center"
          direction={{ base: "column", md: "row" }}
          justify={"flex-start"}
          w="100%"
          justifyContent="space-between"
        >
          <Text fontSize="xl" fontWeight="bold" mb={3}>
            Informações
          </Text>
          {userSelected?.contato && (
            <Button
              ml={{ base: 0, md: "auto" }}
              mt={{ base: 3, md: 0 }}
              leftIcon={<FaWhatsapp />}
              bg="xmentor.primary"
              colorScheme="primary"
              size="sm"
              onClick={handleWhatsappRedirect}
            >
              Entre em contato
            </Button>
          )}
        </HStack>
        <VStack align="start" spacing={3} w="100%">
          <Flex align="center" w="100%" gap={2}>
            <IconButton
              aria-label=""
              bgColor={"white"}
              color="xmentor.text_gray"
              border="1px"
              borderColor={"xmentor.borderCards"}
              size={"sm"}
              icon={<MdOutlinePhoneIphone size={"20px"} />}
            />
            <HStack w="100%" justifyContent="space-between">
              <Text fontSize={"sm"} color="xmentor.text_gray">
                Telefone
              </Text>
              <Text fontSize={"sm"}>{userSelected?.contato}</Text>
            </HStack>
          </Flex>
          <Flex align="center" w="100%" gap={2}>
            <IconButton
              aria-label=""
              bgColor={"white"}
              color="xmentor.text_gray"
              border="1px"
              borderColor={"xmentor.borderCards"}
              size={"sm"}
              icon={<MdOutlineMail size={"20px"} />}
            />
            <HStack w="100%" justifyContent="space-between">
              <Text fontSize={"sm"} color="xmentor.text_gray">
                Email
              </Text>
              <Text fontSize={"sm"}>{userSelected?.email}</Text>
            </HStack>
          </Flex>
          <Flex align="center" w="100%" gap={2}>
            <IconButton
              aria-label=""
              bgColor={"white"}
              color="xmentor.text_gray"
              border="1px"
              borderColor={"xmentor.borderCards"}
              size={"sm"}
              icon={<MdOutlineCalendarToday size={"20px"} />}
            />
            <HStack w="100%" justifyContent="space-between">
              <Text fontSize={"sm"} color="xmentor.text_gray">
                Aniversário
              </Text>
              <HStack spacing={1}>
                <Text fontSize={"sm"} color={"xmentor.text_gray"}>
                  {userSelected&&userSelected.data_nascimento
                    ? calculateAge(String(userSelected?.data_nascimento)) +
                      " Anos | "
                    : "Não informado"}
                </Text>
                <Text fontSize={"sm"}>
                  {formatDate(String(userSelected?.data_nascimento))}
                </Text>
              </HStack>
            </HStack>
          </Flex>
          <Flex align="center" w="100%" gap={2}>
            <IconButton
              aria-label=""
              bgColor={"white"}
              color="xmentor.text_gray"
              border="1px"
              borderColor={"xmentor.borderCards"}
              size={"sm"}
              icon={<MdOutlineApartment size={"20px"} />}
            />
            <HStack w="100%" justifyContent="space-between">
              <Text fontSize={"sm"} color="xmentor.text_gray">
                Empresa
              </Text>
              <Text fontSize={"sm"}>
                {/* <IconButton
                  aria-label=""
                  mr={2}
                  bgColor={"white"}
                  color="xmentor.text_gray"
                  border="1px"
                  borderColor={"xmentor.borderCards"}
                  size={"sm"}
                  icon={<FaEarthAmericas />}
                /> */}
                {userSelected?.nome_empresa}
              </Text>
            </HStack>
          </Flex>
        </VStack>
      </VStack>
    </Flex>
  );
};

export default InformationSection;
