import React, { useState } from "react";
import {
  Avatar,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Icon,
  MenuGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { FaGear } from "react-icons/fa6";
import { useMentoria } from "../../../../context/MentoriaProvider/useMentoria";
import { IItemMentorado } from "..";
import { useNavigate } from "react-router-dom";

export function ItemMentorado({
  foto,
  nome,
  mentoria_id,
  usuario_id,
  etapa_id,
}: IItemMentorado) {
  const navigate = useNavigate();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const iconRotation = isMenuExpanded ? 180 : 0;
  const { etapasMentoria, AvancarEtapaMentoria } = useMentoria();

  const handleMenuToggle = () => {
    setIsMenuExpanded(!isMenuExpanded);
  };

  const handleClickItem = () => {
    navigate("/admin/users/view", {
      state: {
        id: usuario_id,
        nome: nome,
        email: "",
        foto: foto,
        perfil: "Mentorado",
      },
    });
  };

  return (
    <HStack
      cursor={"pointer"}
      onClick={handleClickItem}
      w="100%"
      borderRadius={"14px"}
      justifyContent="space-between"
      transition="0.4s"
      _hover={{ backgroundColor: "gray.100" }}
    >
      <HStack p={2} w="full">
        <Avatar
          mr="2"
          borderRadius={"14px"}
          src={`${process.env.REACT_APP_API}/tmp/user/${foto}`}
          h={"72px"}
          w="72px"
        />
        <Text color={"xmentor.cardsment"} fontSize={"15px"}>
          {nome}
        </Text>
      </HStack>
      <Menu size="sm">
        <MenuButton
          variant={"ghost"}
          size={"sm"}
          as={Button}
          display={"flex"}
          alignItems={"center"}
          onClick={(e) => {
            // Ignora o clique da HStack
            e.stopPropagation();
            handleMenuToggle();
          }}
        >
          <HStack>
            <Icon
              transition={"0.7s"}
              as={FaGear}
              boxSize={"20px"}
              transform={`rotate(${iconRotation}deg)`}
            />
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuGroup title="Mover mentorado">
            <MenuDivider />
            {etapasMentoria &&
              etapasMentoria.map((i, index) => (
                <MenuItem
                  key={index}
                  px={4}
                  fontSize={"16px"}
                  onClick={(e) => {
                    AvancarEtapaMentoria(
                      etapa_id,
                      mentoria_id,
                      i.id,
                      usuario_id
                    );
                    e.stopPropagation();
                  }}
                >
                  {i.titulo}
                </MenuItem>
              ))}
          </MenuGroup>
        </MenuList>
      </Menu>
    </HStack>
  );
}
