import React, { useState } from "react";
import {
  VStack,
  HStack,
  Text,
  IconButton,
  Box,
  Button,
} from "@chakra-ui/react";
import { MdModeEditOutline } from "react-icons/md";

type AboutProps = {
  description: string;
  handleOpenSobre: () => void;
};

const AboutSection = ({ description, handleOpenSobre }: AboutProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <VStack
      align={"flex-start"}
      p={[2, 5]}
      bg="white"
      w="100%"
      minH={"128px"}
      borderWidth="1px"
      borderRadius="lg"
      position="relative"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize="xl" fontWeight="bold" mb={3}>
          Sobre
        </Text>
        <IconButton
          colorScheme="primary"
          bgColor={"xmentor.primary"}
          color={"white"}
          fontSize="20px"
          onClick={handleOpenSobre}
          icon={<MdModeEditOutline />}
          aria-label="Add"
          ml={{ base: 0, md: "auto" }}
          mt={{ base: 4, md: 0 }}
        />
      </HStack>
      <Box
        w="100%"
        maxH={isExpanded ? "none" : "4.5em"}
        overflow="hidden"
        position="relative"
        sx={{
          maskImage: !isExpanded
            ? "linear-gradient(to bottom, black 50%, transparent 100%)"
            : "none",
          transition: "max-height 0.5s ease",
        }}
      >
        <Text
          fontSize={"sm"}
          fontWeight="400"
          color={"xmentor.text_gray"}
          whiteSpace="pre-wrap"
          wordBreak="break-word"
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: isExpanded ? "none" : 3,
          }}
        >
          {description}
        </Text>
      </Box>
      {description.length > 0 && !isExpanded && (
        <Button
          mt="auto"
          onClick={toggleExpanded}
          variant="link"
          color="xmentor.primary"
        >
          Ler mais
        </Button>
      )}
      {isExpanded && (
        <Button
          mt="auto"
          onClick={toggleExpanded}
          variant="link"
          color="xmentor.primary"
        >
          Mostrar menos
        </Button>
      )}
    </VStack>
  );
};

export default AboutSection;
