import * as React from "react"
import { DynamicThemeProvider} from "./style/theme"
import { BrowserRouter } from "react-router-dom"
import { Router } from "./routes"
import { AuthProvider } from "./context/AuthProvider"


export const App = () => {
  return(
    <BrowserRouter>
      <DynamicThemeProvider>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </DynamicThemeProvider>
    </BrowserRouter>
)}
