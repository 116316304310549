import React, { useEffect, useRef, useState } from "react";
import { Button, Stack } from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { TemplateAdmin } from "../../../../components/layout/admin/TemplateAdmin";
import { BodyContent } from "../../../../components/content/body";
import { Input } from "../../../../components/Form/Input";
import { IEvento, INewEvento } from "../../../../context/EventoProvider/types";
import { EventoProvider } from "../../../../context/EventoProvider";
import { useEvento } from "../../../../context/EventoProvider/useEvento";

function RegisterNewEventoChildren() {
  const { CriarEvento } = useEvento();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { payLoad } = useAuth();

  const [inputData, setInputData] = useState<INewEvento>({
    nome: "",
    data_evento: "",
    descricao_instrucoes: "",
    duracao: 0,
    mentoria_id: payLoad?.user.mentoria_id || "",
    usuario_criador_id: payLoad?.user.id || "",
  });

  const handleInputChange = (field: keyof IEvento, value: string) => {
    setInputData((prevData) => ({ ...prevData, [field]: value }));
  };
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await CriarEvento(inputData);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error in handleRegister:", err);
    }
  };

  return (
    <TemplateAdmin>
      <BodyContent>
        <Stack spacing="4" display="flex" w="100%">
          <Input
            type="text"
            label="Nome do evento"
            name="nome"
            required
            value={inputData.nome}
            onChange={(e) => handleInputChange("nome", e.target.value)}
          />

          <Input
            type="text"
            label="Descrição"
            name="descricao_instrucoes"
            maxLength={100}
            required
            value={inputData.descricao_instrucoes}
            onChange={(e) =>
              handleInputChange("descricao_instrucoes", e.target.value)
            }
          />
          <Input
            type="datetime-local"
            label="Data do evento"
            name="data_evento"
            required
            value={inputData.data_evento}
            onChange={(e) => handleInputChange("data_evento", e.target.value)}
          />

          <Input
            type="number"
            label="Duração (min)"
            name="duracao"
            required
            value={inputData.duracao}
            maxLength={3}
            onChange={(e) =>
              setInputData((prevData) => ({
                ...prevData,
                duracao: Number(e.target.value),
              }))
            }
          />

          <Stack direction="row" spacing={4} mt={4} justifyContent="flex-end">
            <Button variant="outline" size="lg" type="reset">
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              colorScheme="blue"
              size="lg"
              isLoading={isSubmitting}
            >
              Continuar
            </Button>
          </Stack>
        </Stack>
      </BodyContent>
    </TemplateAdmin>
  );
}

export function RegisterNewEvento() {
  return (
    <EventoProvider>
      <RegisterNewEventoChildren />
    </EventoProvider>
  );
}
