import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  VStack,
  Text,
  IconButton,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  Box,
  useTheme,
} from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { UploadAvatar } from "../../../../components/avatarUpload";
import { IModalUpload } from "../view";
import { CustomModalOverlay } from "../../trilha/components/CustomModalOverlay";
import { FaRegTrashAlt } from "react-icons/fa";

export function ModalUploadAnexo({ isOpen, onClose, usuario_cliente_id }: IModalUpload) {
  const [files, setFiles] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const { UploadAnexo, payLoad } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const toast = useToast();
  const theme = useTheme();
  const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;

  // Permitir múltiplos arquivos
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

    // Gerar pré-visualizações
    selectedFiles.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setPreviewUrls((prevUrls) => [...prevUrls, fileReader.result as string]);
      };
    });
  };

  const handleRemove = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviewUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  // Função de upload ajustada para múltiplos arquivos com progresso
  async function handleUpload() {
    if (files.length > 0 && payLoad) {
      setIsLoading(true);
      try {
        const totalProgressPerFile = 100 / files.length;

        for (let i = 0; i < files.length; i++) {
          const formDataWithImage = new FormData();
          formDataWithImage.append("usuario_cliente_id", usuario_cliente_id);
          formDataWithImage.append("usuario_criador_id", String(payLoad?.user.id));
          formDataWithImage.append("file", files[i]);

          // Simular atraso para fins de demonstração
          await new Promise((resolve) => setTimeout(resolve, 500));

          // Chamar a API para upload
          await UploadAnexo(formDataWithImage, usuario_cliente_id);

          // Atualizar progresso
          setUploadProgress((i + 1) * totalProgressPerFile);
        }

        toast({
          position: "top",
          description: "Upload de arquivos com sucesso",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        handleRemoveAll(); // Limpar arquivos após upload
        onClose();
      } catch (error) {
        toast({
          position: "top",
          description: "Erro ao cadastrar",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        console.error("Erro no upload:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const handleRemoveAll = () => {
    setFiles([]);
    setPreviewUrls([]);
    setUploadProgress(0);
  };

  useEffect(() => {
    if (!isOpen) {
      handleRemoveAll();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <CustomModalOverlay />
      <ModalContent>
        <ModalHeader>Novo anexo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>

          {/* Barra de progresso centralizada e sobreposta */}
          {isLoading && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="fixed"
              top={0}
              bottom={0}
              left={0}
              right={0}
              zIndex={51}
              bg="rgba(0, 0, 0, 0.7)"
            >
              <Box mt="4" textAlign="center" bg="white" rounded={10}>
                <CircularProgress value={uploadProgress} color="green" size="120px">
                  <CircularProgressLabel>{uploadProgress.toFixed(0)}%</CircularProgressLabel>
                </CircularProgress>
              </Box>
            </Box>
          )}

          <UploadAvatar
            bg="white"
            borderColor="black"
            brandColor="black"
            file={null} // Não usamos diretamente o file
            previewUrl={null} // Não usamos diretamente o previewUrl
            handleChange={handleChange}
            handleRemove={() => { }} // Remoção feita separadamente
            typesFile="*"
            isFile
            multiple // Adiciona a capacidade de selecionar múltiplos arquivos
          />

          {/* Lista de arquivos */}
          <VStack w="100%" spacing="10px">
            {files.map((file, index) => (
              <Box w="100%" key={index}>
                <HStack w="100%" pt={2} spacing={4} justifyContent="space-between">
                  <Text>{file.name}</Text>
                  <IconButton
                    icon={<FaRegTrashAlt />}
                    aria-label="Remover anexo"
                    onClick={() => handleRemove(index)}
                  />
                </HStack>
              </Box>
            ))}
          </VStack>


        
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="outline" size="lg" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            isDisabled={files.length === 0}
            // colorScheme="blue"
            background= {theme.colors.xmentor.primary}
            _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
            size="lg"
            onClick={handleUpload}
            isLoading={isLoading}
          >
            Enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
