import { Input, InputGroup, InputLeftElement, InputProps } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

interface Props extends InputProps {}
export function InputSearch({...rest}:Props){


    return(
        <InputGroup>
            <InputLeftElement pointerEvents='none'>
                <FiSearch color='gray.300' />
            </InputLeftElement>
            <Input
                size='xs'
                fontSize='16px'
                p={5}
                autoComplete="off"
                focusBorderColor="blue.500"
                borderColor="gray.500"
                type={"text"}
                maxLength={255}
                rounded={10}
                paddingLeft={10}
                bg="xmentor.background_content"
                {...rest}
            />
        </InputGroup>
    )
}