import { Box, Flex, Switch, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface IConfigOptionCard {
  children : ReactNode;
  value : boolean;
  cb : (e: boolean) => void;
}

export default function EmailOptionCard(props: IConfigOptionCard) {
  
  const onSwitch = () => {
    props.cb(!props.value)
  };

  return (
    <Flex
      w={"100%"}
      h={"64px"}
      p={'24px'}
      justifyContent={"space-between"}
      display={"flex"}
      borderRadius={"md"}
      bgColor={"#fff"}
      alignItems={"center"}
      gap={"32px"}
    >
        <Text /*fontFamily={'Rubik'}*/ fontWeight={"400"} lineHeight={{base : '16p' , md: "22px"}}>
          {props.children}
        </Text>
        <Switch />
    </Flex>
  );
}
