import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { CalendarDefaultTheme } from "@uselessdev/datepicker";
import { useEffect, useState } from "react";
import { IAuthProvider } from "../context/AuthProvider/types";

// Função para obter as cores do localStorage
const getColorsFromLocalStorage = () => {
  const storedColors = localStorage.getItem("xmentor-color-theme");
  if (storedColors) {
    console.log(storedColors)
    return String(storedColors) || "";
  }
  return "";
};

const darkenColor = (color: string, factor: number) => {
  const hex = color.replace("#", "");
  const r = Math.max(0, Math.min(255, parseInt(hex.substring(0, 2), 16) - factor * 255)).toString(16).padStart(2, '0');
  const g = Math.max(0, Math.min(255, parseInt(hex.substring(2, 4), 16) - factor * 255)).toString(16).padStart(2, '0');
  const b = Math.max(0, Math.min(255, parseInt(hex.substring(4, 6), 16) - factor * 255)).toString(16).padStart(2, '0');
  return `#${r}${g}${b}`;
};


// Função para criar tema dinâmico
const createTheme = (colors: string) => {

  const primaryColor = colors || "#4963FF";
  const darkenedPrimaryColor = darkenColor(primaryColor, 0.2); // Escurece em 20%

  const lightTheme = {
    config: {
      initialColorMode: "light",
      useSystemColorMode: false,
    },
    colors: {
      xmentor: {
        background_template: "#F2F4F7",
        background_content: "#FFF",
        primary: colors || "#4963FF",
        text_gray: "#8A8490",
        cardsment: "#2D2B2E",
        primaryDark: darkenedPrimaryColor, 
        bgInputText: "#F2F4F7",
        bgColorBorder: "#CCD0D8",
        text_white: "#FFF",
        text_black: "#1A181B",
        text_tab: "#344054",
        blueCards: colors || "#3345B2",
        borderCards: "#EAECF0",
      },
    },
    fonts: {
      heading: "Poppins, sans-serif",
      body: "Poppins, sans-serif",
    },
  };

  return extendTheme(CalendarDefaultTheme, {
    ...lightTheme, // Usar o tema light como base

    // Adicionar ou sobrescrever propriedades específicas do tema aqui, se necessário

    // Adicionar o modo escuro como uma variante
    shadows: {
      dark: "0 0 10px rgba(0, 0, 0, 0.5)", // Adicione sombras específicas do modo escuro
    },
  });
};

// Componente que gerencia o tema dinâmico
export const DynamicThemeProvider = ({ children }: IAuthProvider) => {
  const [theme, setTheme] = useState(createTheme(getColorsFromLocalStorage()));

  // Atualizar o tema sempre que houver alteração no localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      setTheme(createTheme(getColorsFromLocalStorage()));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Renderizar os componentes filhos com o tema atualizado(
  return (<ChakraProvider theme={theme}> {children} </ChakraProvider>);
};
