import React, { useState } from "react";
import {
  Button, Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Circle
} from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { CustomModalOverlay } from "../../trilha/components/CustomModalOverlay";
import { IoWarningOutline } from "react-icons/io5";
import { IModalDeleteAnotacoes } from "../view";

export function ModalDeleteAnotacoes({ isOpen, onClose, id, usuario_cliente_id }: IModalDeleteAnotacoes) {
  const { DeletarAnotacoes } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  async function handleDelete() {
    setIsLoading(true);
    await DeletarAnotacoes(id, usuario_cliente_id);
    onClose();
    setIsLoading(false);
  }
  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <CustomModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" justifyContent="flex-start" alignContent="flex-start" alignItems="flex-start">
          <Circle bg="yellow.100" p={2}>
            <IoWarningOutline color="red" size={28} />
          </Circle>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Deseja realmente excluir a anotação?
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="outline" size="lg" onClick={onClose}>
            Cancelar
          </Button>
          <Button colorScheme="blue" size="lg" onClick={handleDelete} isLoading={isLoading}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
