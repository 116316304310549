import { Flex, HStack, Box, Text, Button } from "@chakra-ui/react";
import React, { ChangeEvent } from "react";
import { FaPaperclip } from "react-icons/fa";

export interface IDocumentsCard {
  name: string;
  payment: string;
  fileName: string;
  fileSize: string;
  url?: string;
}

export default function DocumentsCard(props: IDocumentsCard) {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    //Colcar logica para enviar arquivos
    if (file) {
      console.log("Arquivo selecionado:", file);
    }
  };

  return (
    <Flex
      w={"100%"}
      bgColor={"#fff"}
      flexDir={"column"}
      alignSelf={"stretch"}
      rounded={"lg"}
      padding={"24px"}
      gap={"24px"}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleUpload}
      />
      <Box>
        <Text /*fontFamily={'Rubik'}*/ fontWeight={"500"} lineHeight={"22px"}>
          {props.name}
        </Text>
        <Text
          /*fontFamily={'Rubik'}*/ lineHeight={"22px"}
          color={"rgba(175, 175, 175, 1)"}
        >
          {props.payment}
        </Text>
      </Box>
      <HStack w="100%" gap={"18px"}>
        <Button variant={"unstyled"} onClick={handleButtonClick}>
          <FaPaperclip size={"18px"} color={"rgba(175, 175, 175, 1)"} />
        </Button>
        <Box>
          <Text
            /*fontFamily={'Rubik'}*/ lineHeight={"22px"}
            color={"rgba(175, 175, 175, 1)"}
          >
            {props.fileName}
          </Text>
          <Text
            /*fontFamily={'Rubik'}*/ lineHeight={"22px"}
            color={"rgba(175, 175, 175, 1)"}
          >
            {props.fileSize}
          </Text>
        </Box>
      </HStack>
    </Flex>
  );
}
