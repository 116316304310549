import {
  Button,
  Box,
  HStack,
  VStack,
  Text,
  Image,
  useToast,
  Flex,
  IconButton,
  Center,
  background,
} from "@chakra-ui/react";
import { ResultRouteProps } from "../../view";
import { UploadBackground } from "../../components/UploadBackground";
import { IUser } from "../../../../../context/AuthProvider/types";
import { MdModeEditOutline } from "react-icons/md";
import { useState } from "react";
import ImageEditModal from "../../../../../components/avatarUpload/ModalEditImage";
import { useAuth } from "../../../../../context/AuthProvider/useAuth";
import InformationSectionMentor from "../../section/InformationSessionMentor";
import { ModalUpdateSocialNetwork } from "./MoldalUpdateSocialNetwork";
import { ModalUpdateDataUser } from "../../components/MoldalUpdateDataUser";

interface MentorProfileType {
  resultRoute: ResultRouteProps;
}

const MentorProfileSection = ({ resultRoute }: MentorProfileType) => {
  const { userSelected, payLoad, UploadAvatar } = useAuth();
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isHovered, setIsHovered] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const toast = useToast();

  const handleImageClick = () => {
    setImagePreview(
      `${process.env.REACT_APP_API}/tmp/user/${userSelected?.foto}`
    );
    setIsEditOpen(true);
  };

  const handleSave = async (file: File) => {
    const formDataWithImage = new FormData();
    formDataWithImage.append("usuario_cliente_id", userSelected?.id || "");
    formDataWithImage.append("usuario_criador_id", String(payLoad?.user.id));
    formDataWithImage.append("file", file);
    try {
      await UploadAvatar(userSelected?.id || "", formDataWithImage);
      toast({
        title: "Avatar atualizado com sucesso.",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erro ao atualizar avatar.",
        status: "error",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsEditOpen(false);
    }
  };
  const handleOpenModal = () => {
    setIsOpenModal(!isOpenModal)
  }
  return (
    <>
      <VStack
        align={"flex-start"}
        p={0}
        bg="white"
        w="100%"
        minH={"128px"}
        borderWidth="1px"
        borderRadius="lg"
        position="relative"
      >
        {/* <Text fontWeight={"bold"} fontSize={"lg"}>
          Mentor
        </Text> */}
        <VStack w="full" spacing={0} p='0' borderRadius={'lg'}>
          <UploadBackground
            urlImage={userSelected ? userSelected.imagem_background : ""}
            usuario_cliente_id={resultRoute.id}
          />
          <VStack w='100%' align={'start'}>

            <Box
              w={["100px", "170px"]}
              h={["100px", "170px"]}
              alignSelf={"start"}
              mt={["-50px", "-100px"]}
              zIndex={2}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Image
                border={'5px solid white'}
                w={"full"}
                h={"full"}
                alignSelf={"start"}
                rounded={"md"}
                borderRadius={'full'}
                ml={10}
                transition={'0.3s all'}
                _hover={{
                  filter: "brightness(1.03)",
                  transform: 'scale(1.04)',
                }}
                _active={{ transform: 'scale(0.98)' }}
                src={`${process.env.REACT_APP_API}/tmp/user/${userSelected?.foto}`}
                cursor="pointer"
                onClick={handleImageClick}
              />
            </Box>
            <HStack w="100%" justifyContent="space-between" pr="28px" pb="8px">
              <InformationSectionMentor />
              <IconButton
                colorScheme="primary"
                bgColor={"xmentor.primary"}
                color={"white"}
                fontSize="20px"
                onClick={handleOpenModal}
                icon={<MdModeEditOutline />}
                aria-label="Add"
              />
            </HStack>
          </VStack>         
        </VStack>
      </VStack>
      <ImageEditModal
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        imagePreview={null}
        handleSave={handleSave}
      />
      <ModalUpdateDataUser
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        usuario_cliente_id={resultRoute.id}
      />
      
     
    </>
  );
};

export default MentorProfileSection;
