import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  Icon,
  MenuGroup,
  MenuDivider,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { FaEllipsisVertical } from "react-icons/fa6";
import { ICardStep } from "..";
import { useMentoriaGestao } from "../../../../context/MentoriaGestaoProvider/useMentoriaGestao";

export function CardStep({
  title,
  qtl,
  mentoria_id,
  etapa_id,
  children,
  edit,
  index,
  isDraggingOver,
  CheckListGestao
}: ICardStep) {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { AlterarEtapaMentoria, DeletarEtapaMentoria } = useMentoriaGestao();

  const [isEdit, setIsEdit] = useState(false);
  const [newTitle, setNewTitle] = useState(title);

  const update = () => {
    setIsUpdating(true);
    AlterarEtapaMentoria(mentoria_id, etapa_id, newTitle);
    setIsEdit(false);
    setIsUpdating(false);
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleDeleteClick = () => {
    setIsUpdating(true);
    DeletarEtapaMentoria(mentoria_id, etapa_id);
    setIsUpdating(false);
  };

  return (
    <VStack align="center" >
      <VStack
        borderRadius={"14px"}
        w={{ base: "100%", md: "auto" }}
        bgColor={"xmentor.background_content"}
        p={"16px"}
        borderColor={"xmentor.borderCards"}
        maxH="74vh"
        mb={5}
        overflow="hidden"

        border={isDraggingOver ? "2px solid gray" : "none"}
      >
        <HStack w={"100%"}>
          <HStack w="100%" mb={children ? "24px" : -2} justifyContent="start">
            {isEdit ? (
              <>
                <Input
                  key={etapa_id}
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  placeholder="Nome da Etapa"
                />
                {CheckListGestao && CheckListGestao.map(i => {
                  <Text>{i.titulo}</Text>
                })}
                <Button onClick={update} disabled={isUpdating}>
                  Ok
                </Button>
              </>
            ) : (
              <HStack justifyContent={"space-between"} w={"100%"}>
                <HStack>
                  <Text fontSize={"14px"}>{title}</Text>
                  <Box
                    w={"20px"}
                    h={"20px"}
                    bg={"#EAECF0"}
                    borderRadius="20px"
                    border={"1px"}
                    borderColor={"xmentor.borderCards"}
                    p={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize={"12px"}>{qtl}</Text>
                  </Box>
                </HStack>
                {index > 0 && (
                  <Menu size="sm">
                    <MenuButton
                      variant="outline"
                      size={"sm"}
                      as={IconButton}
                      aria-label="Options"
                      border="0px"
                      icon={<FaEllipsisVertical />}
                      onClick={() => setIsMenuExpanded(!isMenuExpanded)}
                    />
                    <MenuList>
                      <MenuGroup title="Opções">
                        <MenuDivider />
                        <MenuItem
                          px={4}
                          fontSize={"14px"}
                          onClick={handleEditClick}
                          disabled={isUpdating}
                        >
                          Editar
                        </MenuItem>
                        {edit && (
                          <MenuItem
                            px={4}
                            fontSize={"14px"}
                            onClick={handleDeleteClick}
                            disabled={isUpdating}
                          >
                            Excluir
                          </MenuItem>
                        )}
                      </MenuGroup>
                    </MenuList>
                  </Menu>
                )}
              </HStack>
            )}
          </HStack>
        </HStack>
        <VStack
          overflowY="auto"
          maxHeight="100%"
          align="flex-start"
          minW={300}
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0)",
              transition: "background-color 0.3s",
              borderRadius: "4px",
              marginRight: "-8px", // Ajuste a margem para mover o scroll para a direita
            },
            "&:hover::-webkit-scrollbar-thumb": {
              backgroundColor: "#c4c4c4",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
              marginRight: "8px", // Ajuste a margem para mover o scroll para a direita
            },
          }}
        >
          {!isEdit && children}
        </VStack>



      </VStack>
    </VStack>
  );
}
