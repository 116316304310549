import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { TemplateAdmin } from "../../../../components/layout/admin/TemplateAdmin";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { InputSearch } from "../../../../components/Form/InputSearch";
import { CardMentor } from "../../../../components/CardMentor";

export function UsersMentor() {
  const navigate = useNavigate();
  const { SearchUser, users, payLoad, FindUserById } = useAuth();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;
  useEffect(() => {
    handlePage("", page);
  }, []);
  useEffect(() => {
    if (search.length >= 3) {
      handlePage(search, page);
    } else if (search.length == 0) {
      handlePage("", 0);
    }
  }, [search, page]);
  function handlePage(search: string, page: number) {
    if (page >= 0)
      if (payLoad?.user.mentoria_id)
        SearchUser({
          nome: search,
          perfil: "mentor",
          pagina: page,
          mentoria_id: payLoad?.user.mentoria_id,
        });
  }
  return (
    <TemplateAdmin>
      <VStack spacing={4} align={"flex-start"} mb={4}>
        <Text
          fontSize="xl"
          fontWeight={700}
          fontStyle="normal"
          lineHeight="normal"
          letterSpacing={"-0.227px"}
        >
          Mentores
        </Text>
        <Button
          type="submit"
          colorScheme="blue"
          size="md"
          bg="xmentor.primary"
          _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
          leftIcon={<GoPlus />}
          onClick={() =>
            navigate("/admin/users/register-new-user", {
              state: { perfil: "mentor" },
            })
          }
          isDisabled={
            Number(payLoad?.user.mentoria?.tipo_mentoria?.qtd_mentores) <=
            Number(users?.total)
          }
        >
          Novo mentor
        </Button>

        <InputSearch
          placeholder="Pesquisa mentor"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </VStack>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {users &&
          users.data.length > 0 &&
          users.data.map((i) => (
            <CardMentor
              key={i.id}
              image={i.foto || ""}
              mentor={i.nome || ""}
              onClick={async () => {
                await FindUserById(String(i.id));
                navigate("/admin/users/mentor", {
                  state: {
                    id: i.id,
                    nome: i.nome,
                    email: i.email,
                    foto: i.foto,
                    perfil: "Mentor",
                  },
                });
              }}
            />
          ))}
      </SimpleGrid>
    </TemplateAdmin>
  );
}
