import React, { useEffect, useState } from "react";
import {
  VStack,
  Text,
  Button,
  Input as InputChakra,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Flex,
  Divider,
  ModalOverlay,
  HStack,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormLabel,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { Input } from "../../../../components/Form/Input";
import { CustomModalOverlay } from "../../trilha/components/CustomModalOverlay";
import { SocialMediaList } from "./ModalRedesSociaisMentorado";

import "cropperjs/dist/cropper.css";
import { ModalAddRedesSociais } from "./ModalAddRedesSociais";
import {
  MdOutlineCalendarToday,
  MdOutlineMail,
  MdOutlinePhoneIphone,
} from "react-icons/md";
import { ResultRouteProps } from "../view";
import { useLocation } from "react-router-dom";

export interface IModalAddRedesSociais {
  isOpen: boolean;
  onClose: () => void;
  usuario_cliente_id: string;
}
interface IRSProps {
  instagram: string;
  linkedin: string;
  youtube: string;
}

export function ModalUpdateDataUser(data: IModalAddRedesSociais) {
  const location = useLocation();
  const resultRoute: ResultRouteProps = location.state || {
    email: "",
    nome: "",
    foto: "",
    id: "",
    perfil: ""
  };
  const {
    UploadAvatar: UploadDataAvatar,
    payLoad,
    userSelected,
    UpdateUser,
    AtualizarRedesSociais,
  } = useAuth();
  const [redesSociais, setRedesSociais] = useState<IRSProps>({
    instagram: userSelected?.redes_sociais?.instagram || "",
    linkedin: userSelected?.redes_sociais?.linkedin || "",
    youtube: userSelected?.redes_sociais?.youtube || "",
  });

  const [file, setFile] = useState<File | null>(null);
  const [nome, setNome] = useState(userSelected?.nome || "");

  const [contato, setContato] = useState(userSelected?.contato || "");

  const [email, setEmail] = useState(userSelected?.email || "");

  const [dataNascimento, setDataNascimento] = useState(
    userSelected?.data_nascimento || ""
  );

  const [empresa, setEmpresa] = useState(userSelected?.nome_empresa || "");
  const [ocupacao, setOcupacao] = useState(userSelected?.ocupacao || "");
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPreviewUrl(
      userSelected &&
      `${process.env.REACT_APP_API}/tmp/user/${userSelected?.foto}`
    );
  }, [data]);

  const handleRemove = () => {
    setFile(null);
    setPreviewUrl(null);
  };

  async function handleUpload() {
    if (!payLoad) {
      return;
    }
    setIsLoading(true);

    try {
      if (file) {
        const formDataWithImage = new FormData();
        formDataWithImage.append("usuario_cliente_id", data.usuario_cliente_id);
        formDataWithImage.append(
          "usuario_criador_id",
          String(payLoad?.user.id)
        );
        formDataWithImage.append("file", file);
        await Promise.all([
          UploadDataAvatar(data.usuario_cliente_id, formDataWithImage),
          AtualizarRedesSociais(
            data.usuario_cliente_id,
            redesSociais.instagram,
            redesSociais.linkedin,
            redesSociais.youtube
          ),
          UpdateUser({
            id: data.usuario_cliente_id,
            action: "profile",
            data: {
              contato: contato,
              email: email,
              nome: nome,
              data_nascimento: dataNascimento,
              ocupacao: ocupacao,
              nome_empresa: empresa,
              sobreMim: {
                metas: String(userSelected?.sobreMim?.metas),
                pontosFortes: String(userSelected?.sobreMim?.pontosFortes),
                pontosDeMelhoria: String(
                  userSelected?.sobreMim?.pontosDeMelhoria
                ),
              },
            },
          }),
        ]);
      } else {
        await Promise.all([
          AtualizarRedesSociais(
            data.usuario_cliente_id,
            redesSociais.instagram,
            redesSociais.linkedin,
            redesSociais.youtube
          ),
          UpdateUser({
            id: data.usuario_cliente_id,
            action: "profile",
            data: {
              contato: contato,
              email: email,
              nome: nome,
              data_nascimento: dataNascimento,
              ocupacao: ocupacao,
              nome_empresa: empresa,
              sobreMim: {
                metas: String(userSelected?.sobreMim?.metas),
                pontosFortes: String(userSelected?.sobreMim?.pontosFortes),
                pontosDeMelhoria: String(
                  userSelected?.sobreMim?.pontosDeMelhoria
                ),
              },
            },
          }),
        ]);
      }

      setIsLoading(false);
      data.onClose();
    } catch (error) {
      console.error("Error uploading data:", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!data.isOpen) {
      handleRemove();
    }
    if (userSelected && userSelected.redes_sociais)
      setRedesSociais({
        instagram: userSelected.redes_sociais.instagram,
        linkedin: userSelected.redes_sociais.linkedin,
        youtube: userSelected.redes_sociais.youtube,
      });
  }, [data.isOpen]);
  return (
    <Modal isOpen={data.isOpen} onClose={data.onClose} size="2xl" isCentered>
      {/*<CustomModalOverlay />*/}
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{resultRoute.perfil}</ModalHeader>
        <ModalCloseButton mt={3} />
        <Divider />
        <ModalBody>
          <VStack spacing={5} align="flex-start">
            <Flex w="full" gap="4">
              <Input
                w="full"
                rounded="md"
                bg="xmentor.background_template"
                fontSize={"sm"}
                label="Nome"
                type="text"
                name="nome"
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />

              <Input
                w="full"
                rounded="md"
                bg="xmentor.background_template"
                fontSize={"sm"}
                label="Cargo"
                type="text"
                placeholder="Cargo"
                name="cargo"
                value={ocupacao}
                onChange={(e) => setOcupacao(e.target.value)}
              />
            </Flex>
            <Flex w="full" gap="4">
              <Input
                w="full"
                rounded="md"
                bg="xmentor.background_template"
                fontSize={"sm"}
                label="Empresa que trabalha"
                type="text"
                name="empresa"
                placeholder="Empresa que trabalha"
                value={empresa}
                onChange={(e) => setEmpresa(e.target.value)}
              />
              <Input
                w="full"
                rounded="md"
                bg="xmentor.background_template"
                fontSize={"sm"}
                label="Telefone"
                type="text"
                name="Telefone"
                placeholder="00 00000 0000"
                value={contato}
                maxLength={15}
                onChange={(event) => {
                  const { value } = event.target;
                  const regex = new RegExp("^[0-9()+-\\s]*$");
                  // Aplicar regex e limitar caracteres
                  if (regex.test(value) && value.length <= 15) {
                    setContato(value);
                  }
                }}
                pattern="^[0-9()+-\\s]*$"
              />
            </Flex>
            <Flex w="full" gap="4">
              <Input
                w="full"
                rounded="md"
                bg="xmentor.background_template"
                fontSize={"sm"}
                label="E-mail"
                type="email"
                name="email"
                placeholder="Email"
                maxLength={255}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                w="full"
                rounded="md"
                fontSize={"sm"}
                label="Nascimento"
                type="date"
                name="nascimento"
                maxLength={255}
                value={dataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
              />
            </Flex>
            <SocialMediaList
              onChange={setRedesSociais}
              value={redesSociais}
              onUpdate={data.isOpen}
            />
          </VStack>
        </ModalBody>
        <Divider />
        <ModalFooter gap={2}>
          <Button variant="outline" px={6} fontSize="sm" onClick={data.onClose}>
            Cancelar
          </Button>
          <Button
            px={6}
            bg="xmentor.primary"
            color="white"
            fontSize="sm"
            onClick={handleUpload}
            isLoading={isLoading}
            _hover={{ bg: "xmentor.primary" }}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
