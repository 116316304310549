import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { TemplateMentorado } from "../../../components/layout/mentorado/TemplateMentorado";
import ConfigOptionCard from "../../../components/cards/ConfigOptionCard";
import { FaUserAlt , FaQuestionCircle  } from "react-icons/fa";
import { FiKey } from "react-icons/fi";
import { IoMdMailUnread } from "react-icons/io";
import { FaFileInvoice } from "react-icons/fa6";
import { IoShieldCheckmark } from "react-icons/io5";
import { RxExit } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

export function MentoradoConfig() {
  const navigate = useNavigate()


  return (
    <TemplateMentorado>
      <Flex px={{base : "16px",md : "64px"}} flexDirection={"column"} gap={"24px"}>
        <Text fontSize={"24px"} fontWeight={"700"}>
          Configurações
        </Text>
        <Text fontWeight={"500"}>Configurações do perfil</Text>
        <VStack gap={"8px"} w={'100%'}>
          <ConfigOptionCard icon={<FaUserAlt />} name="Perfil" cb={() => console.log('pressed')}/>
          <ConfigOptionCard icon={<FiKey />} name="Alterar Senha" cb={() => navigate("/mentorado/config/change-password")}/>
          <ConfigOptionCard icon={<IoMdMailUnread />} name="Notificações por email" cb={() => console.log('pressed')}/>
        </VStack>
        <Text fontWeight={"500"}>Suporte</Text>
        <VStack gap={"8px"} w={'100%'}>
            <ConfigOptionCard icon={<FaFileInvoice />} name="Termos de uso" cb={() => console.log('pressed')}/>
            <ConfigOptionCard icon={<IoShieldCheckmark />} name="Politicas de privacidade" cb={() => console.log('pressed')}/>
            <ConfigOptionCard icon={<FaQuestionCircle />} name="Ajuda" cb={() => console.log('pressed')}/>
            <ConfigOptionCard icon={<RxExit color="rgba(240, 68, 56, 1)"/>} name="Sair" cb={() => console.log('pressed')} textColor="rgba(240, 68, 56, 1)"/>
        </VStack>
      </Flex>
    </TemplateMentorado>
  );
}
