import React from "react";
import { VStack, HStack, Text, IconButton } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { CardAnotacoes } from "../components/CardAnotacoes";
import { useAuth } from "../../../../context/AuthProvider/useAuth";

type NotesSectionProps = {
  handleAddAnotacao: () => void;
  setIdAnotacao: (arg: string) => void;
  setIsModalDeletarAnotacoes: (arg: boolean) => void;
};

const NotesSection = ({
  handleAddAnotacao,
  setIdAnotacao,
  setIsModalDeletarAnotacoes,
}: NotesSectionProps) => {
  const { userSelected } = useAuth();

  return (
    <VStack
      align={"flex-start"}
      p={[2, 5]}
      bg="white"
      w="100%"
      borderWidth="1px"
      borderRadius="lg"
    >
      <HStack w={"100%"} justifyContent={"space-between"}>
        <Text fontSize="xl" fontWeight="bold" mb={3}>
          Anotações
        </Text>
        <IconButton
          colorScheme="primary"
          bgColor={"xmentor.primary"}
          color={"white"}
          icon={<FaPlus />}
          aria-label="Edit"
          ml={{ base: 0, md: "auto" }}
          mt={{ base: 4, md: 0 }}
          onClick={handleAddAnotacao}
        />
      </HStack>
      {userSelected?.anotacoes_recebidas.map((card, index) => (
        <CardAnotacoes
          key={index}
          title={card.titulo}
          date={card.data_cadastro}
          description={card.texto}
          usuario_criador={card.usuario_criador}
          onClick={() => {
            setIdAnotacao(card.id);
            setIsModalDeletarAnotacoes(true);
          }}
        />
      ))}
    </VStack>
  );
};

export default NotesSection;
