import { Box, Button, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";

interface IConfigOptionCard {
  icon: ReactElement;
  name: string;
  cb: () => void;
  textColor ?: string;
}

export default function ConfigOptionCard(props: IConfigOptionCard) {
  const onCardPress = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    props.cb();
  };
  return (
    <Button
      // variant={"unstyled"}
      w={"100%"}
      h={"64px"}
      justifyContent={"left"}
      display={"flex"}
      borderRadius={"md"}
      bgColor={"#fff"}
      alignItems={"center"}
      onClick={onCardPress}
    >
      <Box ml={"25px"}>{props.icon}</Box>
      <Box ml={"20px"}>
        <Text /*fontFamily={'Rubik'}*/ fontWeight={"500"} lineHeight={"22px"} color={props?.textColor}>
          {props.name}
        </Text>
      </Box>
    </Button>
  );
}
