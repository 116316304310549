import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Stack, VStack, useBreakpointValue, } from "@chakra-ui/react";

import { Sidebar } from "./Sidebar";

interface PropsIsOpen{
  isOpen?: boolean
  sendInfo?: ()=> void
}

export function DrawerSideBar({isOpen, sendInfo}:PropsIsOpen) {

  
 
  const isDrawerSidebar = useBreakpointValue({
    base: true,
    lg: false
  })
  if (isDrawerSidebar) {

    

    return (
    <Drawer isOpen={isOpen?isOpen:false} placement="right" size="xs" onClose={sendInfo?sendInfo:()=>{}}>
        <DrawerOverlay/>
        <DrawerContent bg="xmentor.primary" p={0} >  
            <DrawerBody p={0}>
                <Sidebar>
                    <DrawerCloseButton />
                </Sidebar>
            </DrawerBody>              
        </DrawerContent>
    </Drawer>)
  }
  return (
    <Flex
      as='aside'
      h={"100vh"} 
    >
        <Sidebar />
    </Flex >
  )
}