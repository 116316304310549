import React from "react";

import {
  Avatar,
  VStack,
  HStack,
  Text,
  Button
} from "@chakra-ui/react";
import { BodyContent } from "../../../../components/content/body";
import { useLocation, useNavigate } from "react-router-dom";
import { TemplateMaster } from "../../../../components/layout/master/TemplateMaster";

interface Props{
  id: string
  nome: string
  descricao: string
  administradores: string
  mentores:string
  mentorados: number
  criadoEm: string
  foto: string
  perfil: string
}

export function ViewMentoria() {
  const navigate = useNavigate()
  const location = useLocation();
  const resultRoute: Props = location.state || {
    id: "",
    nome: "",
    descricao: "",
    administradores: "",
    mentores:"",
    mentorados: 0,
    criadoEm: "",
    foto: "",
    perfil: "",
  }

  return (
    <TemplateMaster>
      <BodyContent>
        {resultRoute&&
        <VStack w="100%" align="left" spacing={4}>
          <HStack  display="flex" justifyContent="space-between">
            <HStack>
              <Avatar src={`${process.env.REACT_APP_API}/tmp/mentoria/${resultRoute.foto}`} />
              <VStack align="left" spacing={0}>
                <Text fontWeight="bold" fontSize="lg" >
                  {resultRoute.nome}
                </Text>
                <Text fontWeight="bold" color="xmentor.text_gray">
                  Criado em {resultRoute.criadoEm}
                </Text>
              </VStack>
            </HStack>
            <Button variant="outline" size="md" onClick={()=>navigate("/master/mentoria/update", {state: resultRoute})}>
              Editar
            </Button>
          </HStack>
          <VStack align="left" spacing={0}>
            <Text  color="xmentor.text_gray">
              Administradores
            </Text>
            <Text  >
              {resultRoute.administradores || "Nenhum"}
            </Text>          
          </VStack>
          <VStack align="left" spacing={0}>
            <Text  color="xmentor.text_gray">
              Mentores
            </Text>
            <Text  >
              {resultRoute.mentores || "Nenhum"}
            </Text>          
          </VStack>
          <VStack align="left" spacing={0}>
            <Text  color="xmentor.text_gray">
              Mentorados
            </Text>
            <Text  >
              {resultRoute.mentorados}
            </Text>          
          </VStack>
        </VStack>
        }
      </BodyContent>
    </TemplateMaster>
  );
}
