import React, { useEffect, useState } from "react";
import {
  Button,
  HStack,
  Stack,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Divider,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  useTheme,
} from "@chakra-ui/react";
import { Input } from "../../../../components/Form/Input";
import { FaPlus } from "react-icons/fa";

interface QuestionProps {
  sendQuestionList: (value: string[]) => void;
}

export const QuestionList = (props: QuestionProps) => {
  const [questions, setQuestions] = useState<string[]>([]);
  const [quiz, setQuiz] = useState("");
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editValue, setEditValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;
    

  useEffect(() => {
    props.sendQuestionList(questions);
  }, [questions]);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const addQuestion = () => {
    if (quiz.trim() !== "") {
      setQuestions([...questions, quiz]);
      setQuiz("");
      onToggle();
    }
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const editQuestion = (index: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = editValue;
    setQuestions(updatedQuestions);
    setEditIndex(null);
    setEditValue("");
  };

  return (
    <VStack
      display="flex"
      w="100%"
      mt={1}
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
     
        <HStack justifyContent={"center"} spacing={3} alignItems={"center"}>
          <Text fontWeight={"bold"}>Questionário</Text>
          {questions.length > 0 && (
          <IconButton
            colorScheme="primary"
            bgColor={"xmentor.primary"}
            color={"white"}
            size={"sm"}
            fontSize="14px"
            onClick={onToggle}
            icon={<FaPlus />}
            aria-label="Add"
          />)}
        </HStack>
      {questions.length <= 0 && (
        <Button
          fontSize={"xs"}
          px={6}
          size="md"
          bg={"xmentor.primary"}
          textColor={"white"}
          _hover={{ bg: "xmentor.primary" }}
          onClick={onToggle}
        >
          Criar perguntas ao mentorado
        </Button>
      )}
      <Modal isCentered isOpen={isOpen} size={"2xl"} onClose={onToggle}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Faça uma pergunta</ModalHeader>
          <Divider />
          <ModalCloseButton mt={2} />
          <ModalBody>
            <Stack spacing={3} maxW={"470px"} mt={'5'} mx="auto">
              <Text>
                Faça perguntas ao mentorado! Essas perguntas deverão ser
                respondidas por eles no primeiro acesso ao sistema.
              </Text>
              <VStack align="flex-end" spacing={1}>
                <Input
                  placeholder="Digite aqui sua pergunta"
                  type="text"
                  name="quiz"
                  value={quiz}
                  onChange={(e) => setQuiz(e.target.value)}
                  maxLength={55}
                />
                <Text fontSize={"2xs"}>{(quiz.length || "0") + "/55"}</Text>
              </VStack>
            </Stack>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button
              fontSize={"sm"}
              px={7}
              variant="outline"
              size="md"
              onClick={onToggle}
            >
              Cancelar
            </Button>
            <Button
              fontSize={"sm"}
              px={6}
              size="md"
              bg={"xmentor.primary"}
              textColor={"white"}
              onClick={addQuestion}
              _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <VStack w="100%" align="flex-start" display="flex" mt={4}>
        {questions.map((question, index) => (
          <VStack display="flex" w="100%" align="center">
            <HStack key={index} w="100%" align="center" display="flex">
              <Text>{index + 1}</Text>
              {editIndex === index ? (
                <HStack w="100%" align="center" display="flex">
                  <VStack align="flex-end" w="100%" spacing={1}>
                    <Input
                      placeholder="Digite aqui sua pergunta"
                      type="text"
                      name="quiz"
                      value={editValue}
                      w="100%"
                      onChange={(e) => setEditValue(e.target.value)}
                      maxLength={55}
                    />
                    <Text fontSize={"2xs"}>
                      {(editValue.length || "0") + "/55"}
                    </Text>
                  </VStack>
                  <Button size="sm" ml={2} onClick={() => editQuestion(index)}>
                    Salvar
                  </Button>
                </HStack>
              ) : (
                <HStack
                  display="flex"
                  w="100%"
                  align="center"
                  justifyContent="space-between"
                >
                  <Text>{question}</Text>
                  <HStack>
                    <Button
                      size="sm"
                      ml={2}
                      onClick={() => {
                        setEditIndex(Number(index));
                        setEditValue(question);
                      }}
                    >
                      Editar
                    </Button>
                    <Button
                      size="sm"
                      ml={2}
                      onClick={() => deleteQuestion(index)}
                    >
                      Excluir
                    </Button>
                  </HStack>
                </HStack>
              )}
            </HStack>
            <Divider />
          </VStack>
        ))}
      </VStack>
    </VStack>
  );
};
