import React, { useEffect } from "react";
import {
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import EventoCardMentorado from "../../../components/EventoCardMentorado";
import { EventoProvider } from "../../../context/EventoProvider";
import { useEvento } from "../../../context/EventoProvider/useEvento";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { useNavigate } from "react-router-dom";
import { formatarHora } from "../../../utils/formatarData";
import { TemplateMentor } from "../../../components/layout/mentor/TemplateMentor";

export function EventoChildren() {
  const { ConsultaEvento, eventos } = useEvento();
  const { payLoad } = useAuth();
  useEffect(() => {
    carregarEventos();
  }, []);

  const carregarEventos = async () => {
    try {
      await ConsultaEvento(payLoad?.user.mentoria_id || " ");
    } catch (error) {
      console.error("Erro ao carregar eventos:", error);
      // Adicione a lógica necessária para lidar com o erro, se necessário.
    }
  };
  return (
    <TemplateMentor>
      <VStack align="start">
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Heading fontSize={24} my="24px" fontWeight={700}>
            Eventos
          </Heading>
        </HStack>
        <Stack
          spacing={"24px"}
          gap={"24px"}
          flexWrap={"wrap"}
          direction={["column", "row"]}
          borderRadius={14}
          w="100%"
        >
          {eventos.length > 0 &&
            eventos.map((evento, index) => (
              <EventoCardMentorado
                key={index}
                data={evento.data_evento || ""}
                horario={formatarHora(evento.data_evento) || ""}
                titulo={evento.nome || ""}
                descricao={
                  String(evento.duracao || "0") +
                  " min, " +
                  evento.descricao_instrucoes
                }
                onClick={()=>{}}
              />
            ))}
        </Stack>
      </VStack>
    </TemplateMentor>
  );
}

export function EventoMentor() {
  return (
    <EventoProvider>
      <EventoChildren />
    </EventoProvider>
  );
}
