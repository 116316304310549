import axios from "axios";
import Cookies from "js-cookie";

const apiXmentor = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});

const token = Cookies.get("@xmentor-token-auth");

apiXmentor.interceptors.request.use((config) => {
  if (!config.url?.includes('/usuario-login')) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiXmentor.interceptors.response.use(
  (response) => {
    const newToken = response.headers['new-token']; // Substitua 'new-token' pelo nome do header que contém o novo token

    if (newToken) {
      // Atualizar o token no cookie
      Cookies.set("@xmentor-token-auth", newToken);
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiXmentor;
