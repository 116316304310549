export function formatarData(input: string): string {
  if (input.length === 0) {
    return ""
  }
  const parts = input.split('-'); // Dividir a data em partes usando o traço como separador
  if (parts.length !== 3) {
    throw new Error('Formato de data inválido. Use o formato YYYY-MM-DD.');
  }

  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  const outputDate = `${day}/${month}/${year}`; // Formatar a nova data como "DD/MM/YYYY"
  return outputDate;
}
export function converterData(dataISO: string): string {
  const data = new Date(dataISO);
  const dia = data.getDate().toString().padStart(2, '0');
  const mes = (data.getMonth() + 1).toString().padStart(2, '0');
  const ano = data.getFullYear().toString();
  return `${dia}/${mes}/${ano}`;
}
export function formatarDataHora(input: string): string {
  const data = new Date(input);

  const dia = String(data.getDate()).padStart(2, "0");
  const mes = String(data.getMonth() + 1).padStart(2, "0"); // Mês é base 0
  const ano = data.getFullYear();
  const horas = String(data.getHours()).padStart(2, "0");
  const minutos = String(data.getMinutes()).padStart(2, "0");

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;

}
export function formatarHora(input: string): string {
  const data = new Date(input);
  const horas = String(data.getHours()).padStart(2, "0");
  const minutos = String(data.getMinutes()).padStart(2, "0");

  return `${horas}:${minutos}`;

}