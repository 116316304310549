// src/components/layout/master/Sidebar.tsx
import { Divider, Button, Text, Flex, Icon, Box } from "@chakra-ui/react";
import { FaHome, FaUserAlt } from "react-icons/fa";
import { RiSettings3Fill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { ReactNode, useState } from "react";
import { LogoSvg } from "../LogoSvg";
import { ButtonNavigation } from "../ButtonNavigation";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { motion } from "framer-motion";
import { LogoMenor } from "../LogoMenor";

interface Props {
    children?: ReactNode;
}

export function Sidebar({ children }: Props) {
    const { logout } = useAuth();
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <motion.div
            onHoverStart={() => setIsExpanded(true)}
            onHoverEnd={() => setIsExpanded(false)}
            initial={{ width: 80 }}
            animate={{ width: isExpanded ? 312 : 80 }}
            transition={{ duration: 0.3 }}
            style={{
                display: "flex",
                flexDirection: "column",
                background: "#4963FF",
                color: "white",
                paddingTop: 16,
                paddingBottom: 16,
                height: "100%",
                position: "fixed",
                zIndex: 2,
                overflow: "hidden"
            }}
        >
            <Flex flexDir="column" w="100%" padding="0px 16px" alignItems="center" position="relative">
                {isExpanded ? <LogoSvg /> : <LogoMenor />}
                {children && isExpanded && (
                    <Box position="absolute" right={0} top={0}>
                        {children}
                    </Box>
                )}
                <Divider my={5} opacity={0.2} />
            </Flex>

            <Flex
                padding="0px 16px"
                flexDirection="column"
                alignItems={isExpanded ? "flex-start" : "center"}
                gap="16px"
                h="full"
            >
                <ButtonNavigation key={1} title="Início" url="/master/home" icon={FaHome} isExpanded={isExpanded} />
                <ButtonNavigation key={2} title="Configurações" url="/master/config" icon={RiSettings3Fill} isExpanded={isExpanded} />
                <ButtonNavigation key={3} title="Perfil" url="/master/profile" icon={FaUserAlt} isExpanded={isExpanded} />
            </Flex>

            <Flex flexDir="column" w="100%" alignItems="center">
                <Divider my={5} opacity={0.2} />
                <Button
                    display="flex"
                    padding={4}
                    alignItems="center"
                    color="white"
                    lineHeight="20px"
                    bg={"xmentor.primary"}
                    onClick={logout}
                    colorScheme="facebook"
                >
                    {isExpanded && <Text mr={4}>Sair</Text>}
                    <Icon as={FiLogOut} fontSize='20' />
                </Button>
            </Flex>
        </motion.div>
    );
}
