import Cookies from "js-cookie";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IAuthProvider,
  ISearchUser,
  IContext,
  IListUser,
  IPayLoad,
  IUser,
  IActivity,
  INotification,
  IUpdateProfileReq,
  IPayLoadLogin,
} from "./types";
import {
  getUserLocalStorage,
  setUserLocalStorage,
  getTokenLocalStorage,
  auth,
  searchUser,
  registerUser,
  findActvity,
  findUserById,
  updateDescriptionProfile,
  uploadAnexo,
  deleteAnexo,
  criarVinculoMentorMentorado,
  deletarVinculoMentorMentorado,
  criarAcao,
  deletarAcao,
  atualizarAcao,
  criarAnotacoes,
  responderAtividadeComTexto,
  responderAtividadeComArquivo,
  findNotifications,
  checkNotifications,
  deleteMentorado,
  atualizarRedesSociais,
  uploadBackground,
  deletarAnotacoes,
  updateUser,
  uploadAvatar,
  addExtraHours,
  setLoginLocalStorage,
  addTag,
  deleteTag,
} from "./util";
import { useToast } from "@chakra-ui/react";

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [authData, setAuthData] = useState<{
    token: string | null;
    payLoad: IPayLoad | null;
  }>({
    token: JSON.stringify(Cookies.get("@xmentor-token-auth") || "null"),
    payLoad: JSON.parse(Cookies.get("@xmentor-user-data") || "null"),
  });
  const [isGoogle, setIsGoogle] = useState(
    Cookies.get("xmentor-user-isGoogle") || "null"
  );
  const [payLoadLogin, setPayLoadLogin] = useState<IPayLoadLogin | null>(null)
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [users, setUsers] = useState<IListUser | null>(null);
  const [userSelected, setUserSelected] = useState<IUser | null>(null);
  const [activity, setActivity] = useState<IActivity[]>([]);
  const [configMentoria, setConfigMentoria ] =useState("")

  useEffect(() => {
    try {
      const result = JSON.parse(Cookies.get("@xmentor-user-data") || "null");
      setAuthData((prevData) => ({ ...prevData, payLoad: result }));
      const data = JSON.parse(Cookies.get("@xmentor-login-data") || "null")

      setPayLoadLogin(data)
    } catch (error) {
      console.error("Error parsing JSON:", error);
      // Handle the error, possibly by setting a default value or taking appropriate action
    }
  }, []);

  const verifyToken = () => !!authData.token;

  const showSuccessToast = (description: string) => {
    toast({
      position: "top",
      description,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const showErrorToast = (error: any) => {
    toast({
      position: "top",
      description: error.response?.data?.message || error || "Error",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const Auth = async (response: any) => {
    try {
      setPayLoadLogin(response)
      // salva em local storage
      setLoginLocalStorage(response)
      showSuccessToast(response.msg);
      setTimeout(() => {
        navigate("/seleciona-mentoria")
      }, 2500)
    } catch (error) {
      showErrorToast(error);
      setTimeout(() => (window.location.href = "/"), 2500);
    }
  };

  const AuthUser = async (response: any) => {
    try {
      showSuccessToast("Mentoria selecionada");
      // definir cor aqui 
      const defaultColor = "#4963FF";
      const color = response?.configMentoria?.cor || defaultColor;
      
      setTimeout(() => {
        setUserLocalStorage(response);
        setConfigMentoria(response.configMentoria)
        const defaultColor = "#4963FF";
        localStorage.setItem("xmentor-color-theme", color);
        
        setAuthData({
          token: response.token,
          payLoad: response,
        });
        if (response.user.googleOAuth2) {
          setIsGoogle("true");
        }
        if (response.user.perfil == "mentorado") {
          window.location.href = "/mentorado/home";
          return;
        }
        if (
          response.user.perfil == "auxiliar" ||
          response.user.perfil == "mentor"
        ) {
          window.location.href = "/admin/home";
          return;
        }
        // if (response.user.perfil == "mentor") {
        //   window.location.href = '/mentor/home'
        //   return
        // }
        if (response.user.perfil == "master") {
          window.location.href = "/master/home";
          return;
        }
      }, 2500);
    } catch (error) {
      showErrorToast(error);
      setTimeout(() => (window.location.href = "/"), 2500);
    }
  };

  const updateConfigMentoria = (newConfig: any) => {
    setAuthData((prevData) => {
      if (!prevData.payLoad) {
        // Retorna o estado anterior se payLoad for null
        return prevData;
      }
    
      return {
        ...prevData,
        payLoad: {
          ...prevData.payLoad,
          configMentoria: {
            ...prevData.payLoad.configMentoria,
            cor: newConfig.cor,
            foto: newConfig.foto, // Assumindo que `foto_logo` é o campo correto
            //new
            nome: newConfig.nome
          },
        },
      };
    });
  
    // Atualiza também o cookie para persistir os dados
    Cookies.set("@xmentor-user-data", JSON.stringify({
      ...authData.payLoad,
      configMentoria: {
        ...authData.payLoad?.configMentoria,
        cor: newConfig.cor,
        foto: newConfig.foto,
        //new
        nome: newConfig.nome
      },
    }));
  };

  const logout = () => {
    showErrorToast("Sua sessão encerrou!");
    setAuthData({ token: null, payLoad: null });
    Cookies.remove("@xmentor-user-data");
    Cookies.remove("@xmentor-login-data");
    Cookies.remove("@xmentor-token-auth");
    Cookies.remove("xmentor-user-isGoogle");
    localStorage.clear()
    navigate("/");
  };

  const SearchUser = async (search: ISearchUser) => {
    localStorage.setItem("paramsSearchXmentor", JSON.stringify(search));

    try {
      const response = await searchUser(search);
      setUsers(response);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const RegisterUser = async (user: FormData) => {
    try {
      await registerUser(user);
      showSuccessToast("Cadastrado com sucesso");
      setTimeout(() => window.location.reload(), 1500);
      return
    } catch (error) {
      showErrorToast(error);
      return
    }
  };

  const UpdateUser = async (data: IUpdateProfileReq) => {
    try {
      await updateUser(data);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(data.id));
      setTimeout(async () => await FindUserById(data.id), 750);
    }
  };
  const FindActivity = async (id: string, status: string) => {
    try {
      const response = await findActvity(id, status);
      setActivity(response);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const carregaToken = () => {
    const result = getTokenLocalStorage();
    setAuthData((prevData) => ({ ...prevData, token: result }));
    return result;
  };

  const checkAuth = () => {
    const result = getUserLocalStorage();
    if (result) setAuthData((prevData) => ({ ...prevData, payLoad: result }));
  };

  const FindUserById = async (id: string) => {
    try {
      const result = await findUserById(id);
      setUserSelected(() => result);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const UpdateDescriptionProfile = async (id: string, description: string) => {
    try {
      await updateDescriptionProfile(id, description);
    } catch (error) {
      showErrorToast(error);
    }
  };
  const UploadAnexo = async (data: FormData, usuario_cliente_id: string) => {
    try {
      await uploadAnexo(data);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(usuario_cliente_id));
      setTimeout(async () => await FindUserById(usuario_cliente_id), 750);
    }
  };
  const DeleteAnexo = async (id: string, idUser: string) => {
    try {
      await deleteAnexo(id);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(idUser));
      setTimeout(async () => await FindUserById(idUser), 750);
    }
  };
  const CriarVinculoMentorMentorado = async (
    usuario_mentor_id: string,
    usuario_mentorado_id: string
  ) => {
    try {
      await criarVinculoMentorMentorado(
        usuario_mentor_id,
        usuario_mentorado_id
      );
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(usuario_mentorado_id));
      setTimeout(async () => await FindUserById(usuario_mentorado_id), 750);
    }
  };
  const DeletarVinculoMentorMentorado = async (
    usuario_mentor_id: string,
    usuario_mentorado_id: string
  ) => {
    try {
      await deletarVinculoMentorMentorado(
        usuario_mentor_id,
        usuario_mentorado_id
      );
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(usuario_mentorado_id));
      setTimeout(async () => await FindUserById(usuario_mentorado_id), 750);
    }
  };
  const CriarAcao = async (
    titulo: string,
    data_fato: string,
    file: boolean,
    etapa_id: string,
    mentor_id: string,
    usuario_cliente_id: string,
    usuario_criador_id: string
  ) => {
    try {
      await criarAcao(
        titulo,
        data_fato,
        file,
        etapa_id,
        mentor_id,
        usuario_cliente_id,
        usuario_criador_id
      );
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(usuario_cliente_id));
      setTimeout(async () => await FindUserById(usuario_cliente_id), 750);
    }
  };
  const DeletarAcao = async (id: string, usuario_mentorado_id: string) => {
    try {
      await deletarAcao(id);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(usuario_mentorado_id));
      setTimeout(async () => await FindUserById(usuario_mentorado_id), 750);
    }
  };
  const AtualizarAcao = async (
    id: string,
    titulo: string,
    status: boolean,
    data_fato: string,
    usuario_cliente_id: string
  ) => {
    try {
      await atualizarAcao(id, titulo, status, data_fato);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(usuario_cliente_id));
      setTimeout(async () => await FindUserById(usuario_cliente_id), 750);
    }
  };
  const CriarAnotacoes = async (
    titulo: string,
    texto: string,
    usuario_cliente_id: string,
    usuario_criador_id: string
  ) => {
    try {
      await criarAnotacoes(
        titulo,
        texto,
        usuario_cliente_id,
        usuario_criador_id
      );
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(usuario_cliente_id));
      setTimeout(async () => await FindUserById(usuario_cliente_id), 750);
    }
  };
  const DeletarAnotacoes = async (id: string, idUser: string) => {
    try {
      await deletarAnotacoes(id);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(idUser));
      setTimeout(async () => await FindUserById(idUser), 750);
    }
  };
  const ResponderAtividadeComTexto = async (
    id: string,
    resposta: string,
    idUser: string
  ) => {
    try {
      await responderAtividadeComTexto(id, resposta);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => {
        FindActivity(idUser, "0");
      });
      setTimeout(async () => {
        FindActivity(idUser, "0");
      }, 750);
    }
  };
  const ResponderAtividadeComArquivo = async (
    data: FormData,
    idUser: string
  ) => {
    try {
      await responderAtividadeComArquivo(data);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => {
        FindActivity(idUser, "0");
      });
      setTimeout(async () => {
        FindActivity(idUser, "0");
      }, 750);
    }
  };
  const FindNotifications = async (idUser: string) => {
    try {
      const result = await findNotifications(idUser);
      setNotifications(result);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const CheckNotifications = async (id: string, idUser: string) => {
    try {
      await checkNotifications(id);

      const notification = notifications.filter((i) => i.id !== id);
      setNotifications(() => notification);
    } catch (error) {
      showErrorToast(error);
    }
    setTimeout(async () => await FindNotifications(idUser), 750);
  };
  const DeleteMentorado = async (id: string) => {
    try {
      await deleteMentorado(id);
      return true;
    } catch (error) {
      showErrorToast(error);
      return false;
    }
  };
  const AtualizarRedesSociais = async (
    id: string,
    instagram: string,
    linkedin: string,
    youtube: string
  ) => {
    try {
      const value = await atualizarRedesSociais(
        id,
        instagram,
        linkedin,
        youtube
      );
      return value;
    } catch (error) {
      showErrorToast(error);
      return error;
    } finally {
      new Promise(async (resolve) => await FindUserById(id));
      setTimeout(async () => await FindUserById(id), 550);
    }
  };
  const UploadBackground = async (id: string, data: FormData) => {
    try {
      await uploadBackground(id, data);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(id));
      setTimeout(async () => await FindUserById(id), 550);
    }
  };
  const UploadAvatar = async (id: string, data: FormData) => {
    try {
      await uploadAvatar(id, data);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(id));
      setTimeout(async () => await FindUserById(id), 550);
    }
  };
  const AddExtraHours = async (
    mentorId: string,
    historicoMentoriaId: string,
    carga_horaria: number
  ) => {
    try {
      await addExtraHours(mentorId, historicoMentoriaId, carga_horaria);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(mentorId));
      setTimeout(async () => await FindUserById(mentorId), 550);
    }
  };
  const AddTag = async(descricao: string, cor: string, usuarioId: string) => {
    try {
      await addTag(descricao, cor, usuarioId);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(usuarioId));
      setTimeout(async () => await FindUserById(usuarioId), 550);
    }
  };
  const DeleteTag = async (id: string, idUser: string) => {
    try {
      await deleteTag(id);
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async (resolve) => await FindUserById(idUser));
      setTimeout(async () => await FindUserById(idUser), 750);
    }
  };
  return (
    <AuthContext.Provider
      value={{
        ...authData,
        payLoadLogin,
        Auth,
        AuthUser,
        RegisterUser,
        UpdateUser,
        UploadAvatar,
        SearchUser,
        logout,
        users,
        carregaToken,
        verifyToken,
        checkAuth,
        FindActivity,
        activity,
        userSelected,
        FindUserById,
        UpdateDescriptionProfile,
        UploadAnexo,
        DeleteAnexo,
        CriarVinculoMentorMentorado,
        DeletarVinculoMentorMentorado,
        CriarAcao,
        AtualizarAcao,
        DeletarAcao,
        CriarAnotacoes,
        ResponderAtividadeComArquivo,
        ResponderAtividadeComTexto,
        FindNotifications,
        CheckNotifications,
        notifications,
        isGoogle,
        setIsGoogle,
        DeleteMentorado,
        AtualizarRedesSociais,
        UploadBackground,
        DeletarAnotacoes,
        AddExtraHours,
        AddTag,
        DeleteTag,
        updateConfigMentoria
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
