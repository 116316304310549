import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
} from "@chakra-ui/react";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent py={14}>
        <ModalBody
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <VStack spacing={2} align="flex-start" maxW={"263px"}>
            <Text fontWeight="bold" fontSize="xl" textAlign={"center"}>
              {title}
            </Text>
            <Text
              fontSize={"sm"}
              color={"xmentor.text_gray"}
              textAlign={"center"}
            >
              {description}
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent={"center"} alignItems={"center"} gap={6}>
          <Button variant="outline" fontSize={"sm"} px={7} onClick={onClose}>
            Cancelar
          </Button>
          <Button colorScheme="blue" fontSize={"sm"} px={7} onClick={onConfirm}>
            Mover
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
