import { Box, Flex, useToast } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { DrawerSideBar } from "./Drawer";
import { Header } from "../Header";
import { useNavigate } from "react-router-dom";
import isAuthenticated from "../../../context/AuthProvider/util";

export interface Props {
  children: ReactNode
  fixedHeader?: boolean

  updateNotification?: ()=>void
}

export function TemplateMentor({ children, fixedHeader, updateNotification  }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/')
    }
    const timer = setInterval(() => {
      if (!isAuthenticated()) {
        toast({
          position: 'top-right',
          description: "Sua sessão encerrou!",
          status: 'warning',
          duration: 5000,
          isClosable: true,
        })
        navigate('/')
      }

    }, 3000);

    return () => {
      clearInterval(timer);
    };

  }, [navigate])
  return (
    <Flex background="xmentor.background_template" minH={"100vh"} >
      <DrawerSideBar isOpen={isOpen} sendInfo={() => setIsOpen(false)} />
      {/* Corpo */}
      <Flex
        direction="column"
        flex="1"
        justifyContent="right"
        marginLeft={{ base: '0', lg: '305px' }}
      >
        {/* Header */}
        <Header sendInfo={() => setIsOpen(!isOpen)} fixed={fixedHeader || false}  updateNotification={()=>{}} isMenu />
        {/* Corpo */}
        <Box overflow={"hidden"} justifySelf={'right'} id="arrastadm" w={{ base: "100vw", "2xl": "calc(100vw - 312px)", "lg": "calc(100vw - 312px)" }}
          flex="2" p="4" px={{ base: "16px", lg: "64px" }} >
          {/* Conteúdo do Corpo */}
          {children}
        </Box>
      </Flex>
    </Flex>
  );
}
