import { VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";

const Logo = () => {

  const icon1 = {
    hidden: {
      opacity: 1,
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)"
    },
    visible: {
      opacity: 1,
      pathLength: 3,
      fill: "#0181c8"
    }
  };

  const icon2 = {
    hidden: {
      opacity: 1,
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)"
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      fill: "#18aefe"
    }
  };

  return (
    <VStack h={'100vh'} justify='center' align={'center'} transform={'scale(1.2)'}>
      <motion.svg xmlns="http://www.w3.org/2000/svg" width="100" height="110" viewBox="0 0 100 110" fill="none">
        <motion.path
          d="M50.8977 37.6987C50.9699 37.6852 50.9609 37.6536 50.8707 37.6039C50.8459 37.5904 50.8244 37.5723 50.8064 37.5498C46.6687 32.2818 42.2501 26.628 37.5507 20.5884C36.5309 19.2754 35.6725 18.3369 34.9753 17.7728C32.1902 15.519 28.1563 15.4547 25.2324 17.5393C23.4952 18.7757 22.0614 19.915 20.9311 20.9573C18.5148 23.1807 17.7365 26.9946 19.1375 30.0945C19.485 30.8616 19.9994 31.6794 20.6807 32.548C30.4676 45.0084 40.2117 57.4373 49.913 69.8345C50.1183 70.0962 50.3326 70.103 50.5559 69.8548L81.5852 35.0286"
          stroke="#98C4E9"
          strokeWidth="2"
          variants={icon1}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 3 },
            fill: { duration: 5 }
          }}
        />
        <motion.path
          d="M81.5849 35.0286C86.4942 29.2349 91.5185 23.4661 96.6579 17.7221C98.0747 16.136 98.8102 14.4101 98.8643 12.5443C98.9365 10.0852 97.9766 7.91031 95.9844 6.0197C94.4142 4.52842 93.0346 3.34848 91.8456 2.47988C88.695 0.175279 83.8387 0.60845 81.304 3.4579C71.1877 14.8264 61.0522 26.24 50.8975 37.6987"
          stroke="#7CB0DF"
          strokeWidth="2"
          variants={icon2}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 3 },
            fill: { duration: 5 }
          }}
        />
         <motion.path
          d="M15.8582 107.693C16.9276 107.249 18.059 106.353 19.2525 105.006C28.0061 95.1198 36.6515 85.3791 45.1886 75.7839C45.2381 75.7279 45.2665 75.6563 45.269 75.5816C45.2715 75.5068 45.2478 75.4336 45.2021 75.3744L32.4168 58.6838C32.4069 58.6715 32.3944 58.6615 32.3802 58.6545C32.366 58.6476 32.3505 58.6438 32.3347 58.6435C32.3189 58.6431 32.3032 58.6463 32.2887 58.6527C32.2743 58.659 32.2614 58.6685 32.251 58.6804C22.719 69.433 13.1384 80.2194 3.50942 91.0396C2.52125 92.153 1.56692 93.4153 1.22174 94.8603C1.01192 95.7447 0.951007 96.8716 1.03899 98.2411C1.2522 101.541 3.89183 103.47 6.13552 105.507C8.99512 108.099 12.0578 109.273 15.8582 107.693Z"
          stroke="#7CB0DF"
          strokeWidth="2"
          variants={icon1}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 3 },
            fill: { duration: 5 }
          }}
        />
        <motion.path
          d="M67.5203 92.2884C69.8994 95.3172 73.6287 96.3629 77.2497 94.9686C79.0095 94.2884 80.7456 92.6268 82.2921 91.4288C85.26 89.1343 86.414 85.3441 85.0807 81.8855C84.731 80.9853 83.947 79.7625 82.7287 78.2171C77.8194 71.9993 73.0658 65.9541 68.4679 60.0814C68.4537 60.063 68.4357 60.0479 68.415 60.0372C68.3944 60.0265 68.3716 60.0205 68.3484 60.0195C68.3252 60.0185 68.302 60.0227 68.2806 60.0316C68.2591 60.0406 68.2399 60.0541 68.2242 60.0713L54.5252 75.3879C54.4891 75.4286 54.4683 75.4806 54.4664 75.535C54.4646 75.5894 54.4818 75.6427 54.515 75.6857C58.752 81.1094 63.0871 86.6436 67.5203 92.2884Z"
          stroke="#98C4E9"
          strokeWidth="2"
          variants={icon2}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 3 },
            fill: { duration: 5 }
          }}
        />
      </motion.svg>
    </VStack>
  );
};

export default Logo;
