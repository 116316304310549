import React from "react";
import { ModalOverlay } from "@chakra-ui/react";




export const CustomModalOverlay: React.FC = () => (
    <ModalOverlay
        css={{
            backdropFilter: 'blur(4px)',
            backgroundColor: 'rgba(255, 255, 255, 0.001)',
        }} />
);
