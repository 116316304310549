import React, { useEffect, useState } from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  Divider,
  HStack,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { ResultRouteProps } from "../view";
import { useAuth } from "../../../../context/AuthProvider/useAuth";

interface AddTextModalProps {
  isOpen: boolean;
  text: string;
  onClose: () => void;
  onSave: (newText: string) => void;
}

export const ModalSobre: React.FC<AddTextModalProps> = ({
  isOpen,
  text,
  onClose,
  onSave,
}) => {
  const { userSelected }= useAuth()
  const [newText, setNewText] = useState(text||userSelected?.descricao||"");
  const location = useLocation();
  const resultRoute: ResultRouteProps = location.state || {
    email: "",
    nome: "",
    foto: "",
    id: "",
    perfil: ""
  };
  const handleNewTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewText(e.target.value);
  };

  const handleSaveClick = () => {
    onSave(newText);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sobre o {resultRoute.perfil.toLocaleLowerCase()}</ModalHeader>
        <ModalCloseButton mt={2.5} />
        <Divider />
        <ModalBody minH={300}>
          <Text my={3}>Fala sobre o mentorado</Text>
          <Textarea
            h={"200px"}
            fontSize={"15px"}
            fontWeight="400"
            value={newText}
            onChange={handleNewTextChange}
            overflow={"auto"}
            bg="xmentor.background_template"
          />
        </ModalBody>
        <Divider />
        <ModalFooter gap={2}>
          <Button
            fontSize={"sm"}
            px={7}
            variant="outline"
            size="lg"
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            fontSize={"sm"}
            px={8}
            size="lg"
            bg={"xmentor.primary"}
            textColor={"white"}
            onClick={handleSaveClick}
            _hover={{ bg: "xmentor.primary" }}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
