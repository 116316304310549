import React, { useEffect, useState } from "react";
import {
  HStack,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Select,
  Divider,
  Grid,
  Box,
  Flex,
  Image,
  Text,
  useBreakpointValue,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  ModalOverlay,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { searchUser } from "../../../../context/AuthProvider/util";
import { IUser } from "../../../../context/AuthProvider/types";
import { CustomModalOverlay } from "../../trilha/components/CustomModalOverlay";
import { IModalAddMentor, IMentores } from "../view";
import { FaEdit, FaPlus, FaRegTrashAlt, FaTrashAlt } from "react-icons/fa";

export function ModalAddMentor(props: IModalAddMentor) {
  const toast = useToast();
  const {
    CriarVinculoMentorMentorado,
    DeletarVinculoMentorMentorado,
    userSelected,
  } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [mentores, setMentores] = useState<IMentores[]>([]);
  const [mentorSelected, setMentorSelected] = useState("");

  const templateColumns = useBreakpointValue({ base: "1fr", md: "1fr 1fr" });
  useEffect(() => {
    loadMentor();
  }, [props.isOpen]);

  const handleRemove = async (id: string) => {
    if (id.trim()) {
      await DeletarVinculoMentorMentorado(id, props.usuario_cliente_id);
      // props.onClose()
    }
  };

  const handleAdd = async (id: string) => {
    if (id.trim()) {
      await CriarVinculoMentorMentorado(id, props.usuario_cliente_id);
      // props.onClose()
    }
  };
  const loadMentor = async () => {
    if (props.mentoria_id.length > 0)
      try {
        const response = await searchUser({
          mentoria_id: props.mentoria_id,
          nome: "",
          pagina: 0,
          perfil: "mentor",
        });
        const prev = response.data.map((i: IUser) => {
          return {
            id: i.id,
            name: i.nome,
            foto: i.foto,
          };
        });
        setMentores(prev);
      } catch (error: any) {
        toast({
          position: "top",
          description: "Error",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
  };
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mentores do mentorado</ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody minH={300}>
          <HStack w="100%" justifyContent="space-between" mb="5">
            <Text fontWeight="bold" mb={3}>
              Mentores
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                colorScheme="primary"
                bgColor={"xmentor.primary"}
                color={"white"}
                icon={<FaPlus />}
                aria-label="Edit"
                ml={{ base: 0, md: "auto" }}
                mt={{ base: 4, md: 0 }}
              />
              <MenuList>
                {mentores &&
                  mentores
                    .filter(
                      (itemPrincipal) =>
                        !userSelected?.mentores.some(
                          (itemExcluir) =>
                            itemExcluir.mentor.id === itemPrincipal.id
                        )
                    )
                    .map((i: IMentores) => (
                      <MenuItem key={i.id} onClick={() => handleAdd(i.id)}>
                        <Flex align="center" borderRadius="lg">
                          <Image
                            rounded={10}
                            w="48px"
                            h="48px"
                            src={`${process.env.REACT_APP_API}/tmp/user/${i.foto}`}
                            alt=""
                          />
                          <Box textAlign={"left"} ml={2}>
                            <Text fontWeight="bold">{i.name}</Text>
                            {/* <Text fontSize="sm">Mentor Educação</Text> */}
                          </Box>
                        </Flex>
                      </MenuItem>
                    ))}
              </MenuList>
            </Menu>
          </HStack>
          <Grid templateColumns={templateColumns} gap={4} w="100%">
            {userSelected?.mentores.map((i) => (
              <Flex
                key={i.mentor.id}
                align="center"
                p={3}
                bgColor={"white"}
                borderWidth="1px"
                borderRadius="lg"
              >
                <Image
                  rounded={10}
                  w="64px"
                  h="64px"
                  src={`${process.env.REACT_APP_API}/tmp/user/${i.mentor?.foto}`}
                  alt=""
                />
                <Box textAlign={"left"} ml={2}>
                  <Text fontWeight="bold">{i.mentor.nome}</Text>
                  {/* <Text fontSize="sm">Mentor Educação</Text> */}
                </Box>
                <IconButton
                  icon={<FaRegTrashAlt />}
                  color="xmentor.text_gray"
                  aria-label="Delete"
                  ml={{ base: 0, md: "auto" }}
                  mt={{ base: 3, md: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemove(i.mentorId);
                  }}
                />
              </Flex>
            ))}
          </Grid>
        </ModalBody>
        <Divider />
        <ModalFooter gap={2}>
          <Button variant="outline" size="lg" onClick={props.onClose}>
            Cancelar
          </Button>
          <Button
            size="lg"
            bg={"xmentor.primary"}
            textColor={"white"}
            onClick={props.onClose}
            _hover={{ bg: "xmentor.primary" }}
          >
            Salvar
          </Button>
          {/* <Button colorScheme="blue" size="lg" onClick={handleAdd} isLoading={isLoading}>
            Salvar
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
