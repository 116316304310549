import React from "react";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import { TemplateMentorado } from "../../../components/layout/mentorado/TemplateMentorado";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { getOAuthURLAPI } from "../../../helpers/googleOAuthHelper";
import { useToast } from "@chakra-ui/react";
import GoogleAuthContainer from "../../../components/GoogleAuthContainer";

export function GoogleOAuth2() {
  const user = useAuth();
  const toast = useToast();

  const onHandleGoToOAuth2 = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      const response = await getOAuthURLAPI();
      if (response.length > 8) {
        window.location.replace(response);
      } else {
        toast({
          position: "top",
          description: "Ocorreu um erro ao criar o link de autorização",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (e) {
      console.log(e);
      toast({
        position: "top",
        description: "Ocorreu um erro ao criar o link de autorização",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };



  return (
    <TemplateAdmin>
      <GoogleAuthContainer cb={onHandleGoToOAuth2} />
    </TemplateAdmin>
  );
}
