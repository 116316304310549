import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  IconButton,
  Box,
  Grid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  Button,
  InputGroup,
  InputLeftAddon,
  Input,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import {
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaPlus,
  FaRegTrashAlt,
  FaLinkedin,
} from "react-icons/fa";
import { useAuth } from "../../../../context/AuthProvider/useAuth";

interface SocialMedia {
  id: string;
  name: string;
  url: string;
  icon: JSX.Element;
}

interface SocialMediaItemProps {
  socialMedia: SocialMedia;
  onChange: (value: string) => void;
  onDelete: (id: string) => void;
}

const SocialMediaItem: React.FC<SocialMediaItemProps> = ({
  socialMedia,
  onChange,
  onDelete,
}) => {

  const [value, setValue] = useState<string>(socialMedia.url)

  return (
    <InputGroup
      size='md'
      bgColor="gray.100"
      borderWidth="1px"
      borderRadius="lg"
    >
      <InputLeftAddon border="none" >
        <Box
          as="span"
          mr={3}
          onClick={() => window.open(socialMedia.url, '_blank')}
          cursor="pointer"
        >
          {socialMedia.icon}
        </Box>
        <Text fontWeight={500}>{socialMedia.name}:</Text>
      </InputLeftAddon>
      <Input
        pl={0}
        type="text"
        placeholder='Digite aqui'
        border="none"
        focusBorderColor="transparent"
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
      <InputRightElement >
        <IconButton
          icon={<FaRegTrashAlt />}
          color="xmentor.text_gray"
          aria-label="Delete"
          onClick={() => onDelete(socialMedia.id)}
        />
      </InputRightElement>
    </InputGroup>
  );
};
interface IProps {
  value: IRSProps
  onChange: (value: IRSProps) => void
  onUpdate: boolean
}

interface IRSProps {
  instagram: string
  linkedin: string
  youtube: string
}
export const SocialMediaList: React.FC<IProps> = ({ value, onChange, onUpdate }) => {
  const [instagram, setInstagram] = useState<string>(value.instagram || "");
  const [linkedin, setLinkedin] = useState<string>(value.linkedin || "");
  const [youtube, setYoutube] = useState<string>(value.youtube || "")
  const [socialMedias, setSocialMedias] = useState<SocialMedia[]>([]);
  const { userSelected } = useAuth()
  useEffect(() => {
    let newSocialMedias = [];
    if (value.instagram) newSocialMedias.push({ id: "1", name: "Instagram", url: value.instagram, icon: <FaInstagram /> });
    if (value.linkedin) newSocialMedias.push({ id: "2", name: "Linkedin", url: value.linkedin, icon: <FaLinkedin /> });
    if (value.youtube) newSocialMedias.push({ id: "3", name: "Youtube", url: value.youtube, icon: <FaYoutube /> });

    setSocialMedias(newSocialMedias);
  }, [, userSelected]);

  const addSocialMedia = (name: string, value: string) => {
    const newId = (socialMedias.length + 1).toString();
    const icon =
      name === "Instagram" ? (
        <FaInstagram />
      ) : name === "Linkedin" ? (
        <FaLinkedin />
      ) : (
        <FaYoutube />
      );
    const url = `${value}`;
    if (socialMedias.find(i => i.name === name)) return
    setSocialMedias(() => [...socialMedias, { id: newId, name, url, icon }]);

  };

  const handleDelete = (id: string) => {
    const sm = socialMedias.find((sm) => sm.id == id)
    sm?.name === "Instagram" ? (
      setInstagram("")
    ) : sm?.name === "Linkedin" ? (
      setLinkedin("")
    ) : sm?.name === "Youtube" && (
      setYoutube("")
    );
    setSocialMedias(socialMedias.filter((sm) => sm.id !== id));

  };

  useEffect(() => {
    const data = {
      instagram,
      linkedin,
      youtube
    }
    onChange(data)
  }, [instagram, linkedin, youtube])

  useEffect(() => {
    if (!onUpdate) {
      setSocialMedias([])
      setInstagram(() => "")
      setLinkedin(() => "")
      setYoutube(() => "")
    }
  }, [onUpdate])
  return (
    <>
      <Flex w="100%" justify={"space-between"}>
        <Heading size="sm" mt={2}>
          Redes sociais
        </Heading>
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FaPlus />}
            aria-label="Add"
            colorScheme="blue"
            borderRadius="md"
            size="md"
            bg="xmentor.primary"
            _hover={{ bg: "xmentor.primary", opacity: 0.8 }}
          />
          <MenuList>
            <MenuItem hidden={!!socialMedias.find(i => i.name == "Instagram")} onClick={() => addSocialMedia("Instagram", "")} >Instagram</MenuItem>
            <MenuItem hidden={!!socialMedias.find(i => i.name == "Linkedin")} onClick={() => addSocialMedia("Linkedin", "")}>Linkedin</MenuItem>
            <MenuItem hidden={!!socialMedias.find(i => i.name == "Youtube")} onClick={() => addSocialMedia("Youtube", "")}>Youtube</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <VStack        
        gap={4}
        w="100%"
      >
        {socialMedias.map((sm) => (
          <SocialMediaItem
            key={sm.id}
            socialMedia={sm}
            onChange={(value) => {
              switch (sm.name) {
                case 'Instagram':
                  setInstagram(value)
                  break;
                case 'Linkedin':
                  setLinkedin(value)
                  break;
                default:
                  setYoutube(value)
              }
            }
            }
            onDelete={handleDelete}
          />
        ))}
      </VStack>
    </>
  );
};
