import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  VStack,
  Text,
  Box,
  Button,
  HStack,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { CustomModalOverlay } from "./CustomModalOverlay";
import { useMentoriaGestao } from "../../../../context/MentoriaGestaoProvider/useMentoriaGestao";
import { FaRegCheckCircle } from "react-icons/fa";

interface IMentoradoChecklistModal {
  isOpen: boolean;
  onClose: () => void;
  nomeMentorado: string;
  mentoriaId: string;
  usuarioId: string;
  foto: string;
}

export function MentoradoChecklistModal({
  isOpen,
  onClose,
  nomeMentorado,
  mentoriaId,
  usuarioId,
  foto,
}: IMentoradoChecklistModal) {
  const navigate = useNavigate();
  const { etapasMentoria, CheckEtapa } = useMentoriaGestao();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <CustomModalOverlay />
      <ModalContent maxH="80vh" overflowY="auto">
        <ModalHeader fontWeight={600} color={"#101828"} fontSize={"18px"}>
          {nomeMentorado}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {etapasMentoria.slice(1).map((etapa, etapaIndex) => {
            const hasChecklist = etapa.CheckListGestao.length > 0;

            if (hasChecklist) {
              var completedCount = etapa.CheckListGestao.filter((i) =>
                i.CheckListGestaoUsuario.find(
                  (j) => j.usuario_id == usuarioId && j.check == true
                )
              ).length;
              var totalCount = etapa.CheckListGestao.length;
              var progressPercent = Math.round(
                (completedCount / totalCount) * 100
              ); // Arredonda a porcentagem para números inteiros

              return (
                <Box color={"#344054"} key={etapaIndex} mb={4}>
                  <HStack spacing={2}>
                    <Icon
                      w={"18px"}
                      h={"18px"}
                      as={FaRegCheckCircle}
                      color="#344054"
                    />
                    <Text fontSize={"18px"} fontWeight={600}>
                      {etapa.titulo}
                    </Text>
                    <Text ml={-1} fontSize={"14px"} fontWeight={400}>
                      ({progressPercent}%)
                    </Text>{" "}
                    {/* Exibe a porcentagem arredondada */}
                  </HStack>
                  <motion.div
                    style={{
                      marginTop: "5px",
                      height: "6px",
                      backgroundColor: "lightgray",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <motion.div
                      style={{
                        height: "100%",
                        backgroundColor: "#4963FF",
                      }}
                      animate={{ width: `${progressPercent}%` }}
                      transition={{ duration: 0.5 }}
                    />
                  </motion.div>
                  <VStack mt={"4"} spacing={2} align="start" mb={5}>
                    {etapa.CheckListGestao.map((item, itemIndex) => (
                      <Checkbox
                        key={item.id}
                        isChecked={
                          item.CheckListGestaoUsuario.find(
                            (i) => i.usuario_id == usuarioId && i.check == true
                          )
                            ? true
                            : false
                        }
                        colorScheme="blue"
                        fontSize={"16px"}
                        fontWeight={400}
                        onChange={() =>
                          CheckEtapa(usuarioId, mentoriaId, etapa.id, item.id)
                        }
                      >
                        {item.titulo}
                      </Checkbox>
                    ))}
                    <Divider />
                  </VStack>
                </Box>
              );
            } else {
              return (
                <Box key={etapaIndex} mb={4}>
                  <HStack spacing={2}>
                    <Icon
                      w={"18px"}
                      h={"18px"}
                      as={FaRegCheckCircle}
                      color="#344054"
                    />

                    <Text fontWeight={600} fontSize={"18px"}>
                      {etapa.titulo}
                    </Text>
                  </HStack>
                  <Divider mt={2} />
                </Box>
              );
            }
          })}
        </ModalBody>
        <ModalFooter justifyContent={"space-between"} w={"100%"}>
          <Button
            w={"40%"}
            colorScheme="black"
            variant={"outline"}
            mr={3}
            onClick={() =>
              navigate("/admin/users/view", {
                state: {
                  id: usuarioId,
                  nome: nomeMentorado,
                  email: "",
                  foto: foto,
                  perfil: "Mentorado",
                },
              })
            }
          >
            Ver Perfil
          </Button>
          <Button
            w={"40%"}
            colorScheme="facebook"
            backgroundColor="xmentor.primary"
            onClick={onClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
