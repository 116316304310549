import React from "react";
import { Box, Flex, Text, Icon, HStack, VStack } from "@chakra-ui/react";
import { FaUsers, FaUserTie, FaCalendarCheck, FaTasks } from "react-icons/fa";
import { MdEvent } from "react-icons/md";

const data = [
  { label: "Mentorados", value: "30", icon: FaUsers },
  { label: "Mentores", value: "6", icon: FaUserTie },
  { label: "Reuniões agendadas", value: "5", icon: FaCalendarCheck },
  { label: "Atividades realizadas", value: "57", icon: FaTasks },
  { label: "Eventos", value: "12", icon: MdEvent },
];


interface DashboardCardData {
  label: string;
  value: number;
  icon: React.ElementType;
}

interface DashboardCardsProps {
  data: DashboardCardData[];
}

const DashboardCards: React.FC<DashboardCardsProps> = ({ data }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      wrap="wrap"
      w="100%"
      gap="15px"
      py={4}
    >
      {data.map((card, index) => (
        <Box
          key={index}
          bg="white"
          boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)" // Define a sombra personalizada
          borderRadius="24px"
          p={6}
          textAlign="center"
          flex="1 1 calc(20% - 15px)" // Ajusta os cards para ocuparem 20% da largura com gap
          minW="200px" // Largura mínima para cada card
          maxW="100%"
        >
          <HStack>
            <Box
              p={4}
              borderRadius="full"
              bgColor="#F4F7FE"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={card.icon} w={8} h={8} color="xmentor.primary" />
            </Box>
            <VStack w="100%" align="start" spacing={0} ml={1}>
              <Text
                fontSize="sm"
                color="gray.500"
                mb={0}
                align="start"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxWidth="calc(100% - 1rem)"
              >
                {card.label}
              </Text>
              <Text fontSize="2xl" fontWeight="bold">
                {card.value}
              </Text>
            </VStack>
          </HStack>
        </Box>
      ))}
    </Flex>
  );
};

export default DashboardCards;