import { Box, Flex, useToast } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { DrawerSideBar } from "./Drawer";
import { Header } from "../Header";
import isAuthenticated from "../../../context/AuthProvider/util";
import { useNavigate } from "react-router-dom";

export interface Props{
    children: ReactNode
    fixedHeader? : boolean
}

export function TemplateMaster({ children, fixedHeader }: Props) {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()
    const toast = useToast()
    useEffect(()=>{
        if(!isAuthenticated()){
            navigate('/')
        }
        const timer = setInterval(() => {
          if(!isAuthenticated()){
            toast({
              position: 'top-right',
              description: "Sua sessão encerrou!",
              status: 'warning',
              duration: 5000,
              isClosable: true,
            })
            navigate('/')      
          }
          
        }, 3000);
    
        return () => {
          clearInterval(timer);
        };
    
      },[ navigate ])
    return (
        <Flex background="xmentor.background_template"  minH={"100vh"} >
            <DrawerSideBar  isOpen={isOpen} sendInfo={()=>setIsOpen(false)}  />
            {/* Corpo */}
            <Flex
                direction="column"
                flex="1"
                justifyContent="right"
                marginLeft={{ base: '0', lg: '312px' }}
            >
                {/* Header */}
                
                {/* Corpo */}
                <Box overflow={"hidden"} id="arrastadm" maxW={"full"} flex="2" p="4" px={{ base: "16px", lg: "64px" }} background="xmentor.background_template">
                    {/* Conteúdo do Corpo */}
                    {children}
                </Box>
            </Flex>
        </Flex>
    );
}
