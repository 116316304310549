import { Avatar, Text, VStack } from "@chakra-ui/react";

interface ButtonMentorProps {
    mentorName: string;
    mentorAvatarSrc: string;
    onClick: () => void;
  }

  
export function ButtonMentor(props: ButtonMentorProps) {
    const { mentorName, onClick } = props;
  
    return (
      <VStack onClick={onClick}>
        <Avatar h={"75px"} w={"75px"} src={props.mentorAvatarSrc} />
        <Text>{mentorName}</Text>
      </VStack>
    );
  }