import React, { useEffect, useState } from "react";
import {
  Avatar,
  VStack,
  Text,
  Stack,
  Button,
  HStack,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th, Modal, ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure, Input, useToast, Spinner,
  Center,
  Tooltip,
  Heading,
  Box,
  Td,
  Link
} from "@chakra-ui/react";
import { FaPlus, FaTrash } from "react-icons/fa";
import Calendar from "react-calendar";
import { ButtonMentor } from "../../../components/cards/ButtonMentor";
import {
  IMentorAgendamento,
  createAgendaAPI,
  deleteAgendaAPI,
  getMentorsAgendaAPI,
} from "../../../helpers/agendaHelper";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { CustomModalOverlay } from "../../admin/trilha/components/CustomModalOverlay";
import { TemplateMentor } from "../../../components/layout/mentor/TemplateMentor";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import { InputSearch } from "../../../components/Form/InputSearch";
import { getAgendamentos } from "../../../helpers/agendaHelper";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export interface ICreateNewTime {
  id?: string;
  data: string;
  time: string[] | string;
  mentor: string;
}

export function Agenda() {
  const [mentorData, setMentorData] = useState<IMentorAgendamento[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedMentor, setSelectedMentor] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedTimes, setSelectedTimes] = useState<string[]>([""]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [calendarSelectedDate, setCalendarSelectedDate] = useState<Date | null>(
    null
  );
  const [selectedTime, setSelectedTime] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = useAuth();
  const toast = useToast();

  const createNewTime = async (data: string, time: string[]) => {
    const agendamento = [
      {
        data: data,
        time: time,
        mentor: mentorData[selectedMentor as number].id,
      },
    ];
    const result = await createAgendaAPI({
      user_id: auth.payLoad?.user.id as string,
      agendamento: agendamento,
    });
    return result;
  };

  const addTimeToMentor = (times: ICreateNewTime[]) => {
    const data = times[0].data;
    const _mdata = mentorData[selectedMentor as number];
    if (!_mdata.agendamentos[data]) _mdata.agendamentos[data] = [];
    times.forEach((e) => {
      _mdata.agendamentos[data].push({
        id: e.id as string,
        link: "Aguardando mentorado confirmar data",
        time: e.time as string,
      });
    });
    var mData = mentorData;
    mData[selectedMentor as number] = _mdata;
    setMentorData(mData);
  };

  const removeTimeToMentor = (ids: string[], data: string) => {
    const _mdata = mentorData[selectedMentor as number];
    const ag = _mdata.agendamentos[data as string].filter(
      (a) => !ids.includes(a.id)
    );
    var mData = mentorData;
    if (ag.length === 0) {
      delete _mdata.agendamentos[data as string];
      mData[selectedMentor as number] = _mdata;
    } else {
      _mdata.agendamentos[data as string] = ag;
      mData[selectedMentor as number] = _mdata;
    }
    setMentorData(mData);
  };

  const convertTime = (e: string) => {
    const date = e.split("-");
    return `${date[2]}/${date[1]}/${date[0]}`;
  };

  const showSuccessToast = () => {
    toast({
      position: "top",
      description: "Operação realizada com sucesso",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  }

  const showErrorToast = () => {
    toast({
      position: "top",
      description: "Ocorreu um erro, operação não concluida",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  }

  const handleSelecteData = (e: string) => {
    const date = convertTime(e);
    const times = mentorData[selectedMentor as number].agendamentos[date];
    if (times) {
      const t = times.map((e) => {
        return e.time;
      });
      setSelectedTimes(t);
    } else {
      setSelectedTimes([]);
    }

    setSelectedDate(e);
  };

  const handleMentorClick = (index: number) => {
    setSelectedMentor(index);
  };

  const handleAddAppointment = async () => {
    setLoading(true);
    try {
      const date = convertTime(selectedDate);
      const agenda = mentorData[selectedMentor as number].agendamentos[date];
      if (agenda) {
        if (selectedTimes.length > agenda.length) {
          const oldTimes = agenda.map((e) => e.time);
          const timeToUpdate = selectedTimes.filter(
            (e) => !oldTimes.some((a) => a === e)
          );
          const result = await createNewTime(date, timeToUpdate);

          if (result.msg === "ok") {
            addTimeToMentor(result.agendamento as ICreateNewTime[]);
          }
          //PLUS
        } else if (selectedTimes.length < agenda.length) {
          const oldTimes = agenda.map((e) => e.time);
          const timeToUpdate = oldTimes.filter(
            (e) => !selectedTimes.some((a) => a === e)
          );
          const idsToRemove: string[] = [];
          timeToUpdate.forEach((e) => {
            agenda.forEach((a) => {
              if (a.time === e) idsToRemove.push(a.id);
            });
          });
          const result = await deleteAgendaAPI(idsToRemove);
          if (result.msg === "ok") {
            removeTimeToMentor(idsToRemove, date);
          }
        } else {
          var hasChange = false;
          for (const t in agenda) {
            if (selectedTimes.find((e) => e === agenda[t].time)) hasChange = true;
          }
          if (hasChange) {
            const oldTimes = agenda.map((e) => e.time);
            const timeToUpdate = selectedTimes.filter(
              (e) => !oldTimes.some((a) => a === e)
            );

            const timeToRemove = oldTimes.filter(
              (e) => !selectedTimes.some((a) => a === e)
            );

            const idsToRemove: string[] = [];
            timeToRemove.forEach((e) => {
              agenda.forEach((a) => {
                if (a.time === e) idsToRemove.push(a.id);
              });
            });

            const result1 = await deleteAgendaAPI(idsToRemove);
            if (result1.msg === "ok") {
              removeTimeToMentor(idsToRemove, date);
            }

            const result2 = await createNewTime(date, timeToUpdate);

            if (result2.msg === "ok") {
              addTimeToMentor(result2.agendamento as ICreateNewTime[]);
            }
          }
          //MODIFY
        }
      } else {
        const result = await createNewTime(date, selectedTimes);
        if (result.msg === "ok") {
          addTimeToMentor(result.agendamento);
        }
      }
      showSuccessToast()
    } catch (e) {
      showErrorToast()
    }
    setLoading(false);
  };

  const handleRemoveAppointment = async (date: string) => {
    setLoading(true);
    try {
      const ag = mentorData[selectedMentor as number].agendamentos[date];
      const idsToRemove = ag.map((e) => {
        return e.id;
      });

      const result = await deleteAgendaAPI(idsToRemove);
      if (result.msg === "ok") {
        const mentors = mentorData;
        const m = mentorData[selectedMentor as number];
        delete m.agendamentos[date as string];
        mentors[selectedMentor as number] = m;
        setMentorData(mentorData);
      }
      showSuccessToast()
    } catch (e) {
      showErrorToast()
    }
    setLoading(false);
  };

  const handleAddTimeInput = () => {
    setSelectedTimes([...selectedTimes, ""]);
  };

  const handleRemoveTimeInput = (index: number) => {
    const updatedTimes = [...selectedTimes];
    updatedTimes.splice(index, 1);
    setSelectedTimes(updatedTimes);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCalendarDateClick = (date: Date) => {
    setCalendarSelectedDate(date);
    var dateString = date.toISOString().split("T")[0];
    handleSelecteData(dateString);
    setSelectedTime(null);
    openModal();
  };

  const handleCalendarTimeClick = (time: number) => {
    setSelectedTime(time);
    closeModal();
  };

  useEffect(() => {
    const refresh = async () => {
      if (auth.isGoogle !== "true") window.location.replace(`${process.env.REACT_APP_FRONT}/oauth/google`);
      try {
        const mentors = await getMentorsAgendaAPI();
        if (mentors.msg === "ok") {
          setMentorData(mentors.agendamentos);
        }
      } catch (e) {
        showErrorToast()
      }
    };
    refresh();
  }, []);

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const renderListOfMentors = () => {
    return mentorData.map((m, index) => {
      return (
        <ButtonMentor
          key={m.id + index}
          mentorName={m.name}
          mentorAvatarSrc={`${process.env.REACT_APP_API}/tmp/user/${m.img}`}
          onClick={() => handleMentorClick(index)}
        />
      );
    });
  };

  const renderMentorDetails = () => {
    if (selectedMentor !== null) {
      return (
        <VStack w={"100%"} align={"start"}>
          <Button
            size="sm"
            variant={"outline"}
            alignSelf="start"
            onClick={() => setSelectedMentor(null)}
          >
            Voltar
          </Button>
          <HStack mt={5}>
            <Avatar h={"60px"} w={"60px"} src={`${process.env.REACT_APP_API}/tmp/user/${mentorData[selectedMentor].img}`} />
            <Text>{mentorData[selectedMentor].name}</Text>
          </HStack>
          <HStack
            mt="5"
            alignSelf={"center"}
            w={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text fontSize={"24px"} fontWeight={500}>
              Horários disponíveis
            </Text>
            <IconButton
              icon={<FaPlus size={25} color="" />}
              aria-label="Adicionar"
              bgColor="xmentor.primary"
              color="white"
              onClick={onOpen}
            />
          </HStack>
          {isLoading ? (
            <Center w={"100%"}>
              <Spinner />
            </Center>
          ) : (
            <Stack direction={["column", "row"]} spacing="24px" w={"100%"}>
              <Table mt="5" variant="simple" size={"sm"} h={"100px"}>
                <Thead borderRadius={"8px"}>
                  <Tr>
                    <Th>Data</Th>
                    <Th>Horários</Th>
                    <Th>Ações</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(mentorData[selectedMentor].agendamentos).map(
                    (e, index) => {
                      return (
                        <Tr
                          key={`${e[0]}-${mentorData[selectedMentor]}-${index}`}
                        >
                          <Th>{e[0]}</Th>
                          <Th>
                            {e[1].map((t) => {
                              return ` ${t.time} `;
                            })}
                          </Th>
                          <Th>
                            <IconButton
                              size={"sm"}
                              icon={<FaTrash />}
                              aria-label="Remover"
                              colorScheme="red"
                              onClick={() => handleRemoveAppointment(e[0])}
                            />
                          </Th>
                        </Tr>
                      );
                    }
                  )}
                </Tbody>
              </Table>

              <VStack>
                <Text fontSize={"24px"} fontWeight={500}>
                  Visualização
                </Text>

                <Calendar
                  tileClassName={({ date }) => {
                    var dateString = date.toISOString().split("T")[0];
                    dateString = convertTime(dateString);
                    return mentorData[selectedMentor].agendamentos[dateString]
                      ? "highlight"
                      : "";
                  }}
                  onChange={(date) => handleCalendarDateClick(date as Date)}
                  value={calendarSelectedDate || new Date()}
                />
                <VStack
                  h={"100%s"}
                  overflowX={"auto"}
                  overflowY={"hidden"}
                  whiteSpace={"nowrap"}
                  display={"block"}
                >
                  {selectedDate &&
                    selectedTimes.length &&
                    mentorData[selectedMentor].agendamentos[
                    convertTime(selectedDate)
                    ] && (
                      <>
                        <Text fontSize={"19px"} fontWeight={500}>
                          Horários disponíveis
                        </Text>
                        <HStack spacing={4} style={{ flexWrap: "nowrap" }}>
                          {mentorData[selectedMentor].agendamentos[
                            convertTime(selectedDate)
                          ].map((e, index) => {
                            return (
                              <Button
                                key={e.time}
                                size="sm"
                                onClick={() => handleCalendarTimeClick(index)}
                              >
                                {e.time}
                              </Button>
                            );
                          })}
                        </HStack>
                      </>
                    )}
                </VStack>
              </VStack>
            </Stack>
          )}

          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <CustomModalOverlay />
            <ModalContent>
              <ModalHeader>Horários</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack w={"100%"} align={"start"}>
                  <HStack w={"100%"}>
                    <Text>Dia:</Text>
                    <Input
                      type="date"
                      placeholder="Selecione a Data"
                      value={selectedDate}
                      min={getTodayDate()}
                      onChange={(e) => handleSelecteData(e.target.value)}
                    />
                  </HStack>
                  {selectedTimes.map((time, index) => (
                    <HStack key={index} mt={3} w={"100%"}>
                      <Text>Horário:</Text>
                      <Input
                        type="time"
                        placeholder="Adicione Horário"
                        value={time}
                        w={"100%"}
                        onChange={(e) => {
                          const updatedTimes = [...selectedTimes];
                          updatedTimes[index] = e.target.value;
                          setSelectedTimes(updatedTimes);
                        }}
                      />
                      <IconButton
                        icon={<FaTrash />}
                        aria-label="Remover"
                        colorScheme="red"
                        onClick={() => handleRemoveTimeInput(index)}
                      />
                    </HStack>
                  ))}
                  <Button mt={3} onClick={handleAddTimeInput}>
                    Adicionar
                  </Button>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={handleAddAppointment}
                >
                  Atualizar
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </VStack>
      );
    }
    return (
      <>
        <Text fontSize={20}>Escolha um Mentor</Text>
        <Stack mt={5} px={5} direction={["column", "row"]} spacing={"34px"}>
          {mentorData.length > 0 ? (
            renderListOfMentors()
          ) : (
            <Text>Não foi localizado nenhum mentor</Text>
          )}
        </Stack>
      </>
    );
  };

  return (
    <TemplateAdmin>
      <VStack align={"start"} my={"15px"} spacing={0} fontWeight={"bold"}>
        <HStack spacing={5} >
          <Heading fontSize={24} my="24px" fontWeight={700}>
            Agenda
          </Heading>
          <Tooltip
            label="Gerencie a agenda de reuniões dos mentores, disponibilizando horários para que os mentorados agendem reuniões com os mentores. Todas as reuniões são sincronizadas com o Google Meet e o Google Calendar."
            fontSize="sm"
            borderRadius={"5px"}
          >
            <QuestionOutlineIcon fontSize={"20px"} />
          </Tooltip>
        </HStack>
        <VStack
          px={5}
          border={"1px"}
          borderColor={"xmentor.borderCards"}
          borderRadius={"8px"}
          py={4}
          bgColor={"xmentor.background_content"}
          w={"100%"}
          align={"start"}
          minH={"500px"}
        >
          {renderMentorDetails()}
        </VStack>
        {selectedMentor == null && <Agendamentos />}
      </VStack>
    </TemplateAdmin>
  );
}

interface Participante {
  id: string;
  nome: string;
  foto: string;
  perfil: 'mentorado' | 'mentor';
}

interface Participacao {
  participante: Participante;
}

interface Reuniao {
  id: string;
  time: string;
  data: string;
  link: string;
  data_cadastro: string;
  created_by: string;
  isOpen: boolean;
  participacoes: Participacao[];
}

function Agendamentos() {
  const navigate = useNavigate()
  const { payLoad } = useAuth()
  const [reunioes, setReunioes] = useState<Reuniao[]>([])
  useEffect(() => {
    if (payLoad?.user.mentoria_id) agendamentos(payLoad?.user.mentoria_id)
  }, [, payLoad])

  async function agendamentos(mentoria_id: string) {
    const agendamentos = await getAgendamentos(mentoria_id)
    console.log(agendamentos)
    setReunioes(agendamentos)
  }

  return (
    <VStack align={"start"} my={"15px"} spacing={0} fontWeight={"bold"} w={"100%"}>
      <HStack spacing={5} w={"100%"}>
        <Heading fontSize={24} my="24px" fontWeight={700}>
          Reuniões agendadas
        </Heading>      
      </HStack>

      <Box bg="#FFF" borderRadius="8px" border="1px solid #EAECF0" w={'100%'}>
        <Table variant="simple" w="full" >
          <Thead >
            <Tr justifyContent="space-between">
              <Th>Data/hora</Th>
              <Th>Mentor</Th>
              <Th>Mentorado</Th>
              <Th>Link da reunião</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {reunioes &&
              reunioes.length > 0 &&
              reunioes.map((i) => {
                if (!i.isOpen)
                return (
                  <Tr key={i.id} justifyContent="space-between">
                    <Td>{i.data} - {i.time}</Td>
                    <Td>{i.participacoes.find(i => i.participante.perfil == "mentor")?.participante.nome}</Td>
                    <Td>{i.participacoes.find(i => i.participante.perfil == "mentorado")?.participante.nome || "Aguardando"}</Td>
                    <Td>{i.participacoes.find(i => i.participante.perfil == "mentorado") && <Link href={i.link} isExternal>{i.link} <ExternalLinkIcon mx='2px' /></Link>}</Td>
                    <Td>{i.isOpen ? "PENDENTE" : "CONFIRMADO"}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
    </VStack>
  )
}
