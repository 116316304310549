import React, { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IContext,
  IDataHoraAdicional,
  IDataReordenarEtapas,
  IEtapaMentoria,
  IMentoriaProvider,
  ITrilhaMentoria,
} from "./types";
import {
  alterarEtapaMentoria,
  avancarEtapaMentoria,
  cadastrarHorariaExtra,
  consultaEtapaMentoria,
  criarEtapaMentoria,
  deletarAnexoEtapaMentoria,
  deletarEtapaMentoria,
  listarTrilhaUsuario,
  reordenarEtapasMentoria,
  uploadFileEtapaMentoria,
} from "./util";
import { useToast } from "@chakra-ui/react";
import { IMentores } from "../../pages/admin/trilha/DTOs/mentor.dto";

export const MentoriaContext = createContext<IContext>({} as IContext);

export const MentoriaProvider = ({ children }: IMentoriaProvider) => {
  const navigate = useNavigate();
  const [etapasMentoria, setEtapasMentoria] = useState<IEtapaMentoria[]>([]);
  const [trilha, setTrilha] = useState<ITrilhaMentoria | null>(null);
  const toast = useToast();

  function moverMentorado(
    idEtapaAtual: string,
    idMentorado: string,
    idEtapaDestino: string
  ) {
    // Encontrar a etapa atual e a etapa de destino nos dados fornecidos
    const etapaAtualIndex = etapasMentoria.findIndex(
      (etapa) => etapa.id === idEtapaAtual
    );
    const etapaDestinoIndex = etapasMentoria.findIndex(
      (etapa) => etapa.id === idEtapaDestino
    );

    // Se as etapas existirem
    if (etapaAtualIndex !== -1 && etapaDestinoIndex !== -1) {
      // Encontrar o mentorado na etapa atual
      const mentoradoIndex = etapasMentoria[
        etapaAtualIndex
      ].mentorados.findIndex((mentorado) => mentorado.id === idMentorado);

      // Se o mentorado existir na etapa atual
      if (mentoradoIndex !== -1) {
        // Remover o mentorado da etapa atual e adicionar à etapa de destino
        const mentoradoRemovido = etapasMentoria[
          etapaAtualIndex
        ].mentorados.splice(mentoradoIndex, 1)[0];
        etapasMentoria[etapaDestinoIndex].mentorados.push(mentoradoRemovido);
        // Atualizar o estado com as etapas modificadas
        setEtapasMentoria([...etapasMentoria]);
      } else {
        console.log("O mentorado não foi encontrado na etapa atual.");
      }
    } else {
      console.log("As etapas fornecidas não foram encontradas.");
    }
  }
  const showSuccessToast = (description: string) => {
    toast({
      position: "top",
      description,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const showErrorToast = (error: any) => {
    toast({
      position: "top",
      description: error.response?.data?.message || error || "Error",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const ConsultaEtapaMentoria = async (mentoria_id: string) => {
    try {
      const response = await consultaEtapaMentoria(mentoria_id);
      setEtapasMentoria(() => response);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const ListarTrilhaUsuario = async (usuario_id: string) => {
    try {
      const response = await listarTrilhaUsuario(usuario_id);
      setTrilha(response);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const CriarEtapaMentoria = async (
    titulo: string,
    descricao: string,
    mentoria_id: string,
    mentores: IMentores[]
  ): Promise<{ value: boolean; result: any }> => {
    try {
      const result = await criarEtapaMentoria(
        titulo,
        mentoria_id,
        descricao,
        mentores
      );

      return result;
    } catch (error) {
      showErrorToast(error);
      return {
        value: false,
        result: null,
      };
    }
  };

  const AvancarEtapaMentoria = async (
    etapa_atual_id: string,
    mentoria_id: string,
    etapa_id: string,
    usuario_id: string
  ) => {
    try {
      moverMentorado(etapa_atual_id, usuario_id, etapa_id);
      const response = await avancarEtapaMentoria(
        mentoria_id,
        etapa_id,
        usuario_id
      );
      // setEtapasMentoria(() => response)
    } catch (error) {
      showErrorToast(error);
    }
  };
  const DeletarEtapaMentoria = async (
    mentoria_id: string,
    etapa_id: string
  ) => {
    try {
      const response = await deletarEtapaMentoria(etapa_id);
      if (response&&etapasMentoria && etapasMentoria.length > 1) {
        let novasEtapas: IEtapaMentoria[] = etapasMentoria.filter(i => i.id !== etapa_id);
        setEtapasMentoria(() => novasEtapas);
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const DeletarAnexoEtapaMentoria = async (
    id: string,
    etapa_id: string
  ) => {
    try {
      const response = await deletarAnexoEtapaMentoria(id);
      if (response && etapasMentoria && etapasMentoria.length > 1) {
        // Encontra a etapa específica e clona-a
        const etapasAlterada = etapasMentoria.find(i => i.id === etapa_id);
  
        if (etapasAlterada) {
          // Filtra os anexos removendo aquele com o id específico
          const anexos = etapasAlterada.anexos.filter(i => i.id !== id);
          
          // Cria uma nova etapa com os anexos atualizados
          const novaEtapa = { ...etapasAlterada, anexos };
  
          // Atualiza o array de etapas removendo a etapa antiga e inserindo a nova
          let novasEtapas = etapasMentoria.map(i =>
            i.id === etapa_id ? novaEtapa : i
          );
  
          setEtapasMentoria(novasEtapas);
        }
      }
    } catch (error) {
      showErrorToast(error);
    }
  };
  
  

  const AlterarEtapaMentoria = async (
    mentoria_id: string,
    etapa_id: string,
    titulo: string,
    descricao: string,
    mentores: IMentores[]
  ) => {
    try {
      const response = await alterarEtapaMentoria(
        etapa_id,
        titulo,
        descricao,
        mentores
      );
      setEtapasMentoria(() => response);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const UploadFileEtapaMentoria = async (data: FormData) => {
    try {
      await uploadFileEtapaMentoria(data);
    } catch (error) {
      showErrorToast(error);
    }
  };
  
  const CadastrarHorariaExtra = async (data: IDataHoraAdicional) => {
    try {
      const result = await cadastrarHorariaExtra(data);
      return result
    } catch (error) {
      showErrorToast(error);
    }
  };

  const ReordenarEtapasMentoria = async (
    data: IDataReordenarEtapas, etapasState: IEtapaMentoria[]
  ) => {
    try {
      setEtapasMentoria(() => etapasState);
      await reordenarEtapasMentoria(data);
    } catch (error) {
      showErrorToast(error);
    }
  };
  return (
    <MentoriaContext.Provider
      value={{
        ConsultaEtapaMentoria,
        ListarTrilhaUsuario,
        CriarEtapaMentoria,
        AvancarEtapaMentoria,
        DeletarEtapaMentoria,
        DeletarAnexoEtapaMentoria,
        AlterarEtapaMentoria,
        etapasMentoria,
        trilha,
        UploadFileEtapaMentoria,
        CadastrarHorariaExtra,
        ReordenarEtapasMentoria
      }}
    >
      {children}
    </MentoriaContext.Provider>
  );
};
