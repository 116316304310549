import React, { useState } from "react";
import {
  VStack,
  HStack,
  Text,
  IconButton,
  Box,
  Button,
} from "@chakra-ui/react";
import { MdModeEditOutline } from "react-icons/md";
import { useAuth } from "../../../../context/AuthProvider/useAuth";

type AboutProps = {
  description: string;
  handleOpenSobre: () => void;
};

const AboutSectionMentor = ({ description, handleOpenSobre }: AboutProps) => {
  const {userSelected} = useAuth()
  return (
    <VStack
      align={"flex-start"}
      p='8'
      bg="white"
      w="100%"
      minH={"128px"}
      borderWidth="1px"
      borderRadius="lg"
      position="relative"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize="xl" fontWeight="bold" mb={3}>
          Sobre
        </Text>
        <IconButton
          colorScheme="primary"
          bgColor={"xmentor.primary"}
          color={"white"}
          fontSize="20px"
          onClick={handleOpenSobre}
          icon={<MdModeEditOutline />}
          aria-label="Add"

        />
      </HStack>
      
        <Text
          fontSize={"sm"}
          fontWeight="400"
          color={"xmentor.text_gray"}
          whiteSpace="pre-wrap"
          wordBreak="break-word"
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            
          }}
        >
          {userSelected?.descricao}
        </Text>
      
     
    </VStack>
  );
};

export default AboutSectionMentor;
