// src/components/ButtonNavigation.tsx
import { Button, Icon, Text, useTheme } from "@chakra-ui/react";
import { ElementType, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface PropsButtonNavigation {
    title: string;
    url: string;
    icon: ElementType;
    active?: boolean;
    isExpanded: boolean;
}

// Função para escurecer a cor em hexadecimal
const darkenColor = (color: string, factor: number) => {
    const hex = color.replace("#", "");
    const r = Math.max(0, Math.min(255, parseInt(hex.substring(0, 2), 16) - factor * 255)).toString(16).padStart(2, '0');
    const g = Math.max(0, Math.min(255, parseInt(hex.substring(2, 4), 16) - factor * 255)).toString(16).padStart(2, '0');
    const b = Math.max(0, Math.min(255, parseInt(hex.substring(4, 6), 16) - factor * 255)).toString(16).padStart(2, '0');
    return `#${r}${g}${b}`;
};

export function ButtonNavigation({ title, url, icon, active, isExpanded }: PropsButtonNavigation) {
    const [isActive, setIsActive] = useState(active || false);
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;
    const primaryColor = theme.colors.xmentor.primary;

    const handleClick = () => {
        setIsActive(!isActive);
        navigate(url);
    };

    useEffect(() => {
        setIsActive(location.pathname === url);
    }, [location.pathname, url]);

    return (
        <Button
            w={"100%"}
            display="flex"
            padding={4}
            alignItems="center"
            justifyContent={isExpanded ? "flex-start" : "center"}
            bg={isActive ? "white" : "transparent"}
            color={isActive ? primaryColor : "white"}
            onClick={handleClick}
            transition={"0.5s"}
            colorScheme="facebook"
            _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
        >
            <Icon as={icon} fontSize='20' mr={isExpanded ? 4 : 0} />
            {isExpanded && <Text>{title}</Text>}
        </Button>
    );
}
