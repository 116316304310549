export const timeMapping: { [key: number]: number } = {
  1: 15,
  2: 30,
  3: 60,
  4: 90,
  5: 120,
  6: 150,
  7: 180,
  // Adicione outras opções conforme necessário
};

export const minutesToIndex = (minutes: number) => {
  for (const [key, value] of Object.entries(timeMapping)) {
    if (value === minutes) {
      return parseInt(key);
    }
  }
  return 0;
};

export const formatHours = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let formatted = "";
  if (hours > 0) {
    formatted += `${hours} h${hours > 1 ? "s" : ""}`;
  }
  if (remainingMinutes > 0) {
    if (formatted) {
      formatted += " e ";
    }
    formatted += `${remainingMinutes} min`;
  }
  return formatted;
};
