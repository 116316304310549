export function LogoMenor(){
    return (
        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1227_8591)">
                <path d="M28.4072 19.7447C28.4449 19.7376 28.4402 19.7211 28.393 19.6952C28.3801 19.6881 28.3689 19.6787 28.3594 19.6669C26.1974 16.9143 23.8886 13.96 21.433 10.8042C20.9002 10.1181 20.4516 9.62773 20.0874 9.33301C18.6321 8.15533 16.5243 8.12173 14.9965 9.211C14.0887 9.85702 13.3396 10.4523 12.749 10.997C11.4864 12.1587 11.0797 14.1516 11.8118 15.7714C11.9933 16.1722 12.2621 16.5995 12.6181 17.0534C17.732 23.5642 22.8235 30.0586 27.8926 36.5364C27.9999 36.6732 28.1119 36.6767 28.2286 36.547L44.4421 18.3495" stroke="white" stroke-width="2" />
                <path d="M44.442 18.3496C47.0072 15.3222 49.6326 12.3079 52.318 9.30651C53.0583 8.47777 53.4427 7.57594 53.4709 6.60102C53.5087 5.31606 53.0071 4.17964 51.9661 3.19175C51.1456 2.41253 50.4248 1.79598 49.8035 1.34212C48.1572 0.137912 45.6197 0.364254 44.2953 1.85316C39.0093 7.79344 33.7132 13.7573 28.4072 19.7447" stroke="white" stroke-width="2" />
                <path d="M44.442 18.3496C43.9905 18.4922 43.5603 18.6902 43.1512 18.9437C42.4344 19.3893 42.0324 19.6333 41.9452 19.6758C40.6414 20.3088 39.3334 20.7055 38.0214 20.8658C35.5941 21.1617 33.0896 21.0138 30.5079 20.422C29.5919 20.2122 28.8917 19.9864 28.4072 19.7447C33.7132 13.7573 39.0093 7.79344 44.2953 1.85316C45.6197 0.364254 48.1572 0.137912 49.8035 1.34212C50.4248 1.79598 51.1456 2.41253 51.9661 3.19175C53.0071 4.17964 53.5087 5.31606 53.4709 6.60102C53.4427 7.57594 53.0583 8.47777 52.318 9.30651C49.6326 12.3079 47.0072 15.3222 44.442 18.3496Z" fill="white" />
               
                <path d="M44.442 18.3495C43.9905 18.4922 43.5603 18.6902 43.1512 18.9437C42.4344 19.3893 42.0325 19.6333 41.9452 19.6758C40.6414 20.3088 39.3334 20.7055 38.0214 20.8658C35.5941 21.1617 33.0896 21.0138 30.5079 20.422C29.5919 20.2122 28.8917 19.9864 28.4072 19.7447" stroke="white" stroke-width="2" />
                <path d="M28.4071 19.7448C28.8916 19.9864 29.5919 20.2122 30.5079 20.422C33.0896 21.0138 35.5941 21.1618 38.0213 20.8659C39.3334 20.7056 40.6414 20.3089 41.9452 19.6758C42.0324 19.6334 42.4344 19.3894 43.1512 18.9437C43.5602 18.6903 43.9905 18.4922 44.442 18.3496L28.2285 36.5471C28.1118 36.6768 27.9998 36.6732 27.8926 36.5365C22.8235 30.0586 17.7319 23.5643 12.618 17.0534C12.262 16.5996 11.9932 16.1722 11.8117 15.7714C11.0796 14.1517 11.4863 12.1588 12.7489 10.997C13.3395 10.4524 14.0887 9.85708 14.9964 9.21106C16.5242 8.12179 18.632 8.15539 20.0873 9.33307C20.4516 9.62779 20.9001 10.1182 21.433 10.8043C23.8886 13.9601 26.1974 16.9143 28.3594 19.667C28.3688 19.6788 28.38 19.6882 28.393 19.6953C28.4401 19.7212 28.4449 19.7377 28.4071 19.7448Z" fill="white" />

                <path d="M10.0983 56.3183C8.11247 57.1441 6.51217 56.5305 5.01796 55.176C3.84558 54.1115 2.46631 53.1036 2.35491 51.3795C2.30893 50.6639 2.34076 50.0751 2.4504 49.613C2.63076 48.8579 3.12942 48.1983 3.64576 47.6166C8.67715 41.9628 13.6832 36.3266 18.6639 30.7082C18.6693 30.7019 18.676 30.697 18.6836 30.6937C18.6912 30.6903 18.6994 30.6887 18.7076 30.6889C18.7159 30.689 18.724 30.691 18.7314 30.6946C18.7388 30.6983 18.7453 30.7035 18.7505 30.7099L25.4311 39.4312C25.455 39.4621 25.4674 39.5004 25.4661 39.5394C25.4648 39.5785 25.4499 39.6158 25.4241 39.6451C20.9632 44.6588 16.4458 49.7485 11.8719 54.9143C11.2482 55.6181 10.657 56.0861 10.0983 56.3183Z" fill="white" />
                <path d="M42.1768 49.6696C40.2848 50.3981 38.3361 49.8517 37.093 48.2691C34.7765 45.3195 32.5113 42.4278 30.2974 39.5938C30.2801 39.5713 30.2711 39.5435 30.272 39.5151C30.273 39.4866 30.2839 39.4595 30.3027 39.4382L37.4608 31.4349C37.469 31.4259 37.479 31.4189 37.4902 31.4142C37.5015 31.4095 37.5136 31.4073 37.5257 31.4078C37.5378 31.4084 37.5497 31.4115 37.5605 31.4171C37.5713 31.4227 37.5807 31.4306 37.5881 31.4402C39.9906 34.5088 42.4745 37.6676 45.0397 40.9165C45.6763 41.724 46.0859 42.363 46.2687 42.8333C46.9654 44.6405 46.3624 46.621 44.8116 47.8199C44.0035 48.4459 43.0963 49.3141 42.1768 49.6696Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1227_8591">
                    <rect width="140.226" height="58" fill="white" transform="translate(0.887207)" />
                </clipPath>
            </defs>
        </svg>
    )
}