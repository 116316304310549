import React from "react";
import {
  Flex,
  VStack,
  HStack,
  Text,
  Button,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import {
  FaWhatsapp,
  FaPhone,
  FaMailBulk,
  FaCalendar,
  FaBusinessTime,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import { FaEarthAmericas } from "react-icons/fa6";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import {
  MdOutlineApartment,
  MdOutlineCalendarToday,
  MdOutlineMail,
  MdOutlinePhoneIphone,
} from "react-icons/md";

export const InformationSectionMentor = () => {
  const openPage = (platform: string, username: string) => {
    let url;
    switch (platform) {
      case "instagram":
        url = `https://www.instagram.com/${username}`;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/in/${username}`;
        break;
      case "youtube":
        url = `https://www.youtube.com/${username}`;
        break;
      default:
        return;
    }
    window.open(url, "_blank");
  };
  
  const { userSelected } = useAuth();
  return (
        <VStack align="start" w="100%" spacing={0} px={'10'} pb={5}>
           <Text
              w={'100%'}
              fontWeight={"bold"}
              fontSize={"2xl"}
              color={"black"}
            >
              {userSelected?.nome}
            </Text>
           
            
          <Text fontSize={"md"} color="xmentor.text_gray">
                {userSelected?.ocupacao}
              </Text>
              <Stack direction="row" justifySelf={"start"} spacing={2} mt="auto">
              {userSelected?.redes_sociais?.instagram && (
                <IconButton
                  bg="white"
                  alignSelf={"start"}
                  border="1px"
                  borderColor={"xmentor.borderCards"}
                  color={"xmentor.text_gray"}
                  icon={<FaInstagram />}
                  fontSize="lg"
                  aria-label="Instagram"
                  onClick={() =>
                    openPage("instagram", userSelected.redes_sociais.instagram)
                  }
                />
              )}
              {userSelected?.redes_sociais?.linkedin && (
                <IconButton
                  bg="white"
                  border="1px"
                  borderColor={"xmentor.borderCards"}
                  color={"xmentor.text_gray"}
                  icon={<FaLinkedin />}
                  fontSize="lg"
                  aria-label="LinkedIn"
                  onClick={() =>
                    openPage("linkedin", userSelected.redes_sociais.linkedin)
                  }
                />
              )}
              {userSelected?.redes_sociais?.youtube && (
                <IconButton
                  bg="white"
                  border="1px"
                  borderColor={"xmentor.borderCards"}
                  color={"xmentor.text_gray"}
                  icon={<FaYoutube />}
                  fontSize="lg"
                  aria-label="YouTube"
                  onClick={() =>
                    openPage("youtube", userSelected.redes_sociais.youtube)
                  }
                />
              )}
            </Stack>
        </VStack>

  );
};

export default InformationSectionMentor;
