import React from "react";
import {
  VStack,
  HStack,
  Text,
  IconButton,
  Grid,
  Flex,
  Box,
  Image,
} from "@chakra-ui/react";
import { MdModeEditOutline } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { ResultRouteProps } from "../view";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { formatHours } from "../../../../utils/calculateHours";

type MentorSectionProps = {
  setIsModalAddMentor: (arg: boolean) => void;
  resultRoute: ResultRouteProps;
  usuario_cliente_id: string;
};

export const MentorSection = ({
  setIsModalAddMentor,
  resultRoute,
  usuario_cliente_id,
}: MentorSectionProps) => {
  const { payLoad, userSelected, DeletarVinculoMentorMentorado } = useAuth();

  // Check if user is "auxiliar"
  const isAuxiliar = payLoad?.user.perfil === "auxiliar";

  // Check if user is "mentor" and is not admin
  const isMentorAndAdmin =
    payLoad?.user.perfil === "mentor" && payLoad?.user.isAdmin;

  const handleRemove = async (id: string) => {
    if (id.trim()) {
      await DeletarVinculoMentorMentorado(id, usuario_cliente_id);
      // props.onClose()
    }
  };

  return (
    <VStack
      align={"flex-start"}
      p={[2, 5]}
      bg="white"
      w="100%"
      borderWidth="1px"
      borderRadius="lg"
      hidden={
        resultRoute.perfil === "Auxiliar" || resultRoute.perfil === "Mentor"
      }
    >
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize="xl" fontWeight="bold" mb={3}>
          Mentores
        </Text>
        <IconButton
          colorScheme="primary"
          bgColor={"xmentor.primary"}
          color={"white"}
          icon={<MdModeEditOutline />}
          fontSize="20px"
          aria-label="Edit"
          ml={{ base: 0, md: "auto" }}
          mt={{ base: 4, md: 0 }}
          onClick={() => setIsModalAddMentor(true)}
          hidden={!isAuxiliar && !isMentorAndAdmin}
        />
      </HStack>
      <Flex wrap="wrap" gap={4} w="100%">
        {userSelected?.mentores.map((i) => (
          <Flex
            key={i.mentor.id}
            align="center"
            p={3}
            bgColor={"white"}
            borderWidth="1px"
            borderRadius="2xl"
            w={{ base: "100%", sm: "48%", md: "48%", lg: "48%" }}
            maxW={{ base: "100%", sm: "48%", md: "48%", lg: "50%" }}

            flexGrow={1}
          >
            <Image
              rounded={10}
              w="58px"
              h="58px"
              src={`${process.env.REACT_APP_API}/tmp/user/${i.mentor?.foto}`}
              alt=""
            />
            <VStack
              textAlign={"left"}
              alignItems={"left"}
              spacing={0}
              ml={3}
              flex="1"
            >
               <Text fontWeight="bold">{i.mentor.nome}</Text>
              {i.totalCargaHoraria && i.totalCargaHoraria > 0 && (
                
                <Text
                  fontSize={"xs"}
                  color="xmentor.text_gray"
                  _hover={{ textDecoration: "underline" }}
                >
                  {formatHours(i.totalCargaHoraria)}
                  {i.totalHorasExtras && i.totalHorasExtras > 0 ? (
                    <Flex> + {formatHours(i.totalHorasExtras)}</Flex>
                  ): ""}
                </Text>
              )}
             
              {/* <Text fontSize="sm" color="xmentor.text_gray">
                Mentor Educação
              </Text> */}
            </VStack>
            {/* <IconButton
              color="xmentor.text_gray"
              icon={<FaRegTrashAlt />}
              bg={""}
              aria-label="Delete"
              ml={{ base: 0, md: "auto" }}
              mt={{ base: 3, md: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                handleRemove(i.mentor.id);
              }}
            /> */}
          </Flex>
        ))}
      </Flex>
    </VStack>
  );
};

export default MentorSection;
