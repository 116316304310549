import React, { FormEvent, useState } from "react";
import {
  Text,
  Button,
  Textarea,
  ModalFooter,
  Stack,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
  ModalOverlay,
} from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { IModalAddNotes } from "../view";
export function ModalAddTags(props: IModalAddNotes) {
  const { AddTag, payLoad } = useAuth();
  const [isLoading, setIsLoading] = useState(false)
  const [newActionTitle, setNewActionTitle] = useState<string>("");

  const handleAdd = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newActionTitle.trim() && payLoad && payLoad.user) {
      setIsLoading(true)
      await AddTag(
        newActionTitle,
        "red",
        props.usuario_cliente_id
      );
      
      setNewActionTitle("");
      props.onClose();
      setIsLoading(false)
    }
  };

  const handleCancel = () => {
    setNewActionTitle("")
    props.onClose();
    setIsLoading(false)
  }
  return (
    <Modal
      isCentered
      isOpen={props.isOpen}
      size={"2xl"}
      onClose={props.onClose}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleAdd}>
        <ModalHeader>Tag</ModalHeader>
        <Divider />
        <ModalCloseButton mt={2} />
        <ModalBody>
          <Stack spacing={3} maxW={"470px"} mx="auto">
            <Text fontSize="sm" fontWeight="bold" color="black">
              Título
            </Text>
            <Input
              required
              type="text"
              bg="xmentor.background_template"
              value={newActionTitle}
              onChange={(e) => setNewActionTitle(e.target.value)}
              placeholder="Título"
            />            
          </Stack>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button
            fontSize={"sm"}
            px={7}
            variant="outline"
            size="md"
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            fontSize={"sm"}
            px={6}
            size="md"
            bg={"xmentor.primary"}
            textColor={"white"}
            _hover={{ bg: "xmentor.primary" }}
            type="submit"
            isLoading={isLoading}
          >
            Adicionar tag
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
