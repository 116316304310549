import {
  Avatar,
  Badge,
  Box,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
  Image,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiBell } from 'react-icons/fi';
import { RiMenuLine } from 'react-icons/ri';
import { LogoSvg } from './LogoSvg';
import { useAuth } from '../../context/AuthProvider/useAuth';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import { socket } from '../../server/socket';
import { INotification } from '../../context/AuthProvider/types';
import { authUser } from '../../context/AuthProvider/util';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface PropsDrawer {
  sendInfo: () => void;
  fixed?: boolean;
  updateNotification: () => void;
  isMenu?: boolean;
}

export function Header({ sendInfo, fixed, updateNotification, isMenu }: PropsDrawer) {
  const isWideVersion = useBreakpointValue({ base: false, lg: true });
  const { payLoad, FindNotifications, notifications, payLoadLogin, AuthUser } = useAuth();
  const toast = useToast();
  const [mentoriaId, setMentoriaId] = useState("")
  const corSidebar = payLoad?.configMentoria?.cor
  const logoSidebar = payLoad?.configMentoria?.foto
  const nome = payLoad?.configMentoria?.nome

  useEffect(() => {
    if (payLoad && payLoad.user.id) {
      FindNotifications(payLoad.user.id);
      setMentoriaId(payLoad.user.mentoria?.id || "")
    }
  }, [payLoad]);

  useEffect(() => {
    socket.emit('authenticate', payLoad?.user.id);
    socket.on('notification', (notification: string) => {
      setTimeout(() => {
        if (payLoad && payLoad.user.id) {
          FindNotifications(payLoad.user.id);
        }
      }, 750);

      toast({
        position: 'top-right',
        description: notification,
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      updateNotification();
    });

    return () => {
      socket.disconnect();
    };
  }, [FindNotifications, payLoad, toast, updateNotification]);

  async function authenticateUser(mentoriaId: string) {
    try {
      if (payLoadLogin?.login?.id) {
        // por aqui 
        const data = await authUser(payLoadLogin?.login?.id, mentoriaId)
        AuthUser(data)
      }
    } catch (error) {
      toast({
        title: "Erro ao fazer login",
        description: "Verifique suas credenciais e tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }
  return (
    <Box
      bg={"xmentor.background_content"}
      pt={4}
      pb={{ base: '0px', lg: '4' }}
      px={{ base: '0px', lg: '64px' }}
      display="flex"
      justifyContent="space-between"
      zIndex={0}
      alignItems="center"
      pos={fixed ? 'fixed' : 'relative'}
      w="100%"
      // bgColor={!isWideVersion ? 'xmentor.primary' : 'white'}
      bgColor={!isWideVersion ? (corSidebar ? corSidebar : 'xmentor.primary') : 'white'}
      pr={40}
    >
      {!isWideVersion ? (
        <VStack w={'100vw'} px={'16px'} >
          <HStack w={'100%'} justifyContent={'space-between'} p={0}>
            {logoSidebar ? (
              <Image
                src={`${process.env.REACT_APP_API}/tmp/mentoria/${logoSidebar}`}
                alt="Logo"
                width={"70px"} // Ajusta o tamanho da imagem conforme o estado expandido
                height={"70px"} // Ajusta a altura conforme o estado expandido
              />
            ):<LogoSvg />
            }
            <IconButton
              aria-label="Open navigation"
              icon={<Icon as={RiMenuLine} />}
              fontSize="35"
              variant="unstyled"
              onClick={sendInfo}
              color="xmentor.background_content"
            />
          </HStack>
          <HStack mt={2} spacing={4} w="100vw" p={'16px'} justifyContent="space-between" background={'xmentor.background_content'}>
            <HStack>
              <VStack spacing={0} display="flex" alignItems="flex-start">
                <Text fontStyle="normal" fontWeight={700} lineHeight="20px">
                  {/* {capitalizeFirstLetter(payLoad?.user.mentoria?.nome || '')} */}
                  {capitalizeFirstLetter(nome || '')}
                </Text>
              </VStack>
            </HStack>
            <HStack>
              <MenuNotification notifications={notifications} />
            </HStack>
          </HStack>
        </VStack>
      ) : (
        <HStack spacing={4} w="full" justifyContent="space-between">
          {
            payLoadLogin?.login && payLoadLogin.login?.mentorias.filter(i => i).length > 1 ?
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  <HStack>
                    <VStack spacing={0} display="flex" alignItems="flex-start">
                      <Text fontStyle="normal" fontWeight={700} lineHeight="20px" fontSize="lg">
                        {capitalizeFirstLetter(payLoad?.user.mentoria?.nome || '')}
                      </Text>
                    </VStack>
                  </HStack>
                </MenuButton>
                <MenuList>
                  {payLoadLogin.login.mentorias.map(i => {
                    if (i) {
                      return (
                        <MenuItem onClick={() => authenticateUser(i.id)} disabled={i.id == mentoriaId}>
                          <HStack>
                            <VStack spacing={0} display="flex" alignItems="flex-start">
                              <Text fontStyle="normal" fontWeight={700} lineHeight="20px" fontSize="lg">
                                {capitalizeFirstLetter(i.nome || '')}
                              </Text>
                            </VStack>
                          </HStack>
                        </MenuItem>
                      )
                    }
                  })}
                </MenuList>
              </Menu>
              :
              <VStack spacing={0} display="flex" alignItems="flex-start">
                <Text fontStyle="normal" fontWeight={700} lineHeight="20px" fontSize="lg">
                  {capitalizeFirstLetter(payLoad?.user.mentoria?.nome || '')}
                </Text>
              </VStack>
          }



          <HStack>
            <MenuNotification notifications={notifications} />
            <Avatar
              display="flex"
              h={12}
              w={12}
              bg="xmentor.primary"
              name="User"
              borderRadius={8}
              src={`${process.env.REACT_APP_API}/tmp/user/${payLoad?.user.foto}`}
              border="1px solid #D0D5DD"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.08)"
            />
            <VStack spacing={0} display="flex" alignItems="flex-start">
              <Text fontStyle="normal" fontWeight={700} lineHeight="20px">
                {capitalizeFirstLetter(payLoad?.user.nome || '')}
              </Text>
              <Text fontStyle="normal" fontWeight={400} lineHeight="20px" color="xmentor.text_gray">
                {payLoad?.user.email}
              </Text>
            </VStack>
          </HStack>
        </HStack>
      )}
    </Box>
  );
}

interface MenuNotificationProps {
  notifications: INotification[];
}

function MenuNotification({ notifications }: MenuNotificationProps) {
  const { CheckNotifications, payLoad } = useAuth();
  const handleCheck = (id: string) => {
    CheckNotifications(id, String(payLoad?.user.id));
  };
  const handleCheckAll = async () => {
    const check = notifications.map((i) => CheckNotifications(i.id, String(payLoad?.user.id)));
    Promise.all([check]);
  };
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        background="xmentor.background_content"
        border="1px solid #D0D5DD"
        boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.08)"
        color="#667085"
        h={12}
        w={12}
        fontSize={28}
        position="relative"
      >
        <Center>
          <FiBell />
        </Center>
        {notifications.length > 0 && (
          <Badge rounded="full" p={1} colorScheme="yellow" fontSize={12} position="absolute" top={1} right={1}>
            {notifications.length}
          </Badge>
        )}
      </MenuButton>
      <MenuList>
        <Text fontStyle="normal" fontWeight={700} lineHeight="20px" p={4}>
          Últimas notificações
        </Text>
        <Divider />
        <VStack p={2} w="300px">
          {notifications.map((notification, index) => (
            <MenuItem key={index} onClick={() => handleCheck(notification.id)}>
              {notification.mensagem}
            </MenuItem>
          ))}
        </VStack>
        {notifications.length > 1 && (
          <>
            <Divider />
            <MenuItem onClick={() => handleCheckAll()}>Li todas</MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
}
