import React, { useCallback, useEffect, useState } from "react";

import {
  Divider,
  Grid,
  GridItem,
  HStack,
  SimpleGrid, Stack, Text,
  VStack,
} from "@chakra-ui/react";
import { CardMentorias } from "../../../components/CardMentorias";
import { CardAtividadesMentorado } from "../../../components/CardAtividadesMentorado";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { useNavigate } from "react-router-dom";
import { CardMentorAgendaAberta } from "../../../components/CardMentorAgendaAberta";
import {
  IAgendamentoClosed, IMentorAgendamento,
  getUserAgendaAPI
} from "../../../helpers/agendaHelper";
import { TemplateMentor } from "../../../components/layout/mentor/TemplateMentor";
import { EventoProvider } from '../../../context/EventoProvider';
import { useEvento } from '../../../context/EventoProvider/useEvento';
import EventoCardMentorado from "../../../components/EventoCardMentorado";
import { formatarHora } from "../../../utils/formatarData";
import { CardMentorado } from "../../../components/CardMentorado";


export function MentorHomeChildren() {

  const [closedAgenda, setClosedAgenda] = useState<any[]>([]);
  const [openAgenda, setOpenAgenda] = useState<any>({});

  const [formatedDate, setFormatedDate] = useState<string[]>([]);
  const { FindActivity, activity, payLoad, SearchUser, users } = useAuth();

  const Auth = useAuth();

  const { ConsultaEvento, eventos } = useEvento()
  const navigate = useNavigate()
  useEffect(() => {
    carregarEventos()
  }, []);

  const carregarEventos = async () => {
    try {
      await ConsultaEvento(payLoad?.user.mentoria_id || " ");
    } catch (error) {
      console.error("Erro ao carregar eventos:", error);
      // Adicione a lógica necessária para lidar com o erro, se necessário.
    }
  };

  useEffect(() => {
    if (payLoad && payLoad.user.id) FindActivity(payLoad?.user.id, "1");
      const RefreshAgenda = async () => {
        const response = await getUserAgendaAPI(Auth.payLoad?.user.id as string, false);
        if (response.msg === "ok") {
          console.log(response.events.marked)
          setOpenAgenda(response.events.free)
          setClosedAgenda(response.events.marked)
          const dates: string[] = []
          response.events.free.forEach( e => { return Object.keys(e.agendamentos).forEach(d => {
            const fDate = d.split('/')
            dates.push(`${fDate[2]}-${fDate[1]}-${fDate[0]}`)
          })})
          setFormatedDate(dates)
        }
      };
    if (payLoad?.user.mentoria_id)
      SearchUser({
        nome: "",
        pagina: 0,
        perfil: "mentorado",
        mentoria_id: payLoad?.user.mentoria_id,
      });
    RefreshAgenda();
  }, [, payLoad]);


  const updateData = ()=>{
    if (payLoad && payLoad.user.id) {
      const idUser = payLoad.user.id
      new Promise(async resolve => { FindActivity(idUser, "1") })
      setTimeout(async () => { FindActivity(idUser, "1") }, 750)
    }
  }
  return (
    <TemplateMentor updateNotification={updateData}>
      <Grid
        templateColumns={{ base: "100%", "2xl": "60% calc(60% - 336px)" }}
        gap={"24px"}
        overflowX={'hidden'}
        h="100%"
      >
        <GridItem colSpan={1} rowSpan={1} overflow={'hiddden'}>
          <VStack align={"start"} my={"15px"} spacing={0} fontWeight={"bold"}>
            <Text fontSize={30}>{payLoad?.user.nome},</Text>
            <Text fontSize={22}>Bem vindo(a)!</Text>
          </VStack>
          <VStack align={"start"} my={"36px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={24} mb={"24px"}>
              Próximas reuniões com mentorados
            </Text>
            <HStack
              overflow={"auto"}
              maxW={"100%"}
              css={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {closedAgenda.length > 0 ? (
                closedAgenda.map((event) => {
                  if (event.mentorado !== 'Aguardando') //GAMBIARRA (COLOCAR PARA FILTRAR DA API)
                  return (
                    <CardMentorias
                      dia={event.data}
                      horario={event.time}
                      isMentorado
                      mentor={event.mentorado}
                      href={event.meet_link}
                    />
                  );
                })
              ) : (
                <Text>Não há reuniões marcadas</Text>
              )}
            </HStack>
          </VStack>
          <VStack align={"start"} my={"36px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={24} mb={"24px"}>
              Próximos eventos
            </Text>
            <EventoProvider>
              <HStack
                overflow={"auto"}
                maxW={"100%"}
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {eventos.length > 0 ?
                  eventos.map((evento, index) => (
                    <EventoCardMentorado
                      key={index}
                      data={evento.data_evento || ""}
                      horario={formatarHora(evento.data_evento) || ""}
                      titulo={evento.nome || ""}
                      descricao={String(evento.duracao || "0") + " min, " + evento.descricao_instrucoes}
                      onClick={()=>{}}
                    />
                  ))
                  : (
                    <Text>Não há eventos próximos</Text>
                  )}
              </HStack>
            </EventoProvider>
          </VStack>
          <VStack align={"start"} my={"40px"} spacing={0} fontWeight={"normal"}>
            <Text fontSize={24} mb={"16px"}>
              Ultimas atividades
            </Text>
            <VStack
              bgColor="xmentor.background_content"
              w={["100%"]}
              borderRadius={10}
              spacing={0}
            >
             {
              activity.map((data, index) => (
                <React.Fragment key={index}>
                  <CardAtividadesMentorado
                    mentor={data.mentor.nome}
                    foto={data.usuario_cliente.foto||""}
                    horario={data.data_cadastro}
                    atividade={data.titulo}
                    vencimento={data.data_fato}
                    feedback={data.file}
                    mentorado={data.usuario_cliente.nome}
                    resposta={data.resposta||data.url}
                    id={data.id}
                  />
                  {index !== activity.length - 1 && (
                    <Divider borderColor="gray.200" />
                  )}
                </React.Fragment>
                  ))
                }
            </VStack>
          </VStack>


        </GridItem>
        <GridItem rowSpan={2} colSpan={1} overflow={'hiddden'}>
          <VStack align={"start"} my={"40px"} spacing={2} fontWeight={"normal"}>
            <Text fontSize={24} mb={"16px"} w={'100%'}>
              Mentorados recentes
            </Text>
            {users &&
              users.data.slice(0,5).map((data) => (
                <CardMentorado
                  image={String(data.foto)}
                  mentorado={String(data.nome)}
                  onClick={() => navigate("/mentor/users-mentorados/view", {
                    state: {
                      id: data.id,
                      nome: data.nome,
                      email: data.email,
                      foto: data.foto,
                      perfil: "Mentorado"
                    }
                  })}
                />
              ))}
          </VStack>

        </GridItem>
      </Grid>

    </TemplateMentor>
  );
}

export function MentorHome() {
  return (
    <EventoProvider>
      <MentorHomeChildren />
    </EventoProvider>
  )
}
