import apiXmentor from "../server/apiServer";

export interface INotificationsData {
  permitirEmail: boolean;
  permitirWhatApp: boolean;
  permtirPromocao: boolean;
}

export interface ISobreMimData {
  metas: string;
  pontosFortes: string;
  pontosDeMelhoria: string;
}

export interface IUpdateProfileData {
  contato: string;
  email: string;
  nome: string;
  sobreMim: ISobreMimData;
}

interface IGetNotificationRes {
  msg: "ok";
  data: INotificationsData;
}

interface ISobreMimRes {
  msg: "ok";
  data: ISobreMimData;
}

interface IDocumentsData {
  id: string;
  titulo: string;
  descricao: string;
  arquivo: string;
  usuario_cliente_id: string;
  usuario_criador_id: string;
  data_cadastro: string;
}


export async function getNotificationOptionsAPI(
  id: string
): Promise<IGetNotificationRes> {
  return (await apiXmentor.get(`/usuario/opcoes-notificacao?id=${id}`)).data;
}

export async function getSobreMimAPI(id: string): Promise<ISobreMimRes> {
  return (await apiXmentor.get(`/usuario/sobre-mim?id=${id}`)).data;
}

export async function updateProfileAPI(
  id: string,
  data: IUpdateProfileData
): Promise<
  | { msg: "Usuário atualizado com sucesso"; data: IUpdateProfileData }
  | { msg: string }
> {
  return (
    await apiXmentor.patch("/usuario", {
      id: id,
      action: "profile",
      data: data,
    })
  ).data;
}

export async function updateNotificationsOptionsAPI(
  id: string,
  data: IUpdateProfileData
): Promise<
  | { msg: "Usuário atualizado com sucesso"; data: ISobreMimData }
  | { msg: string }
> {
  return (
    await apiXmentor.patch("/usuario", {
      id: id,
      action: "notification",
      data: data,
    })
  ).data;
}

export async function getDocumentsAPI(id: string): Promise<IDocumentsData[]> {
  return (await apiXmentor.get(`/documento?clienteId=${id}`)).data;
}
