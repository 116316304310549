import React from "react";
import {
  VStack,
  Text,
  HStack,
  IconButton,
  Avatar,
  Box,
} from "@chakra-ui/react";
import { FaCalendar, FaRegCalendar, FaRegTrashAlt } from "react-icons/fa";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { converterData } from "../../../../utils/formatarData";
import { MdOutlineCalendarToday } from "react-icons/md";

interface Props {
  title: string;
  date: string;
  description: string;
  onClick: () => void;
  usuario_criador: {
    id: string;
    nome: string;
    foto?: string;
  };
}

export const CardAnotacoes = ({
  title,
  date,
  description,
  onClick,
  usuario_criador,
}: Props) => {
  const { payLoad } = useAuth();
  return (
    <VStack
      bgColor={"white"}
      borderWidth="1px"
      borderRadius="lg"
      w="100%"
      p={2}
    >
      <HStack w="100%" justifyContent="space-between">
        <HStack>
          <Avatar
            name={usuario_criador.nome}
            src={`${process.env.REACT_APP_API}/tmp/user/${usuario_criador.foto}`}
            size="sm"
          />
          <Box textAlign={"left"} ml={2}>
            <Text fontWeight="bold">{usuario_criador.nome}</Text>
            {/* <Text fontSize="sm" color="xmentor.text_gray">
              Mentor Educação
            </Text> */}
          </Box>
        </HStack>
        <HStack spacing={0}>
          <IconButton
            icon={<MdOutlineCalendarToday />}
            variant="ghost"
            aria-label="Date"
            color="xmentor.text_gray"
            sx={{
              _hover: {
                background: "none",
                color: "xmentor.text_gray",
              },
            }}
          />
          <Text>{converterData(date)}</Text>
        </HStack>
      </HStack>
      <VStack align={"start"} w={"100%"} spacing={2}>
        <HStack w="100%" justifyContent="space-between">
          <Text fontWeight="bold">{title}</Text>
          {payLoad?.user.perfil !== "mentorado" && (
            <IconButton
              icon={<FaRegTrashAlt />}
              variant="ghost"
              aria-label="Delete"
              color="xmentor.text_gray"
              ml={{ base: 0, md: "auto" }}
              mt={{ base: 3, md: 0 }}
              onClick={(event) => {
                event.stopPropagation(); // Impede a propagação do evento de clique
                onClick();
              }}
            />
          )}
        </HStack>
        <Text>{description}</Text>
      </VStack>
    </VStack>
  );
};
