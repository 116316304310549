import { Avatar, Button, Circle, Box } from "@chakra-ui/react";
import React, { ChangeEvent } from "react";

import { FaCamera } from "react-icons/fa";

interface IAvatarWithButton {
  url?: string;
  setUrlCb?: (e: string) => void;
}

export default function AvatarWithButton(props: IAvatarWithButton) {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      //props.setUrlCb && props.setUrlCb(file as File);
    }
  };

  return (
    <Box position={"relative"}>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleUpload}
      />
      <Avatar size={"2xl"} src={props.url} />
      <Button
        variant={"unstyled"}
        position={"absolute"}
        top={20}
        left={20}
        onClick={handleButtonClick}
      >
        <Circle
          bgColor={"#4963FF"}
          size={"56px"}
          borderColor={"#fff"}
          borderWidth={"4px"}
        >
          <FaCamera size={20} color="#fff" />
        </Circle>
      </Button>
    </Box>
  );
}
