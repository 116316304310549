import React, { FormEvent, useEffect, useState } from "react";
import {
  Text,
  Button,
  ModalFooter,
  Stack,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Select,
  ModalOverlay,
  Divider,
  VStack,
} from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { CustomModalOverlay } from "../../trilha/components/CustomModalOverlay";
import { IModalAddAcoes } from "../view";
import { MentoriaProvider } from "../../../../context/MentoriaProvider";
import { useMentoria } from "../../../../context/MentoriaProvider/useMentoria";

function ModalAddAcoesChildren(props: IModalAddAcoes) {
  const { CriarAcao, userSelected, payLoad } = useAuth();
  const [newActionTitle, setNewActionTitle] = useState<string>("");
  const [newActionDate, setNewActionDate] = useState<string>("");
  const [mentorSelected, setMentorSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [etapaSelected, setEtapaSelected] = useState("");
  const [file, setFile] = useState(false);
  const { ConsultaEtapaMentoria, etapasMentoria } = useMentoria();

  useEffect(() => get(), [props.isOpen]);

  const get = () => {
    if (props.mentoria_id) ConsultaEtapaMentoria(props.mentoria_id);
  };

  const handleAdd = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (mentorSelected.trim() && payLoad && payLoad.user) {
      setIsLoading(true)
      await CriarAcao(
        newActionTitle,
        newActionDate,
        file,
        etapaSelected,
        mentorSelected,
        props.usuario_cliente_id,
        String(payLoad.user.id)
      );
      setNewActionDate("");
      setFile(false)
      setMentorSelected("")
      setEtapaSelected("")
      setNewActionTitle("");
      props.onClose();
      setIsLoading(false)
    }
  };

  const [feedbackType, setFeedbackType] = useState<string>("");

  useEffect(() => {
    if (feedbackType === "1") {
      setFile(true);
    } else {
      setFile(false);
    }
  }, [feedbackType]);

  const today = new Date().toISOString().split('T')[0];

  return (
    <Modal size={'xl'} isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleAdd}>
          <ModalHeader>Nova atividade</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody>
            <Text mt='3' mb={2}>Nome da atividade</Text>
            <Input
              required
              mb={5}
              bgColor={'xmentor.bgInputText'}
              borderColor={'xmentor.bgColorBorder'}
              placeholder=""
              value={newActionTitle}
              onChange={(e) => setNewActionTitle(e.target.value)}
            />

            <Stack w={'100%'} direction={['column', 'row']} spacing='24px'>
              <VStack align={'start'} w={'100%'}>
                <Text>Etapa</Text>
                <Select
                  required
                  bgColor={'xmentor.bgInputText'}
                  borderColor={'xmentor.bgColorBorder'}
                  placeholder="Selecione a etapa"
                  mb={5}
                  value={etapaSelected}
                  onChange={(e) => setEtapaSelected(e.target.value)}
                >
                  {etapasMentoria
                    ?.filter((i) => i.titulo !== "MENTORADOS" && i.titulo !== "Início")
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.titulo}
                      </option>
                    ))}
                </Select>
              </VStack>

              <VStack align={'start'} w={'100%'}>
                <Text>Data de Vencimento</Text>
                <Input
                  required
                  bgColor={'xmentor.bgInputText'}
                  borderColor={'xmentor.bgColorBorder'}
                  type="date"
                  placeholder="Data de Vencimento"
                  min={today}
                  value={newActionDate}
                  onChange={(e) => setNewActionDate(e.target.value)}
                />
              </VStack>
            </Stack>
            <Stack w={'100%'} direction={['column', 'row']} spacing='24px'>
              <VStack align={'start'} w={'100%'}>
                <Text>Mentor</Text>
                <Select
                  required
                  placeholder="Selecione o mentor"
                  mb={5}
                  bgColor={'xmentor.bgInputText'}
                  borderColor={'xmentor.bgColorBorder'}
                  value={mentorSelected}
                  onChange={(e) => setMentorSelected(e.target.value)}
                >
                  {userSelected?.mentores.map((item) => (
                    <option key={item.mentor.id} value={item.mentor.id}>
                      {item.mentor.nome}
                    </option>
                  ))}
                </Select>
              </VStack>
              <VStack align={'start'} w={'100%'}>
                <Text>Feedback do Mentorado</Text>
                <Select
                  required
                  bgColor={'xmentor.bgInputText'}
                  borderColor={'xmentor.bgColorBorder'}
                  placeholder="Selecione"
                  value={feedbackType}
                  onChange={(e) => setFeedbackType(e.target.value)}
                >
                  <option value="0">Texto de resposta</option>
                  <option value="1">Envio de arquivo</option>
                </Select>
              </VStack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={props.onClose}>
              Cancelar
            </Button>
            <Button colorScheme="blue" bgColor={'xmentor.primary'} type="submit" isLoading={isLoading}>
              Adicionar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export function ModalAddAcoes(props: IModalAddAcoes) {
  return (
    <MentoriaProvider>
      <ModalAddAcoesChildren
        isOpen={props.isOpen}
        mentoria_id={props.mentoria_id}
        usuario_cliente_id={props.usuario_cliente_id}
        onClose={props.onClose}
      />
    </MentoriaProvider>
  );
}
