import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Box,
  Text,
  CircularProgress,
  CircularProgressLabel,
  useToast,
  IconButton,
  Icon,
  Input,
  VStack,
  Flex,
  Center,
} from "@chakra-ui/react";
import { BsTrash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FaCloudUploadAlt, FaRegTrashAlt } from "react-icons/fa";
import { useMentoria } from "../../context/MentoriaProvider/useMentoria";
import { MdCloudUpload } from "react-icons/md";

interface Props {
  id: string;
  sendRequest: boolean;
  status: (i: boolean) => void;
  maxLenght: number;
  clear: () => void;
}

export interface DataProps {
  legenda: string;
  file: File;
}

export function FileUpload({
  id,
  maxLenght,
  sendRequest,
  status,
  clear,
}: Props) {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const toast = useToast();
  const { UploadFileEtapaMentoria } = useMentoria();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && selectedFiles.length + maxLenght <= 19) {
      const validFiles = Array.from(files).filter((file) => {
        const fileType = file.type;
        const fileName = file.name;
        const allowedTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];
        const isValidType =
          allowedTypes.includes(fileType) && fileName.length <= 55;
        return isValidType;
      });

      if (validFiles.length === files.length) {
        setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
      } else {
        toast({
          position: "top",
          description:
            "Tipo de arquivo inválido. São permitidos apenas arquivos pdf, doc e xlsx com no máximo 55 caracteres no nome.",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
      }
    } else {
      toast({
        position: "top",
        description: "O limite é de 20 anexos!",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleRemoveFile = useCallback(
    (index: number) => {
      setSelectedFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles.splice(index, 1);
        return updatedFiles;
      });
    },
    [setSelectedFiles]
  );

  async function handleUpload() {
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (selectedFiles.length > 0 && id !== "") {
      try {
        setUploading(true);
        const totalProgressPerFile = 100 / selectedFiles.length;

        for (let i = 0; i < selectedFiles.length; i++) {
          const formData = new FormData(); // Criar novo FormData para cada arquivo
          // formData.append("authorId", idUser);
          formData.append(`etapa_id`, id);
          const file = selectedFiles[i];
          await new Promise((resolve) => setTimeout(resolve, 500));
          setUploadProgress((i + 1) * totalProgressPerFile);
          if (file) {
            formData.append("file", file);
          }
          await uploadPost(formData);
        }

        setSelectedFiles([]);
        toast({
          position: "top",
          description: "Upload de arquivos com sucesso",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        clear();
        limpaLocalStorage();
      } catch (error) {
        toast({
          position: "top",
          description: "Erro ao cadastrar",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        console.error("Erro no upload:", error);
      } finally {
        setUploading(false);
      }
    } else {
      limpaLocalStorage();
    }
  }
  function limpaLocalStorage() {
    setSelectedFiles([]);
  }
  async function uploadPost(data: FormData) {
    await UploadFileEtapaMentoria(data);
  }
  useEffect(() => {
    if (sendRequest) handleUpload();
  }, [sendRequest]);

  useEffect(() => {
    status(selectedFiles.length > 0);
  }, [selectedFiles]);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragOver = () => {
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };
  const handleDrop = () => {
    setIsDraggingOver(false);
  };

  return (
    <>
      {!uploading ? (
        <>
          <Flex
            align="center"
            justify="center"
            bg={isDraggingOver ? "gray.100" : "xmentor.background_template"}
            border="1px dashed"
            borderColor={isDraggingOver ? "gray.500" : "gray.300"}
            rounded={3}
            w="100%"
            h="max-content"
            cursor="pointer"
            p={5}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            position="relative"
          >
            <Input
              required
              type="file"
              multiple
              accept=".pdf,.doc,.xlsx"
              onChange={handleFileChange}
              position="absolute"
              cursor="pointer"
              opacity={0}
              h="100%"
            // bg="red"
            />
            <Center flexDirection="column">
              <Icon
                as={MdCloudUpload}
                w="40px"
                h="40px"
                color={"xmentor.primary"}
              />
              <Flex
                justify="center"
                textAlign={"center"}
                maxW="170px"
                mx="auto"
                mb="12px"
                mt={3}
              >
                <VStack spacing={1}>
                  <Text fontSize="xs" fontWeight="700" color="black">
                    Clique para adicionar ou arraste para adicionar
                  </Text>
                  <Text fontSize="xs" color="black">
                    PDF DOC e XLSX
                  </Text>
                </VStack>
              </Flex>
            </Center>
          </Flex>
        </>
      ) : (
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="center"
          position="fixed"
          top={0}
          bottom={0}
          left={0}
          right={0}
          zIndex={51}
          bg="rgba(0, 0, 0, 0.7)"
        >
          <Box mt="4" textAlign="center" bg="white" rounded={10}>
            <CircularProgress value={uploadProgress} color="green" size="120px">
              <CircularProgressLabel>
                {uploadProgress.toFixed(0)}%
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
        </Box>
      )}
      {selectedFiles.length > 0 ? (
        <>
          <VStack w="100%" spacing="10px">
            {selectedFiles.map((file, index) => {
              return (
                <Flex key={index} alignItems="center" justifyContent="space-between" width="100%">
                  <Text>{file.name}</Text>
                  <IconButton
                    icon={<FaRegTrashAlt />}
                    aria-label="Remove anexo"
                    onClick={() => handleRemoveFile(index)}
                  />
                </Flex>
              );
            })}
          </VStack>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
