import React, { useEffect, useState } from "react";
import {
  Image,
  Text,
  Flex,
  Icon,
  useToast,
  Input,
  Center,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { IUploadBackground } from "../view";
import { MdModeEditOutline } from "react-icons/md";

export function UploadBackground({
  usuario_cliente_id,
  urlImage,
}: IUploadBackground) {
  const [file, setFile] = useState<File | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const { UploadBackground, payLoad } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files ? event.target.files[0] : null);
  };

  const handleRemove = () => {
    setFile(null);
  };

  async function handleUpload() {
    const formDataWithImage = new FormData();

    if (file && payLoad) {
      setIsLoading(true);
      formDataWithImage.append("file", file);
      await UploadBackground(usuario_cliente_id, formDataWithImage);
      handleRemove();
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!file) {
      return;
    }

    const maxSize = 15 * 1024 * 1024; // Define o tamanho máximo permitido (15 MB)
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];

    if (!allowedFileTypes.includes(file.type)) {
      toast({
        position: "top",
        description:
          "Tipo de arquivo inválido. São permitidos apenas arquivos JPEG, PNG e GIF.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (file.size > maxSize) {
      toast({
        position: "top",
        description: "Envie um arquivo menor que 15MB",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      handleUpload();
    }
  }, [file]);

  return (
    <Flex
      align="center"
      justify="center"
      w="100%"
      h="max-content"
      cursor="pointer"
      borderTopRadius={'lg'}
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      backgroundColor={!urlImage ? "blue.500" : "transparent"}
    >
      {urlImage ? (
        <Image
          src={`${process.env.REACT_APP_API}/tmp/user/${urlImage}`}
          alt=""
          maxH={230}
          w="100%"
          objectFit="cover"
          borderTopRadius={'lg'}
          opacity={isHovered ? 0.7 : 1}
        />
      ) : (
        <Center
          flexDirection="column"
          w="100%"
          h={230}
          rounded={10}
          opacity={isHovered ? 0.7 : 1}
          color="white"
        >
          <Text fontSize="lg">Nenhuma foto carregada</Text>
        </Center>
      )}

      {/* Adicionando um alto índice z para o Input */}
      <Input
        required
        type="file"
        accept={".jpg,.jpeg,.webp,.png"}
        onChange={handleChange}
        position="absolute"
        cursor="pointer"
        opacity={0}
        h="100%"
        w="100%"
        style={{ zIndex: 2 }} // Define um alto índice z para o Input
      />
      <Flex
        flexDirection="column"
        right={12}
        position="absolute"
        opacity={isHovered ? 1 : 0}
        style={{ zIndex: 1 }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <IconButton
              colorScheme="primary"
              bgColor={"xmentor.primary"}
              color={"white"}
              fontSize="20px"
              onClick={() => {}}
              icon={<MdModeEditOutline />}
              aria-label="Add"
              ml="auto"
            />
            {/* <Icon as={FaCloudUploadAlt} w="80px" h="80px" color={"gray.300"} />
            <Flex color={"gray.300"} justify="center" mx="auto" mb="12px">
              <Text fontSize="xs" fontWeight="700">
                Clique para adicionar ou arraste
              </Text>
            </Flex>
            <Text color={"gray.300"} fontSize="xs">
              PDF, jpg, jpeg e png (max. 800x400px)
            </Text> */}
          </>
        )}
      </Flex>
    </Flex>
  );
}
