import React, { useState } from "react";
import {
  Flex,
  VStack,
  Image,
  Text,
  Stack,
  IconButton,
  Box,
  useToast,
} from "@chakra-ui/react";
import { FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import ImageEditModal from "../../../../components/avatarUpload/ModalEditImage";

type DataMentoredSectionProps = {
  handleEditDataClick: () => void;
};

export const DataMentoredSection = ({
  handleEditDataClick,
}: DataMentoredSectionProps) => {
  const { userSelected, payLoad, UploadAvatar } = useAuth();
  const [isHovered, setIsHovered] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const toast = useToast();

  const openPage = (platform: string, username: string) => {
    let url;
    switch (platform) {
      case "instagram":
        url = `https://www.instagram.com/${username}`;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/in/${username}`;
        break;
      case "youtube":
        url = `https://www.youtube.com/${username}`;
        break;
      default:
        return;
    }
    window.open(url, "_blank");
  };

  const handleImageClick = () => {
    setImagePreview(
      `${process.env.REACT_APP_API}/tmp/user/${userSelected?.foto}`
    );
    setIsEditOpen(true);
  };

  const handleSave = async (file: File) => {
    const formDataWithImage = new FormData();
    formDataWithImage.append("usuario_cliente_id", userSelected?.id || "");
    formDataWithImage.append("usuario_criador_id", String(payLoad?.user.id));
    formDataWithImage.append("file", file);
    try {
      await UploadAvatar(userSelected?.id || "", formDataWithImage);
      toast({
        title: "Avatar atualizado com sucesso.",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erro ao atualizar avatar.",
        status: "error",
        position: "top",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsEditOpen(false);
    }
  };

  const isAuxiliar = payLoad?.user.perfil === "auxiliar";
  const isMentorAndAdmin =
    payLoad?.user.perfil === "mentor" && payLoad?.user.isAdmin;

  return (
    <VStack w="100%">
      <Flex
        w="full"
        align={"center"}
        p={"20px"}
        bg="white"
        h="full"
        borderWidth="1px"
        borderRadius="md"
      >
        <Box
          position="relative"
          alignSelf={"start"}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Image
            w={"140px"}
            h={"140px"}
            alignSelf={"start"}
            rounded={"md"}
            src={`${process.env.REACT_APP_API}/tmp/user/${userSelected?.foto}`}
            mr={4}
            cursor="pointer"
            onClick={handleImageClick}
          />
          {isHovered && (
            <Box
              position="absolute"
              top={0}
              left={0}
              w="140px"
              h="140px"
              bg="rgba(0, 0, 0, 0.3)"
              cursor={"pointer"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="md"
              onClick={handleImageClick}
            >
              <MdModeEditOutline color="white" size="24px" />
            </Box>
          )}
        </Box>
        <VStack
          h="full"
          spacing={0}
          py={"10px"}
          alignSelf={"start"}
          alignItems="start"
        >
          <VStack
            alignSelf={"start"}
            spacing={0}
            ml={1}
            justifyContent="start"
            alignItems="start"
          >
            <Text fontSize="lg" fontWeight="bold">
              {userSelected?.nome}
            </Text>
            <Text mb="7" color="xmentor.text_gray">
              {userSelected?.ocupacao}
            </Text>
            <Stack direction="row" justifySelf={"start"} spacing={2} mt="auto">
              {userSelected?.redes_sociais?.instagram && (
                <IconButton
                  bg="white"
                  alignSelf={"start"}
                  border="1px"
                  borderColor={"xmentor.borderCards"}
                  color={"xmentor.text_gray"}
                  icon={<FaInstagram />}
                  fontSize="lg"
                  aria-label="Instagram"
                  onClick={() =>
                    openPage("instagram", userSelected.redes_sociais.instagram)
                  }
                />
              )}
              {userSelected?.redes_sociais?.linkedin && (
                <IconButton
                  bg="white"
                  border="1px"
                  borderColor={"xmentor.borderCards"}
                  color={"xmentor.text_gray"}
                  icon={<FaLinkedin />}
                  fontSize="lg"
                  aria-label="LinkedIn"
                  onClick={() =>
                    openPage("linkedin", userSelected.redes_sociais.linkedin)
                  }
                />
              )}
              {userSelected?.redes_sociais?.youtube && (
                <IconButton
                  bg="white"
                  border="1px"
                  borderColor={"xmentor.borderCards"}
                  color={"xmentor.text_gray"}
                  icon={<FaYoutube />}
                  fontSize="lg"
                  aria-label="YouTube"
                  onClick={() =>
                    openPage("youtube", userSelected.redes_sociais.youtube)
                  }
                />
              )}
            </Stack>
          </VStack>
        </VStack>
        <IconButton
          colorScheme="primary"
          bgColor={"xmentor.primary"}
          color={"white"}
          icon={<MdModeEditOutline />}
          fontSize="20px"
          aria-label="Edit"
          ml="auto"
          mb="auto"
          onClick={handleEditDataClick}
          hidden={!isAuxiliar && !isMentorAndAdmin}
        />
      </Flex>
      <ImageEditModal
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        imagePreview={null}
        handleSave={handleSave}
      />
    </VStack>
  );
};
