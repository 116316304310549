import { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  Text,
  Button,
  Heading,
  IconButton,
  useDisclosure,
  Flex,
  SimpleGrid,
  Badge,

} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { ModalUploadAnexo } from "./components/ModalUploadAnexo";
import { ModalAddMentor } from "./components/ModalAddMentor";
import { ModalAddNotes } from "./components/ModalAddNotes";
import { ModalAddAcoes } from "./components/ModalAddAcoes";
import { CardAcoes } from "./components/CardAcoes";
import { ModalAddRedesSociais } from "./components/ModalAddRedesSociais";
import { UploadBackground } from "./components/UploadBackground";
import { ModalDeleteAnotacoes } from "./components/ModalDeleteAnotacoes";

import "cropperjs/dist/cropper.css";
import { Timeline } from "../../mentorado/trilha";
import { useMentoria } from "../../../context/MentoriaProvider/useMentoria";
import { ModalUpdateDataUser } from "./components/MoldalUpdateDataUser";
import { ModalSobre } from "./components/ModalSobre";
import { DataMentoredSection } from "./section/DataMentoredSection";
import AboutSection from "./section/AboutSection";
import InformationSection from "./section/InformationSession";
import MentorSection from "./section/MentorsSection";
import AttachmentsSection from "./section/AttachmentsSection";
import NotesSection from "./section/NotesSection";
import { IEtapaMentoria } from "../../../context/MentoriaProvider/types";
import { ModalAddTags } from "./components/ModalAddTags";
import { FaRegTrashAlt } from "react-icons/fa";
import { ModalDeleteTag } from "./components/ModalDeleteTag";

export interface ResultRouteProps {
  id: string;
  nome: string;
  email: string;
  foto: string;
  perfil: string;
}

export function ViewUser() {
  const { FindUserById, userSelected, UpdateDescriptionProfile, DeleteTag } = useAuth();

  const [isEditingData, setIsEditingData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [description, setDescription] = useState(userSelected?.descricao || "");
  const [isModalSobreOpen, setIsModalSobreOpen] = useState(false);

  const [isModalAddMentor, setIsModalAddMentor] = useState(false);
  const [isModalDeletarAnotacoes, setIsModalDeletarAnotacoes] = useState(false);
  const [isModalDeleteTag, setIsModalDeleteTag] = useState(false);
  const [idAnotacao, setIdAnotacao] = useState("");

  const [idTag, setIdTag] = useState("");
  const [isModalAddRedesSociais, setIsModalAddRedesSociais] = useState(false);
  const navigate = useNavigate();
  const [isOpenAnotacao, setIsOpenAnotacao] = useState(false);
  const [isOpenTag, setIsOpenTag] = useState(false);
  const [isOpenAcao, setIsOpenAcao] = useState(false);
  const location = useLocation();
  const resultRoute: ResultRouteProps = location.state || {
    email: "",
    nome: "",
    foto: "",
    id: "",
  };
  const { ListarTrilhaUsuario, trilha } = useMentoria();
  const [filteredEvents, setFilteredEvents] = useState<IEtapaMentoria[]>([]);

  useEffect(() => {
    Promise.all([
      FindUserById(resultRoute.id),
      ListarTrilhaUsuario(resultRoute.id),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (trilha && trilha.etapas) {
      const filtered = trilha.etapas.filter(
        (event) => event.titulo !== "Início"
      );
      setFilteredEvents(filtered);
    }
  }, [trilha]);

  /*async function copiar(texto: string) {
    if (texto) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard
          .writeText(texto)
          .then(() => {
            toast({
              position: "top",
              description: "Copiado para área de transferência",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          })
          .catch(() => {
            toast({
              position: "top",
              description: "Erro ao copiar para área de transferência",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          });
      } else {
        toast({
          position: "top",
          description: "Seu navegador não suporta esse recurso!",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  }*/

  // Função para adicionar uma nova anotação
  const handleAddAnotacao = () => {
    setIsOpenAnotacao(true);
  };

  const handleCloseModal = () => {
    setIsModalSobreOpen(false);
  };

  useEffect(
    () => setDescription(userSelected?.descricao || ""),
    [userSelected]
  );
  const handleAddAcao = () => {
    setIsOpenAcao(true);
  };

  const handleOpenSobre = () => {
    setIsModalSobreOpen(!isModalSobreOpen);
  };

  const handleSaveNewText = (text: string) => {
    UpdateDescriptionProfile(userSelected?.id || "", text);
    setDescription(text);
  };

  const handleEditDataClick = () => {
    setIsEditingData(!isEditingData);
  };

  return (
    <>
      <VStack
        p={0}
        alignItems="start"
        gap="24px"
        alignSelf="stretch"
        flexWrap="wrap"
        borderRadius="8px"
        maxW="1440px"
        mx="auto"
      >
        {resultRoute && (
          <>
            <HStack display="flex" w="100%" justifyContent="space-between">
              <Heading size="md">{resultRoute.perfil}</Heading>
              <Button
                px={0}
                variant={"ghost"}
                leftIcon={<IoIosArrowRoundBack size={30} />}
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </HStack>

            <VStack gap={[2, 5]} w="100%">
              <SimpleGrid columns={[1, 2]} spacing={[2, 5]} w="100%">
                {/* Mentor Section */}
                <DataMentoredSection
                  handleEditDataClick={handleEditDataClick}
                />

                {/* About Section */}
                <AboutSection
                  description={description}
                  handleOpenSobre={handleOpenSobre}
                />
              </SimpleGrid>
              {resultRoute.perfil === "Mentor" && (
                <UploadBackground
                  urlImage={userSelected ? userSelected.imagem_background : ""}
                  usuario_cliente_id={resultRoute.id}
                />
              )}

              <Flex direction={["column", "row"]} gap={[2, 5]} w="100%">
                {/* Information Section */}
                <InformationSection />

                {/* Mentors Section */}
                <MentorSection
                  setIsModalAddMentor={setIsModalAddMentor}
                  resultRoute={resultRoute}
                  usuario_cliente_id={resultRoute.id}
                />
              </Flex>
              {/* tags */}
              <VStack
                align={"flex-start"}
                p={[2, 5]}
                bg="white"
                w="100%"
                borderWidth="1px"
                borderRadius="lg"
                hidden={
                  resultRoute.perfil === "Auxiliar" ||
                  resultRoute.perfil === "Mentor"
                }
              >
                <HStack w="100%" justifyContent="space-between" align={"center"}>
                  <Text fontSize="xl" fontWeight="bold" >
                    Tags
                  </Text>
                  <HStack ml={3}>
                    {userSelected?.tags?.map(i => (
                      <Button
                        ml={{ base: 0, md: "auto" }}
                        mt={{ base: 3, md: 0 }}
                        bg="xmentor.primary"
                        colorScheme="primary"
                        size="sm"
                        onClick={() => {
                          setIdTag(i.id)
                          setIsModalDeleteTag(true)
                        }}
                      >
                        <Text mr={3}>{i.descricao}</Text>
                        <FaRegTrashAlt />
                      </Button>))}
                  </HStack>
                  <IconButton
                    colorScheme="primary"
                    bgColor={"xmentor.primary"}
                    color={"white"}
                    icon={<FaPlus />}
                    aria-label="Edit"
                    ml={{ base: 0, md: "auto" }}
                    mt={{ base: 4, md: 0 }}
                    onClick={() => setIsOpenTag(true)}
                  />
                </HStack>
                
              </VStack>
              {/* jornada */}
              <VStack
                align={"flex-start"}
                p={[2, 5]}
                bg="white"
                w="100%"
                borderWidth="1px"
                borderRadius="lg"
                hidden={
                  resultRoute.perfil === "Auxiliar" ||
                  resultRoute.perfil === "Mentor"
                }
              >
                <Text fontSize="xl" fontWeight="bold" mb={3}>
                  Jornada
                </Text>
                <Timeline
                  events={filteredEvents}
                  currentState={trilha?.posicao || 0}
                />

                <HStack w={"100%"} justifyContent={`space-between`}>
                  <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Atividades
                  </Text>

                  <IconButton
                    colorScheme="primary"
                    bgColor={"xmentor.primary"}
                    color={"white"}
                    icon={<FaPlus />}
                    aria-label="Edit"
                    ml={{ base: 0, md: "auto" }}
                    mt={{ base: 4, md: 0 }}
                    onClick={handleAddAcao}
                  />
                </HStack>

                <VStack
                  w="100%"
                  hidden={
                    resultRoute.perfil === "Administrador" ||
                    resultRoute.perfil === "Mentor"
                  }
                >
                  {userSelected && userSelected.acoes_cliente && (
                    <CardAcoes actions={userSelected.acoes_cliente} />
                  )}
                </VStack>
              </VStack>

              <Flex
                direction={["column", "row"]}
                gap={[2, 5]}
                w="100%"
                hidden={
                  resultRoute.perfil === "Auxiliar" ||
                  resultRoute.perfil === "Mentor"
                }
              >
                {/* anexos */}
                <AttachmentsSection onOpen={onOpen} resultRoute={resultRoute} />

                {/* anotacoes */}
                <NotesSection
                  handleAddAnotacao={handleAddAnotacao}
                  setIdAnotacao={setIdAnotacao}
                  setIsModalDeletarAnotacoes={setIsModalDeletarAnotacoes}
                />
              </Flex>
            </VStack>
          </>
        )}
      </VStack>
      <ModalAddNotes
        isOpen={isOpenAnotacao}
        onClose={() => setIsOpenAnotacao(false)}
        usuario_cliente_id={resultRoute.id}
      />
      <ModalAddTags
        isOpen={isOpenTag}
        onClose={() => setIsOpenTag(false)}
        usuario_cliente_id={resultRoute.id}
      />

      <ModalSobre
        isOpen={isModalSobreOpen}
        onClose={handleCloseModal}
        text={description}
        onSave={handleSaveNewText}
      />

      <ModalAddAcoes
        isOpen={isOpenAcao}
        onClose={() => setIsOpenAcao(false)}
        usuario_cliente_id={resultRoute.id}
        mentoria_id={userSelected?.mentoria_id || ""}
      />
      <ModalUploadAnexo
        isOpen={isOpen}
        onClose={onClose}
        usuario_cliente_id={resultRoute.id}
      />

      <ModalAddMentor
        isOpen={isModalAddMentor}
        onClose={() => setIsModalAddMentor(false)}
        usuario_cliente_id={resultRoute.id}
        mentoria_id={userSelected?.mentoria_id || ""}
      />
      <ModalDeleteAnotacoes
        isOpen={isModalDeletarAnotacoes}
        onClose={() => setIsModalDeletarAnotacoes(false)}
        usuario_cliente_id={resultRoute.id}
        id={idAnotacao}
      />
      <ModalDeleteTag
        isOpen={isModalDeleteTag}
        onClose={() => setIsModalDeleteTag(false)}
        usuario_cliente_id={resultRoute.id}
        id={idTag}
      />

      {userSelected && (
        <>
          <ModalUpdateDataUser
            isOpen={isEditingData}
            onClose={() => setIsEditingData(false)}
            usuario_cliente_id={resultRoute.id}
          />
          <ModalAddRedesSociais
            isOpen={isModalAddRedesSociais}
            onClose={() => setIsModalAddRedesSociais(false)}
            usuario_cliente_id={resultRoute.id}
          />
        </>
      )}
    </>
  );
}

export interface IModalUpload {
  isOpen: boolean;
  onClose: () => void;
  usuario_cliente_id: string;
}

export interface IModalAddMentor {
  isOpen: boolean;
  onClose: () => void;
  usuario_cliente_id: string;
  mentoria_id: string;
}

export interface IMentores {
  id: string;
  name: String;
  foto: string;
}

export interface IModalAddNotes {
  isOpen: boolean;
  onClose: () => void;
  usuario_cliente_id: string;
}
export interface IModalAddAcoes {
  isOpen: boolean;
  onClose: () => void;
  mentoria_id: string;
  usuario_cliente_id: string;
}

export interface IUploadBackground {
  usuario_cliente_id: string;
  urlImage?: string;
}

export interface IModalDeleteAnotacoes extends IModalAddNotes {
  id: string;
}
