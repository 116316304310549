import React from "react";
import { Box, Text, Flex, useTheme } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const data = {
//   labels: ['SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB'],
//   datasets: [
//     {
//       label: 'Mentorados',
//       data: [7, 20, 12, 22, 18, 30],
//       // borderColor: '#3b82f6',
//       borderColor: primaryColor,
//       // backgroundColor: 'rgba(59, 130, 246, 0.5)',
//       backgroundColor: primaryColorRGBA,
//       tension: 0.4,
//       borderWidth: 2,
//       fill: true,
//       pointRadius: 4,
//       pointHoverRadius: 6,
//       pointBackgroundColor: '#3b82f6',
//       pointBorderColor: '#fff',
//     },
//   ],
// };

// const options = {
//   responsive: true,
//   maintainAspectRatio: false,
//   scales: {
//     x: {
//       display: true,
//       grid: {
//         display: false,
//       },
//       ticks: {
//         color: '#A0AEC0', // gray.500
//       },
//     },
//     y: {
//       display: true,
//       grid: {
//         display: true,
//         color: 'rgba(112, 144, 176, 0.2)', // Customize grid line color
//       },
//       ticks: {
//         color: '#A0AEC0', // gray.500
//         beginAtZero: true, // Start the y-axis at zero
//       },
//     },
//   },
//   plugins: {
//     legend: {
//       display: false,
//     },
//     tooltip: {
//       enabled: true,
//       callbacks: {
//         label: function(context: { raw: any; }) {
//           return `Mentorados: ${context.raw}`;
//         }
//       }
//     },
//   },
//   elements: {
//     line: {
//       // borderColor: '#3b82f6',
//       // backgroundColor: 'rgba(59, 130, 246, 0.5)',
//       borderColor: primaryColor,
//       backgroundColor: primaryColorRGBA,
//       borderWidth: 2,
//       fill: 'start',
//     },
//     point: {
//       radius: 4,
//       hoverRadius: 6,
//       // backgroundColor: '#3b82f6',
//       backgroundColor: primaryColor,
//       borderColor: '#fff',
//     },
//   },
// };

interface MentoradoPorMes {
  mes: string;
  totalUsuarios: number;
}

interface DadosMentoria {
  mentorados: number;
  mentores: number;
  reunioes: number;
  atividades: number;
  eventos: number;
  mentoradosPorMes: MentoradoPorMes[];
  proximosEventos: any[];
}

interface MentoradosChartProps {
  dadosMentoria: DadosMentoria | null;
}
function formatarMesAno(data: string): string {
  const meses = ["Jan", "Fev", "Mar", "Abr", "Mar", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
  
  const [ano, mes] = data.split("-");
  const mesIndex = parseInt(mes) - 1; // Converter mês para índice de array (0-11)

  return `${meses[mesIndex]}/${ano}`;
}
const MentoradosChart: React.FC<MentoradosChartProps> = ({ dadosMentoria }) => {
  const theme = useTheme();
  const primaryColor = theme.colors.xmentor.primary;
  const primaryColorRGBA = `rgba(${parseInt(primaryColor.slice(1, 3), 16)}, ${parseInt(primaryColor.slice(3, 5), 16)}, ${parseInt(primaryColor.slice(5, 7), 16)}, 0.5)`;
  
  const labels = dadosMentoria?.mentoradosPorMes.map(item => formatarMesAno(item.mes)) ;
  const dataValues = dadosMentoria?.mentoradosPorMes.map(item => item.totalUsuarios);

  const data = {
    labels,
    datasets: [
      {
        label: 'Mentorados',
        data: dataValues,
        // borderColor: '#3b82f6',
        // backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: primaryColor,
        backgroundColor: primaryColorRGBA,
        tension: 0.4,
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        pointHoverRadius: 6,
        // pointBackgroundColor: '#3b82f6',
        pointBackgroundColor: primaryColor,
        pointBorderColor: '#fff',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#A0AEC0', // gray.500
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
          color: 'rgba(112, 144, 176, 0.2)', // Customize grid line color
        },
        ticks: {
          color: '#A0AEC0', // gray.500
          beginAtZero: true, // Start the y-axis at zero
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function(context: { raw: any; }) {
            return `Mentorados: ${context.raw}`;
          }
        }
      },
    },
    elements: {
      line: {
        // borderColor: '#3b82f6',
        // backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: primaryColor,
        backgroundColor: primaryColorRGBA,
        borderWidth: 2,
        fill: 'start',
      },
      point: {
        radius: 4,
        hoverRadius: 6,
        // backgroundColor: '#3b82f6',
        backgroundColor: primaryColor,
        borderColor: '#fff',
      },
    },
  };

  return (
    <Box
      bg="white"
      borderRadius="24px"
      p={6}
      boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      w={{base:"100%",md:"100%",lg:"70%"}}
      h="400px"
    >
      <Flex justifyContent="space-between" mb={4}>
        <Text fontSize="xl" fontWeight="bold">Mentorados</Text>
      </Flex>
      <Box h="300px">
        <Line data={data} options={options} />
      </Box>
    </Box>
  );
};

export default MentoradosChart;
