import {
  Heading,
  Stack,
  VStack,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import "./style.css";
import DashboardCards from "./components/DashboardCards";
import MentoradosChart from "./components/MentoradosChart";
import UpcomingEvents from "./components/UpcomingEvents";
import MentoradosSection from "./components/MentoradosSection";
import MentoresSection from "./components/MentoresSection";
import apiXmentor from "../../../server/apiServer";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { useEffect, useState } from "react";
import { FaUsers, FaUserTie, FaCalendarCheck, FaTasks } from "react-icons/fa";
import { MdEvent } from "react-icons/md";
import { IAtualizaEvento } from "../../../context/EventoProvider/types";

interface MentoradoPorMes {
  mes: string;
  totalUsuarios: number;
}

interface DadosMentoria {
  mentorados: number;
  mentores: number;
  reunioes: number;
  atividades: number;
  eventos: number;
  mentoradosPorMes: MentoradoPorMes[];
  proximosEventos: IAtualizaEvento[];
  mentoradosList: {
    id: string
    foto: string
    nome: string
    ocupacao: string
    quantidadeAcoesCliente: number
    quantidadeHistoricoMentoria: number
  }[]  
  mentoresList: {
    id: string
    foto: string
    nome: string
  }[]
}

export function Dashboard() {
  const { payLoad } = useAuth();
  const [dataDash, setDataDash] = useState<DadosMentoria | null>(null);

  useEffect(() => {
    if (payLoad && payLoad.user.mentoria_id) {
      getDashboard(payLoad.user.mentoria_id);
    }
  }, [payLoad]);

  async function getDashboard(mentoria_id: string) {
    try {
      const request = await apiXmentor.get(`/dashboard/${mentoria_id}`);
      setDataDash(request.data);
      return request.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return (
    <TemplateAdmin>
      <VStack
        align="start"
        mt={4}
        minH={"87vh"}
        maxW={{ base: "100vw", md: '100vw', lg: "87vw" }}
        overflowX={'hidden'}
        overflowY="auto"
      >
        <Heading size="md">Visão geral</Heading>
        {dataDash ? (
          <>
            <DashboardCards data={[
              { label: "Mentorados", value: Number(dataDash.mentorados), icon: FaUsers },
              { label: "Mentores", value: Number(dataDash.mentores), icon: FaUserTie },
              { label: "Reuniões agendadas", value: Number(dataDash.reunioes), icon: FaCalendarCheck },
              { label: "Atividades realizadas", value: Number(dataDash.atividades), icon: FaTasks },
              { label: "Eventos", value: Number(dataDash.eventos), icon: MdEvent },
              
            ]} />
            <Stack direction={{ base: 'column', md: 'column', lg: 'row' }} spacing='14px' w={'100%'}>
              <MentoradosChart dadosMentoria={dataDash} />
              <UpcomingEvents data={dataDash.proximosEventos} />
            </Stack>
            <Stack direction={{ base: 'column', md: 'column', lg: 'row' }} spacing='14px' w={'100%'} mt={2}>
              <MentoradosSection data={dataDash.mentoradosList} />
              <MentoresSection data={dataDash.mentoresList} />
            </Stack>
          </>
        ) : (
          <Center w="100%" h="70vh" >
            <Spinner size="xl" />
          </Center>
        )}
      </VStack>
    </TemplateAdmin>
  );
}
