import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Button,
  Avatar,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Stack,
  useToast,
  Image,
} from "@chakra-ui/react";
import Calendar from "react-calendar";
import "./CalendarioAgendamentoMentorado.css";

import {
  IAgendamentoFree,
  IAgendamentoFreeAPI,
  IDateAgendamento,
  closeAgendaAPI,
} from "../helpers/agendaHelper";
import { useAuth } from "../context/AuthProvider/useAuth";

import { CustomModalOverlay } from "../pages/admin/trilha/components/CustomModalOverlay";
import { converterData } from "../utils/formatarData";

interface Props {
  mentor: string;
  img: string;
  desc: string;
  availableDates: IAgendamentoFree;
  refreshCB: () => void;
}

export function CardMentorAgendaAberta({
  mentor,
  img,
  desc,
  availableDates,
  refreshCB,
}: Props) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedDateConfirm, setSelectedDateConfirm] = useState<Date | null>(null);
  const [selectedTimeConfirm, setSelectedTimeConfirm] = useState<string | null>(null);
  const [idAgendamento, setIdAgendamento] = useState("")

  const auth = useAuth();
  const toast = useToast();

  const convertTime = (e: string) => {
    const date = e.split("-");
    return `${date[2]}/${date[1]}/${date[0]}`;
  };

  const convertTimeToISO = (e: string) => {
    const date = e.split("/");
    return `${date[2]}/${date[1]}/${date[0]}`;
  };

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
    setSelectedTime(null);
    openModal();
  };

  const handleTimeClick = (time_id: string, time: string) => {
    setSelectedDateConfirm(selectedDate);
    setSelectedTimeConfirm(time);
    setIdAgendamento(time_id)
    setIsConfirmModalOpen(true);
  };

  const confirmTimeSelection = async () => {
    if (idAgendamento && selectedDateConfirm) {
      setIsLoading(true)
      const response = await closeAgendaAPI(
        auth.payLoad?.user.id as string,
        idAgendamento
      );

      if (response.msg === "ok") {
        toast({
          position: "top",
          description: `Horário agendado com sucesso! ${converterData(String(selectedDate))} às ${selectedTimeConfirm}`,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        refreshCB();
        closeModal()
        closeConfirmModal();
      } else {
        toast({
          position: "top",
          description: `Ocorreu um erro ao realizar o agendamento`,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        refreshCB();
      }
    }
    setTimeout(() => {
      refreshCB();
    }, 750)
    closeConfirmModal();
    setIsLoading(false)
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <HStack
        w={"100%"}
        h="123px"
        spacing={"16px"}
        borderRadius={"8px"}
        backgroundColor={"xmentor.background_content"}
        border="1px"
        borderColor={"xmentor.borderCards"}
      >
        <Image
          h="100%"
          w="auto"
          rounded={1}
          borderLeftRadius={"8px"}
          borderRightRadius={"0px"}
          // backgroundColor={"xmentor.primary"}
          alt={mentor}
          src={img}
        />
        <VStack align={"start"} spacing={0} py={2}>
          <Heading fontSize={"20px"} fontWeight={500}>
            {mentor}
          </Heading>
          {/* <Text fontSize={"14px"} fontWeight={500}>
            {desc}
          </Text> */}
          <Button
            h={"36px"}
            variant={"outline"}
            colorScheme="blue"
            mt="10px"
            onClick={() => handleDateClick(new Date())}
          >
            Agendar
          </Button>
        </VStack>
      </HStack>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size={
          selectedDate &&
            availableDates[convertTime(selectedDate.toISOString().split("T")[0])]
            ? "3xl"
            : "xl"
        }
        isCentered
      >
        {" "}
        <CustomModalOverlay />
        <ModalContent transition={"0.5s all"}>
          <ModalHeader>Selecione a data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              direction={["column", "row"]}
              spacing="24px"
              justify={"start"}
              pb={5}
            >
              <VStack>

                <Image
                  w="140px"
                  h="auto"
                  rounded={14}
                  // backgroundColor={"xmentor.primary"}
                  alt={mentor}
                  src={img}
                />
                <Heading fontSize={"20px"} fontWeight={500}>
                  {mentor}
                </Heading>
                <Text fontSize={"14px"} fontWeight={500}>
                  {desc}
                </Text>
              </VStack>
              <Calendar
                tileClassName={({ date }) => {
                  const dateString = date.toISOString().split("T")[0];
                  return availableDates[convertTime(dateString)] ? "highlight" : "";
                }}
                onChange={(date) => handleDateClick(date as Date)}
                value={selectedDate || new Date()}
                tileDisabled={({ date }) => {
                  const currentDate = new Date();
                  return date < currentDate;
                }}
              />
              <Text></Text>
              {selectedDate &&
                availableDates[convertTime(selectedDate.toISOString().split("T")[0])] && (
                  <VStack
                    h={"100%"}
                    overflowX={"auto"}
                    overflowY={"hidden"}
                    whiteSpace={"nowrap"}
                    display={"block"}
                    w={'full'}
                  >
                    <Text fontSize={"19px"} fontWeight={500}>
                      Horários disponíveis
                    </Text>

                    <HStack spacing={4} style={{ flexWrap: "nowrap" }}>
                      {availableDates[convertTime(selectedDate.toISOString().split("T")[0])].map(
                        (time) => (
                          <Button
                            key={time.id}
                            size="sm"
                            onClick={() => handleTimeClick(time.id, time.time)}
                          >
                            {time.time}
                          </Button>
                        )
                      )}
                    </HStack>
                  </VStack>
                )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        isCentered
        size="sm"
      >
        <CustomModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Você realmente deseja agendar?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={closeConfirmModal}>
              Cancelar
            </Button>
            <Button colorScheme="blue" isLoading={isLoading} onClick={confirmTimeSelection}>
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
