import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  PinInput,
  PinInputField,
  SimpleGrid,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { TemplateMentorado } from "../../../../components/layout/mentorado/TemplateMentorado";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { postCodeRecoverPassword, postCreatePassword } from "../../../../context/AuthProvider/util";
import { useAuth } from "../../../../context/AuthProvider/useAuth";

export function MentoradoChangePassword() {
  const [actualPsw, setActualPsw] = useState<string>("");
  const { payLoad } = useAuth()

  const [isCode, setIsCode] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [pass, setPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  
  const [isLoading2, setIsLoading2] = useState(false)


  const navigate = useNavigate()
  let isMobile = false
  const toast = useToast()
  if (typeof window !== "undefined") {
    isMobile = window.innerWidth <= 768
  }
  const onGoBackPressed = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    navigate(-1)
  };

  async function CodeRecoverPassword(email: string) {
    if (email.length > 0)
      await postCodeRecoverPassword(email)
        .then((response) => {
          setIsCode(true)

          setActualPsw('')
          setPass('')
          setConfirmPass('')
          toast({
            position: 'top',
            description: "Um código foi enviado para seu E-MAIL",
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        })
        .catch((error) => {
          toast({
            position: 'top',
            description: error.response.data.message,
            status: 'warning',
            duration: 5000,
            isClosable: true,
          })
        })
    setActualPsw('')
    setPass('')
    setConfirmPass('')
    setIsLoading(false)
  }

  async function NewPassword(code: string, pass: string, confirmPass: string) {

    setIsLoading2(true)
    await postCreatePassword(code, pass, confirmPass)
      .then((response) => {
        setIsSuccess(true)
        setIsCode(false)
        setTimeout(() => navigate('/'), 750)
      })
      .catch((error) => {
        toast({
          position: 'top',
          description: error.response.data.message,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        })
      })

    setActualPsw('')
    setPass('')
    setConfirmPass('')
    setIsLoading2(false)

  }


  return (
    <TemplateMentorado>
      <Flex
        px={{ base: "16px", md: "64px" }}
        flexDirection={"column"}
        gap={"24px"}
      >
        <VStack w={"fit-content"} h={"fit-content"}>
          <Box position={"relative"} w={"100%"} h={"24px"}>
            <Button
              variant={"unstyled"}
              onClick={onGoBackPressed}
              w={"100%"}
              position={"absolute"}
              color={"GrayText"}
            >
              <HStack>
                <ArrowBackIcon />
                <Text fontWeight={"500"} color={"Metoria"}>
                  Configurações
                </Text>
              </HStack>
            </Button>
          </Box>
          <Text fontSize={"24px"} fontWeight={"700"}>
            Alterar senha
          </Text>
        </VStack>
        <Flex justify={"center"} align={"center"}>


          <FormControl
            maxW={"926px"}
            w={"100%"}
            p={{ base: "16px", md: "40px" }}
            gap={"16px"}
            bgColor={"#fff"}
            rounded={"md"}
          >
            {!isCode && !isSuccess &&
              <>

                <Button
                  mt="3"
                  colorScheme="blue"
                  size="lg"
                  onClick={() => {
                    if (payLoad && payLoad.user) {
                      CodeRecoverPassword(String(payLoad.user.email))
                      setIsLoading(true)
                    }
                  }}
                  isLoading={isLoading}
                >ALTERAR SENHA</Button>

              </>
            }
            {isCode &&
              <>
                <FormLabel>Insira o código</FormLabel>
                <HStack mb={4}>
                  <PinInput
                    focusBorderColor="blue.500"
                    id="Código"
                    type='alphanumeric'
                    value={actualPsw}
                    onChange={(e) => setActualPsw(e.toUpperCase())}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                <FormLabel>Nova senha</FormLabel>
                <Input
                  type="password"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                  mb={4}
                />
                <FormLabel>Confirme a nova senha</FormLabel>
                <Input
                  type="password"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                  mb={4}
                />
                <Flex
                  w={"100%"}
                  justify={{ base: "center", md: "right" }}
                  mt={"16px"}
                  gap={2}
                >
                  <Button
                    color="white"
                    bgColor="#4963FF"
                    onClick={() => {
                      NewPassword(actualPsw, pass, confirmPass)
                    }}
                    isLoading={isLoading2}
                  >
                    Confirmar
                  </Button>
                  <Button
                    // colorScheme="blue"
                    onClick={() => {
                      if (payLoad && payLoad.user) {
                        CodeRecoverPassword(String(payLoad.user.email))
                        setIsLoading(true)
                      }
                    }}
                    isLoading={isLoading}
                  >Reinviar código</Button>
                </Flex>
              </>

            }
          </FormControl>
        </Flex>
      </Flex>
    </TemplateMentorado>
  );
}
