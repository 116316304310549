import React, { useState } from "react";
import { Box, Text, Avatar, VStack, HStack, Table, Thead, Tbody, Tr, Th, Td, Flex, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


interface IData {
  data: {
    id: string
    foto: string
    nome: string
    ocupacao: string
    quantidadeAcoesCliente: number
    quantidadeHistoricoMentoria: number
  }[]
}
const MentoradosSection: React.FC<IData> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const paginatedMentorados = data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const navigate = useNavigate()
  return (
    <Box
      bg="white"
      borderRadius="24px"
      p={6}
      boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      flex="1"
      w={{ base: "100%", md: "100%", lg: "70%" }}
      overflowX="auto"
    >
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Mentorados
      </Text>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Etapas percorridas</Th>
            <Th>Atividades realizadas</Th>
          </Tr>
        </Thead>
        <Tbody>
          {paginatedMentorados.map((mentorado, index) => (
            <Tr key={index} _hover={{ backgroundColor: "blue.50" }} cursor="pointer" transition="0.3s all" borderRadius="24px"
              onClick={() => navigate("/admin/users/view", {
                state: {
                  id: mentorado.id,
                  nome: mentorado.nome,
                  email: "",
                  foto: mentorado.foto,
                  perfil: "Mentorado"
                }
              })}
            >
              <Td>
                <HStack spacing={3}>
                  <Avatar src={`${process.env.REACT_APP_API}/tmp/user/${mentorado.foto}`} />
                  <VStack align="start" spacing={0}>
                    <Text fontWeight="bold">{mentorado.nome}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {mentorado.ocupacao}
                    </Text>
                  </VStack>
                </HStack>
              </Td>
              <Td>{mentorado.quantidadeHistoricoMentoria}</Td>
              <Td>
                <Flex justify="space-between" align="center">
                  {mentorado.quantidadeAcoesCliente}
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="space-between" mt={4}>
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          isDisabled={currentPage === 0}
          variant="outline"
        >
          Anterior
        </Button>
        <Text>Página {currentPage + 1} de {Math.ceil(data.length / itemsPerPage)}</Text>
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          isDisabled={(currentPage + 1) * itemsPerPage >= data.length}
          variant="outline"
        >
          Próximo
        </Button>
      </Flex>
    </Box>
  );
};

export default MentoradosSection;
