import { Box, Flex, useToast } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { Sidebar } from "./Sidebar";
import { Header } from "../Header";
import { useNavigate } from "react-router-dom";
import isAuthenticated from "../../../context/AuthProvider/util";
import { motion } from "framer-motion";

export interface Props {
  children: ReactNode;
  fixedHeader?: boolean;
}

export function TemplateAdmin({ children, fixedHeader }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/');
    }
    const timer = setInterval(() => {
      if (!isAuthenticated()) {
        toast({
          position: 'top-right',
          description: "Sua sessão encerrou!",
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        navigate('/');
      }
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [navigate, toast]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Flex background="xmentor.background_template" minH={"100vh"}>
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} setIsExpanded={setIsExpanded} isMenu={isExpanded} />
      <Flex
        direction="column"
        maxW={{ base: '100%', lg: isExpanded ? 'calc(100% - 312px)' : 'calc(100% - 80px)' }}

        flex="1"
        ml={{ base: 0, lg: isExpanded ? "312px" : "80px" }}
        transition="margin-left 0.3s ease"
      >
        <Header sendInfo={toggleSidebar} fixed={fixedHeader || false} isMenu={isExpanded} updateNotification={() => {}} />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <Box
            overflow={"hidden"}
            id="arrastadm"
            p="4"
            px={{ base: "16px", lg: "64px" }}
          >
            {children}
          </Box>
        </motion.div>
      </Flex>
    </Flex>
  );
}
