import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { TemplateMentorado } from "../../../components/layout/mentorado/TemplateMentorado";
import { ArrowBackIcon } from "@chakra-ui/icons";
import AvatarWithButton from "../../../components/AvatarWithChangeButton";
import DocumentsCard, {
  IDocumentsCard,
} from "../../../components/cards/DocumentsCard";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { auth } from "../../../context/AuthProvider/util";
import {
  IUpdateProfileData,
  getDocumentsAPI,
  getSobreMimAPI,
  updateProfileAPI,
} from "../../../helpers/profileHelper";

const docsAPI: IDocumentsCard[] = [
  {
    name: "Contrato 25-05-2023",
    payment: "Pagamento - Cartao Credito 1x",
    fileName: "Contrato 25-05-2023.pdf",
    fileSize: "2 MB",
  },
  {
    name: "Contrato 26-05-2023",
    payment: "Pagamento - Cartao Credito 1x",
    fileName: "Contrato 25-05-2023.pdf",
    fileSize: "2 MB",
  },
];

export function MentoradoProfile() {
  const [isEditPressed, setEditPressed] = React.useState(false);
  const [documents, setDocuments] = React.useState<IDocumentsCard[]>(docsAPI);
  const [name, setName] = React.useState<string>("");
  const [contact, setContact] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [goals, setGoals] = React.useState<string>("");
  const [strongPoints, setStrongPoint] = React.useState<string>("");
  const [improvements, setimprovements] = React.useState<string>("");
  const [photoUrl, setPhotoUrl] = React.useState<string>("");
  const [userType, setUserType] = React.useState<string>("");

  const [isLoading, setLoading] = React.useState(false);

  const user = useAuth();
  const toast = useToast();

  const onEditPressed = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setEditPressed(!isEditPressed);
  };

  const onSaveChangePressed = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoading(true);
    const data: IUpdateProfileData = {
      contato: contact,
      email: email,
      nome: name,
      sobreMim: {
        metas: goals,
        pontosDeMelhoria: improvements,
        pontosFortes: strongPoints,
      },
    };
    const response = await updateProfileAPI(
      user.payLoad?.user.id as string,
      data
    );

    if (response.msg === "Usuário atualizado com sucesso") {
      toast({
        position: "top",
        description: "Perfil atualizado com sucesso",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } else {
      toast({
        position: "top",
        description: "Ocorreu um erro ao atualizar o perfil",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const handleLoadNotes = async () => {
    const response = await getSobreMimAPI(user.payLoad?.user.id as string);
    if (response.msg === "ok") {
      setGoals(response.data.metas);
      setStrongPoint(response.data.pontosFortes);
      setimprovements(response.data.pontosDeMelhoria);
      return true;
    }
    return false;
  };

  const handleLoadDocuments = async () => {
    try {
      const response = await getDocumentsAPI(user.payLoad?.user.id as string);
      if (response.length > 0) {
        const docs: IDocumentsCard[] = response.map((doc, index) => {
          return {
            fileName: doc.id,
            name: doc.titulo,
            fileSize: "?",
            payment: doc.descricao,
            url: doc.arquivo,
          };
        });
        setDocuments(docs);
      }
      return true;
    } catch {
      return false;
    }
  };

  const handleLoadProfile = async () => {
    const _user = user.payLoad?.user;
    if (_user) {
      setContact(_user.contato as string);
      setName(_user.nome as string);
      setEmail(_user.email as string);
      setUserType(_user.perfil as string);
    }
    try {
      if (!(await handleLoadNotes())) {
        toast({
          position: "top",
          description: "Ocorreu um erro ao carregar os parâmetros do perfil",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
      if (!(await handleLoadDocuments())) {
        toast({
          position: "top",
          description: "Ocorreu um erro ao carregar os parâmetros do perfil",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (e) {
      console.log(e);
      toast({
        position: "top",
        description: `Ocorreu um erro desconhecido`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  React.useEffect(() => {
    const loadParams = async () => {
      setLoading(true);
      await handleLoadProfile();
      setLoading(false);
    };
    loadParams();
  }, []);

  return (
    <TemplateMentorado>
      <Flex
        w={"100%"}
        h={"100%"}
        px={{ base: "16px", md: "64px" }}
        pt={{ base: "16px", md: "26px" }}
        flexDir={"column"}
        justifyContent={isLoading ? "center" : "right"}
        gap={"24px"}
        overflow={"scroll"}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <VStack w={"fit-content"} h={"fit-content"}>
              <Box position={"relative"} w={"100%"} h={"24px"}>
                {isEditPressed && (
                  <Button
                    variant={"unstyled"}
                    onClick={onEditPressed}
                    w={"100%"}
                    position={"absolute"}
                    color={"GrayText"}
                  >
                    <HStack>
                      <ArrowBackIcon />
                      <Text fontWeight={"500"} color={"Metoria"}>
                        Perfil
                      </Text>
                    </HStack>
                  </Button>
                )}
              </Box>
              <Text fontSize={"24px"} fontWeight={"700"}>
                Perfil
              </Text>
            </VStack>
            <Flex w={"100%"} position={"relative"} flexDir={"column"}>
              <Tabs
                position={"absolute"}
                w={"100%"}
                variant="unstyled"
                gap={"24px"}
                visibility={{ base: "hidden", md: "visible" }}
              >
                <TabList>
                  <Tab
                    _selected={{ color: "white", bg: "#4963FF", rounded: "lg" }}
                  >
                    Geral
                  </Tab>
                  <Tab
                    _selected={{ color: "white", bg: "#4963FF", rounded: "lg" }}
                  >
                    Documentos
                  </Tab>
                </TabList>
                <TabPanels>
                  {isEditPressed ? (
                    <TabPanel
                      w={"100%"}
                      bgColor={"#fff"}
                      rounded={"lg"}
                      mt={"24px"}
                      gap={"24px"}
                    >
                      <Center>
                        <AvatarWithButton
                          url={photoUrl}
                          setUrlCb={setPhotoUrl}
                        />
                      </Center>
                      <VStack w={"100%"} gap={"24px"}>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            textAlign={"left"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                            lineHeight={"22px"}
                          >
                            Nome
                          </Text>
                          <Input
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                          />
                        </VStack>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            textAlign={"left"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                            lineHeight={"22px"}
                          >
                            Contato
                          </Text>
                          <Input
                            value={contact}
                            onChange={(e) => setContact(e.currentTarget.value)}
                          />
                        </VStack>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            textAlign={"left"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                            lineHeight={"22px"}
                          >
                            Email
                          </Text>
                          <Input
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                          />
                        </VStack>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            textAlign={"left"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                            lineHeight={"22px"}
                          >
                            Metas profissonais
                          </Text>
                          <Input
                            value={goals}
                            onChange={(e) => setGoals(e.currentTarget.value)}
                          />
                        </VStack>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            textAlign={"left"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                            lineHeight={"22px"}
                          >
                            Pontos Fortes
                          </Text>
                          <Input
                            value={strongPoints}
                            onChange={(e) =>
                              setStrongPoint(e.currentTarget.value)
                            }
                          />
                        </VStack>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            textAlign={"left"}
                            fontWeight={"400"}
                            fontSize={"16px"}
                            lineHeight={"22px"}
                          >
                            Pontos de melhoria
                          </Text>
                          <Input
                            value={improvements}
                            onChange={(e) =>
                              setimprovements(e.currentTarget.value)
                            }
                          />
                        </VStack>
                        <Flex w={"100%"} justify={"right"}>
                          <Button
                            bgColor={"#4963FF"}
                            color={"#fff"}
                            onClick={onSaveChangePressed}
                          >
                            Salvar Alterações
                          </Button>
                        </Flex>
                      </VStack>
                    </TabPanel>
                  ) : (
                    <TabPanel
                      w={"100%"}
                      bgColor={"#fff"}
                      rounded={"lg"}
                      mt={"24px"}
                      gap={"24px"}
                    >
                      <Flex w={"100%"} justify={"space-between"}>
                        <Text fontSize={"24px"} fontWeight={"500"}>
                          Geral
                        </Text>
                        <Button variant={"outline"} onClick={onEditPressed}>
                          Editar
                        </Button>
                      </Flex>
                      <HStack mt={"30px"}>
                        <Avatar size={"lg"} />
                        <Text fontSize={"24px"}>{name}</Text>
                      </HStack>
                      <VStack gap={"24px"} mt={"24px"}>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            /*fontFamily={'Rubik'}*/ textAlign={"left"}
                            fontSize={"15px"}
                            color={"#8A8490"}
                          >
                            Contato
                          </Text>
                          <Text
                            w={"100%"}
                            /*fontFamily={'Rubik'}*/ textAlign={"left"}
                            fontSize={"16px"}
                            fontWeight={"400"}
                          >
                            {contact}
                          </Text>
                        </VStack>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            /*fontFamily={'Rubik'}*/ textAlign={"left"}
                            fontSize={"15px"}
                            color={"#8A8490"}
                          >
                            Email
                          </Text>
                          <Text
                            w={"100%"}
                            /*fontFamily={'Rubik'}*/ textAlign={"left"}
                            fontSize={"16px"}
                            fontWeight={"400"}
                          >
                            {email}
                          </Text>
                        </VStack>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            /*fontFamily={'Rubik'}*/ textAlign={"left"}
                            fontSize={"15px"}
                            color={"#8A8490"}
                          >
                            Metas Profissionais
                          </Text>
                          <Text
                            w={"100%"}
                            /*fontFamily={'Rubik'}*/ textAlign={"left"}
                            fontSize={"16px"}
                            fontWeight={"400"}
                          >
                            {goals}
                          </Text>
                        </VStack>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            /*fontFamily={'Rubik'}*/ textAlign={"left"}
                            fontSize={"15px"}
                            color={"#8A8490"}
                          >
                            Pontos Fortes
                          </Text>
                          <Text
                            w={"100%"}
                            /*fontFamily={'Rubik'}*/ textAlign={"left"}
                            fontSize={"16px"}
                            fontWeight={"400"}
                          >
                            {strongPoints}
                          </Text>
                        </VStack>
                        <VStack w={"100%"}>
                          <Text
                            w={"100%"}
                            /*fontFamily={'Rubik'}*/ textAlign={"left"}
                            fontSize={"15px"}
                            color={"#8A8490"}
                          >
                            Pontos de melhoria
                          </Text>
                          <Text
                            w={"100%"}
                            /*fontFamily={'Rubik'}*/ textAlign={"left"}
                            fontSize={"16px"}
                            fontWeight={"400"}
                          >
                            {improvements}
                          </Text>
                        </VStack>
                      </VStack>
                    </TabPanel>
                  )}
                  <TabPanel w={"100%"} mt={"24px"} gap={"24px"}>
                    <Text
                      /*fontFamily={'Inter'}*/ fontSize={"24px"}
                      fontWeight={"500"}
                    >
                      Documentos
                    </Text>
                    <VStack gap={"24px"} mt={"16px"}>
                      {documents.length > 0 ? (
                        documents.map((value, index) => (
                          <DocumentsCard
                            {...value}
                            key={index + "-" + value.fileName}
                          />
                        ))
                      ) 
                      : 
                      (
                        <Flex
                          w={"100%"}
                          bgColor={"#fff"}
                          flexDir={"column"}
                          alignSelf={"stretch"}
                          rounded={"lg"}
                          padding={"24px"}
                          gap={"24px"}
                        >
                          <Text>
                            Não há documentos a serem exibidos
                          </Text>
                        </Flex>
                      )}
                    </VStack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
              {isEditPressed ? (
                <Flex
                  w={"100%"}
                  bgColor={"#fff"}
                  rounded={"lg"}
                  padding={"16px"}
                  flexDir={"column"}
                  justifyContent={"right"}
                  visibility={{ base: "visible", md: "hidden" }}
                  gap={"24px"}
                >
                  <Center>
                    <AvatarWithButton url={photoUrl} setUrlCb={setPhotoUrl} />
                  </Center>
                  <VStack>
                    <Text
                      w={"100%"}
                      textAlign={"left"}
                      fontWeight={"400"}
                      fontSize={"16px"}
                      lineHeight={"22px"}
                    >
                      Nome
                    </Text>
                    <Input
                      value={name}
                      onChange={(e) => setName(e.currentTarget.value)}
                    />
                  </VStack>
                  <VStack>
                    <Text
                      w={"100%"}
                      textAlign={"left"}
                      fontWeight={"400"}
                      fontSize={"16px"}
                      lineHeight={"22px"}
                    >
                      Contato
                    </Text>
                    <Input
                      value={contact}
                      onChange={(e) => setContact(e.currentTarget.value)}
                    />
                  </VStack>
                  <VStack>
                    <Text
                      w={"100%"}
                      textAlign={"left"}
                      fontWeight={"400"}
                      fontSize={"16px"}
                      lineHeight={"22px"}
                    >
                      Email
                    </Text>
                    <Input
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </VStack>
                  <Button
                    w={"100%"}
                    rounded={"md"}
                    bgColor={"#4963FF"}
                    color={"#fff"}
                    onClick={onSaveChangePressed}
                  >
                    Salvar Alterações
                  </Button>
                </Flex>
              ) : (
                <Flex
                  w={"100%"}
                  bgColor={"#fff"}
                  rounded={"lg"}
                  padding={"16px"}
                  flexDir={"column"}
                  justifyContent={"right"}
                  visibility={{ base: "visible", md: "hidden" }}
                  gap={"24px"}
                  position={"absolute"}
                >
                  <Flex
                    w={"100%"}
                    height={"64px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <HStack overflow={"clip"}>
                      <Avatar size={"lg"} />
                      <Text
                        /*fontFamily={'Lato'}*/ fontWeight={"700"}
                        fontSize={"24px"}
                      >
                        {name}
                      </Text>
                    </HStack>
                    <Button variant={"outline"} onClick={onEditPressed}>
                      Editar
                    </Button>
                  </Flex>

                  <Box w={"100%"}>
                    <Text
                      /*fontFamily={'Rubik'}*/ fontWeight={"400"}
                      color={"gray"}
                      lineHeight={"15px"}
                    >
                      Cargo
                    </Text>
                    <Text
                      /*fontFamily={'Rubik'}*/ fontWeight={"700"}
                      mt={"12px"}
                    >
                      {userType}
                    </Text>
                  </Box>

                  <Box w={"100%"}>
                    <Text
                      /*fontFamily={'Rubik'}*/ fontWeight={"400"}
                      color={"gray"}
                      lineHeight={"15px"}
                    >
                      Contato
                    </Text>
                    <Text
                      /*fontFamily={'Rubik'}*/ fontWeight={"700"}
                      mt={"12px"}
                    >
                      {contact}
                    </Text>
                  </Box>

                  <Box w={"100%"}>
                    <Text
                      /*fontFamily={'Rubik'}*/ fontWeight={"400"}
                      color={"gray"}
                    >
                      Email
                    </Text>
                    <Text
                      /*fontFamily={'Rubik'}*/ fontWeight={"700"}
                      mt={"12px"}
                    >
                      {email}
                    </Text>
                  </Box>
                </Flex>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </TemplateMentorado>
  );
}
