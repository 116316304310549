import { useState, useEffect } from "react";
import { Stack, Text, Box, HStack, IconButton, Input } from "@chakra-ui/react";
import { IoMdColorFilter } from "react-icons/io";

interface ColorPickerProps {
  onColorChange: (color: string) => void; // Define a prop onColorChange
  initialColor?: string; // Nova prop para a cor inicial
}

function ColorPicker({ onColorChange, initialColor = "#000000" }: ColorPickerProps) {
  const [color, setColor] = useState(initialColor);

  useEffect(() => {
    setColor(initialColor); // Atualiza a cor quando a prop initialColor mudar
  }, [initialColor]);

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setColor(newColor);
    onColorChange(newColor); // Chama a função onColorChange quando a cor é alterada
  };

  return (
    <Stack spacing={2} w="full">
      <Text fontSize="sm" fontWeight="bold" color="black">
        Cor
      </Text>
      <HStack spacing={2} bg="gray.100" p={0} pl={2} borderRadius="md" justifyContent="space-between">
        <Box
          w="24px"
          h="24px"
          pl={2}
          bg={color}
          borderRadius="50%"
          border="1px solid #ccc"
        />
        <IconButton
          aria-label="Escolher cor"
          icon={<IoMdColorFilter size={20} />}
          onClick={() => document.getElementById('colorPickerInput')?.click()}
          variant="ghost"
        />
        <Input
          id="colorPickerInput"
          type="color"
          value={color}
          onChange={handleColorChange}
          w="0px"
          h="0px"
          p={0}
          border="none"
          opacity="0"
          position="absolute"
          visibility="hidden"
        />
      </HStack>
    </Stack>
  );
}

export default ColorPicker;
