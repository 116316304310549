import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  FormLabel,
  Select,
  HStack,
  Avatar,
  Image,
  Text,
  Divider,
  Flex,
  useToast,
} from "@chakra-ui/react";
import {
  timeMapping,
  minutesToIndex,
  formatHours,
} from "../../../../utils/calculateHours";
import { useMentoria } from "../../../../context/MentoriaProvider/useMentoria";
import { converterData } from "../../../../utils/formatarData";

interface EditTimeModalProps {
  isOpen: boolean;
  onClose: () => void;
  extraHours?: {
    carga_horaria: number;
    id: string;
    data_cadastro: string;
    autor: {
      id: string;
      nome: string;
      foto: string;
    };
  }[];
  historicoMentoriaId: string;
  mentor: { id: string; nome: string; foto: string; carga_horaria: number };
  initialHours: number;
  onSave: () => void;
}

export const ModalEditTime: React.FC<EditTimeModalProps> = ({
  isOpen,
  onClose,
  extraHours,
  mentor,
  historicoMentoriaId,
  initialHours,
  onSave,
}) => {
  const [selectedHours, setSelectedHours] = useState<number>(0);
  const toast = useToast();
  const { CadastrarHorariaExtra, trilha } = useMentoria();

  var totalExtraMinutes =
    trilha?.etapas
      .find((i) => i.historico_id === historicoMentoriaId)
      ?.mentores.find((j) => j.mentor.id === mentor.id)
      ?.horasExtras?.reduce((acc, extra) => acc + extra.carga_horaria, 0) || 0;

  useEffect(() => {
    if (initialHours) {
      setSelectedHours(minutesToIndex(initialHours));
    }
  }, [initialHours]);

  const handleSumHoursChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = parseInt(event.target.value, 10);
    setSelectedHours(selectedValue);
  };

  const handleSave = async () => {
    if (selectedHours === 0) {
      toast({
        title: "Erro",
        description: "Não é possível adicionar zero horas.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    await CadastrarHorariaExtra({
      carga_horaria: timeMapping[selectedHours],
      mentorId: mentor.id,
      historicoMentoriaId: historicoMentoriaId,
    });
    onSave();
    toast({
      title: "Sucesso",
      description: "Horas adicionadas com sucesso!",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };

  const handleRemove = async () => {
    const hoursToRemove = timeMapping[selectedHours];
    if (totalExtraMinutes - hoursToRemove >= 0) {
      await CadastrarHorariaExtra({
        carga_horaria: -hoursToRemove,
        mentorId: mentor.id,
        historicoMentoriaId: historicoMentoriaId,
      });
      onSave();
      toast({
        title: "Sucesso",
        description: "Horas removidas com sucesso!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } else {
      toast({
        title: "Erro",
        description:
          "Não é possível remover mais horas do que o total disponível.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar tempo de mentoria</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody px={9} py={6}>
          <VStack spacing={4}>
            <VStack alignItems={"left"} w="full">
              <Text fontSize={"xs"} fontWeight="bold" textAlign={"left"}>
                Mentor selecionado
              </Text>
              <HStack w="full" borderWidth={"1px"} p={3} borderRadius="xl">
                <Image
                  h="96px"
                  w="96px"
                  alt={mentor.nome}
                  rounded={10}
                  src={`${process.env.REACT_APP_API}/tmp/user/${mentor.foto}`}
                />
                <VStack
                  textAlign={"left"}
                  alignItems={"left"}
                  spacing={0}
                  ml={3}
                  flex="1"
                >
                  <Text fontWeight="bold">{mentor.nome}</Text>
                </VStack>
              </HStack>
            </VStack>
            <VStack align="left" spacing={0} w="full">
              <FormLabel fontSize="xs" fontWeight="bold">
                Quantidade de horas
              </FormLabel>
              <Select
                value={selectedHours}
                onChange={handleSumHoursChange}
                bg={"xmentor.background_template"}
                placeholder="Selecione a quantidade de horas"
              >
                <option value={1}>15 Min</option>
                <option value={2}>30 Min</option>
                <option value={3}>1 Hora</option>
                <option value={4}>1 Hora e 30 Min</option>
                <option value={5}>2 Horas</option>
                <option value={6}>2 Horas e 30 Min</option>
                <option value={7}>3 Horas</option>
              </Select>
              <HStack w="full" justifyContent={"space-between"} mt={3}>
                <Button
                  w="full"
                  fontSize={"sm"}
                  variant="outline"
                  onClick={handleRemove}
                >
                  Remover
                </Button>
                <Button
                  w="full"
                  fontSize={"sm"}
                  variant="outline"
                  onClick={handleSave}
                >
                  Adicionar
                </Button>
              </HStack>
            </VStack>
            <VStack alignItems={"left"} w="full">
              <Text fontSize={"xs"} fontWeight="bold" textAlign={"left"}>
                Histórico de edição
              </Text>
              {trilha?.etapas
                .find((i) => i.historico_id === historicoMentoriaId)
                ?.mentores.find((j) => j.mentor.id === mentor.id)
                ?.horasExtras?.sort(
                  (a, b) =>
                    new Date(b.data_cadastro).getTime() -
                    new Date(a.data_cadastro).getTime()
                )
                ?.map((extra) => (
                  <VStack alignItems={"left"} w="full" key={extra.id}>
                    <Flex gap={2} w="full">
                      <Avatar
                        size="md"
                        name={extra.autor.nome}
                        rounded={"lg"}
                        src={`${process.env.REACT_APP_API}/tmp/user/${extra.autor.foto}`}
                      />
                      <Flex w="full" justifyContent="space-between" my="auto">
                        <VStack alignItems="left" spacing={0}>
                          <Flex gap={1}>
                            <Text fontSize={"xs"} textAlign={"left"}>
                              {extra.autor.nome}
                            </Text>
                            <Text
                              fontSize={"xs"}
                              fontWeight="bold"
                              textAlign={"left"}
                            >
                              {extra.carga_horaria > 0
                                ? " adicionou"
                                : " removeu"}{" "}
                              {formatHours(Math.abs(extra.carga_horaria))}
                            </Text>
                          </Flex>
                          <Text fontSize={"xs"}>nesta etapa</Text>
                        </VStack>
                        <Text fontSize={"xs"} textAlign={"left"}>
                          {converterData(extra.data_cadastro)}
                        </Text>
                      </Flex>
                    </Flex>
                    <Divider />
                  </VStack>
                ))}
              <Flex justifyContent="space-between">
                <Text fontSize="xs">Total de horas adicionais: </Text>
                <Text fontSize="xs" fontWeight="bold">
                  {formatHours(Number(totalExtraMinutes))}
                </Text>
              </Flex>
            </VStack>
          </VStack>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button
            fontSize={"sm"}
            variant="outline"
            ml={3}
            onClick={onClose}
            px={6}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
