import { VStack, HStack, Text, IconButton } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import MeetingDocument from "../../../../components/MeetingDocument";
import { ResultRouteProps } from "../view";

type AttachmentsProps = {
  onOpen: () => void;
  resultRoute: ResultRouteProps;
};

const AttachmentsSection = ({ onOpen, resultRoute }: AttachmentsProps) => {
  const { userSelected, payLoad } = useAuth();

  // Check if user is "auxiliar"
  const isAuxiliar = payLoad?.user.perfil === "auxiliar";

  // Check if user is "mentor" and is not admin
  const isMentorAndAdmin =
    payLoad?.user.perfil === "mentor" && payLoad?.user.isAdmin;

  // Implementar lógica para abrir modal de anexar arquivos
  const formatarDataHora = (data: string) => {
    // Implementar lógica para formatar data e hora
    return new Date(data).toLocaleString();
  };

  return (
    <VStack
      align={"flex-start"}
      p={[2, 5]}
      bg="white"
      w="100%"
      borderWidth="1px"
      borderRadius="lg"
    >
      <HStack w={"100%"} justifyContent={"space-between"}>
        <Text fontSize="xl" fontWeight="bold" mb={3}>
          Anexos
        </Text>
        <IconButton
          colorScheme="primary"
          bgColor={"xmentor.primary"}
          color={"white"}
          icon={<FaPlus />}
          aria-label="Edit"
          ml={{ base: 0, md: "auto" }}
          mt={{ base: 4, md: 0 }}
          onClick={onOpen}
          hidden={!isAuxiliar && !isMentorAndAdmin}
        />
      </HStack>
      <VStack
        spacing={"20px"}
        w="100%"
        mb={5}
        hidden={!isAuxiliar && !isMentorAndAdmin}
      >
        {userSelected?.anexos_cliente.map((i, index) => (
          <MeetingDocument
            key={index}
            name={i.url.substring(i.url.indexOf("-") + 1)}
            id={i.id}
            idUser={resultRoute.id}
            uri={i.url}
            dataCriacao={formatarDataHora(i.data_cadastro)}
          />
        ))}
      </VStack>
    </VStack>
  );
};

export default AttachmentsSection;
