import { useEffect, useState } from "react";
import {
  Text,
  Button,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { CustomModalOverlay } from "../../trilha/components/CustomModalOverlay";
import { Input } from "../../../../components/Form/Input";
import { IModalAddRedesSociais } from "./MoldalUpdateDataUser";

export function ModalAddRedesSociais(props: IModalAddRedesSociais) {
  const { AtualizarRedesSociais, userSelected } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [instagram, setInstagram] = useState<string>("");
  const [linkedin, setLinkedin] = useState<string>("");
  const [youtube, setYoutube] = useState<string>("");

  useEffect(() => {
    if (userSelected?.redes_sociais) {
      setInstagram(userSelected?.redes_sociais.instagram);
      setLinkedin(userSelected?.redes_sociais.linkedin);
      setYoutube(userSelected?.redes_sociais.youtube);
    }
  }, [userSelected]);

  const handleUpdate = async () => {
    await AtualizarRedesSociais(
      props.usuario_cliente_id,
      instagram,
      linkedin,
      youtube
    );
    props.onClose();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Redes Sociais</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Instagram</Text>
          <Input
            type="text"
            name="quiz"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
          <Text>LinkedIn</Text>
          <Input
            type="text"
            name="quiz"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
          />
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="outline" size="lg" onClick={props.onClose}>
            Fechar
          </Button>
          <Button
            colorScheme="blue"
            size="lg"
            onClick={handleUpdate}
            isLoading={isLoading}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
