import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import { MentoriaProvider } from "../../../context/MentoriaProvider";
import { ViewUser } from "./view";

export function ViewTemplate() {
  return (
    <TemplateAdmin>
      <MentoriaProvider>
        <ViewUser />
      </MentoriaProvider>
    </TemplateAdmin>
  );
}
