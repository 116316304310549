import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  IConfigMentoria, IContext, IMentoria, IMentoriaProvider, ITipoMentoria } from "./types";
import { useToast } from "@chakra-ui/react";
import { 
  atualizarConfigMentoria,
  atualizarFotoMentoria, 
  atualizarMentoria, 
  consultaMentoria, 
  consultaTipoConfigMentoria, 
  consultaTipoMentoria, 
  criarConfigMentoria, criarMentoria, deletarMentoria } from "./util";


export const MasterContext = createContext<IContext>({} as IContext);

export const MasterProvider = ({ children }: IMentoriaProvider) => {
  const navigate = useNavigate();
  const [mentorias, setMentorias] = useState<IMentoria []>([])
  const [tipoMentoria, setTipoMentoria] = useState<ITipoMentoria []>([])
  const toast = useToast();

  const showSuccessToast = (description: string) => {    
    toast({
        position: 'top',
        description,
        status: 'success',
        duration: 5000,
        isClosable: true,
    });
  };

  const showErrorToast = (error: any) => {
    toast({
      position: 'top',
      description: error.response?.data?.message || error ||"Error",
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  } ;

  const ConsultaMentoria = async (
    nome: string,
    pagina: number
  ) => {
    try{
      const response = await consultaMentoria( nome, pagina)
      setMentorias(response)
    } catch (error) {
      showErrorToast(error);
    }
  }
  const CriarMentoria = async (form: FormData)=>{
    try{
      await criarMentoria(form)
      showSuccessToast("Sucesso ao criar uma nova mentoria!")
      setTimeout(()=>navigate("/master/home"), 2000)      
    } catch (error) {
      showErrorToast(error);
    } finally {
      await ConsultaMentoria("",0)
      setTimeout(()=>ConsultaMentoria("",0), 1500)
      
    }
  }
  const CriarConfigMentoria = async (form: FormData):Promise<IConfigMentoria> =>{
    try{
      const response = await criarConfigMentoria(form)
      showSuccessToast("Sucesso ao criar configurações de mentoria!")
      localStorage.setItem("xmentor-color-theme", response.cor)    
      return response;
      // setTimeout(()=>navigate("/master/home"), 2000)  
    } catch (error) {
      showErrorToast(error);
      return Promise.reject(error)
    }
  }
  const AtualizarConfigMentoria = async (id: string, nome: string,  cor: string)=>{
    try{
      const response = await atualizarConfigMentoria(id, nome, cor)
      showSuccessToast("Atualizado com sucesso!")
      localStorage.setItem("xmentor-color-theme", cor)
      return response;
    } catch (error) {
      showErrorToast(error);
      return Promise.reject(error)
    }
  }
 
  const ConsultaTipoConfigMentoria = async (id: string) => {
    try{
      const response = await consultaTipoConfigMentoria(id)
      return response 
    } catch (error) {
      return Promise.reject(error)
    } 
  }
  const AtualizarMentoria = async (id: string, nome: string,  descricao: string)=>{
    try{
      await atualizarMentoria(id, nome, descricao)
      showSuccessToast("Salvo com sucesso!")
      setTimeout(()=>navigate("/master/home"), 2000)
    } catch (error) {
      showErrorToast(error);
    } finally {
      await ConsultaMentoria("",0)      
      setTimeout(()=>ConsultaMentoria("",0), 1500)
    }
  }
  const AtualizarFotoMentoria = async (id: string, form: FormData)=>{
    try{
      await atualizarFotoMentoria(id, form)
    } catch (error) {
      showErrorToast(error);
    } finally {
      await ConsultaMentoria("",0)
      
      setTimeout(()=>ConsultaMentoria("",0), 1500)
    }
  }
  const DeletarMentoria = async (id: string) => {
    try{
      await deletarMentoria(id)
    } catch (error) {
      showErrorToast(error);
    } finally {
      await ConsultaMentoria("",0)
      
      setTimeout(()=>ConsultaMentoria("",0), 1500)
    }
  }
  const ConsultaTipoMentoria = async () => {
    try{
      const response = await consultaTipoMentoria()
      setTipoMentoria(()=>response)
    } catch (error) {
      showErrorToast(error);
    } 
  }

  return (
    <MasterContext.Provider value={{
      ConsultaMentoria,
      CriarMentoria,
      AtualizarMentoria,
      AtualizarFotoMentoria,
      DeletarMentoria,
      mentorias,
      ConsultaTipoMentoria,
      tipoMentoria,
      CriarConfigMentoria,
      ConsultaTipoConfigMentoria,
      AtualizarConfigMentoria
    }}>
      {children}
    </MasterContext.Provider>
  );
};