import React from "react";
import { Box, Text, Flex, VStack, HStack, Link } from "@chakra-ui/react";
import { IAtualizaEvento } from "../../../../context/EventoProvider/types";
import { formatarDataHora } from "../../../../utils/formatarData";

interface IData {
  data: IAtualizaEvento[]
}

const UpcomingEvents: React.FC <IData> = ({data}) => {
  return (
    <Box
      bg="white"
      borderRadius="24px"
      p={6}
      boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
     w={{base:"100%",md:"100%",lg:"40%"}}
     
    >
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Próximos eventos
      </Text>
      <VStack spacing={4} align="stretch">
        {data&&data.map((event, index) => (
          <HStack
            key={index}
            p={4}
            _hover={{ backgroundColor: "blue.50" }}
            cursor="pointer"
            borderRadius="12px"
          >
            <Box w="4px" h="100%" bg="blue.500" borderRadius="full" />
            <Box ml={4}>
              <Text fontWeight="bold">{event.nome}</Text>
              <Text color="gray.500">{formatarDataHora(event.data_evento)} - {event.duracao}min</Text>
            </Box>
          </HStack>
        ))}
      </VStack>
    </Box>
  );
};

export default UpcomingEvents;