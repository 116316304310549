import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Input, InputGroup, InputLeftElement, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, VStack, useTheme } from "@chakra-ui/react";

import { FiSearch } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { useNavigate } from "react-router-dom";
import { InputSearch } from "../../../components/Form/InputSearch";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import { formatarDataHora } from "../../../utils/formatarData";

export function UsersAdmin() {
    const navigate = useNavigate()
    const { SearchUser, users, payLoad, FindUserById } = useAuth()
    const [page, setPage] = useState(0)
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const theme = useTheme();
    const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;

    useEffect(() => {
        if (search.length >= 3) {
            handlePage(search, page)
        } else if (search.length == 0) {
            setPage(0)
            handlePage("", 0)
        }
    }, [, search])

    function handlePage(search: string, page: number) {
        if (page >= 0)
            if (payLoad?.user.mentoria_id) {
                setIsLoading(true)
                Promise.resolve(SearchUser({
                    nome: search,
                    perfil: "auxiliar",
                    pagina: page,
                    mentoria_id: payLoad?.user.mentoria_id
                }))
                setIsLoading(false)
            }

    }
    return (
        <TemplateAdmin>
            <VStack spacing={4} align={"flex-start"} mb={4}>
                <Text
                    fontSize="xl"
                    fontWeight={700}
                    fontStyle="normal"
                    lineHeight="normal"
                    letterSpacing={"-0.227px"}
                >Auxiliares</Text>
                <Button
                    type="submit"
                    colorScheme="blue"
                    size="md"
                    bg="xmentor.primary"
                    leftIcon={<GoPlus />}
                    onClick={() => navigate("/admin/users/register-new-user", { state: { perfil: "auxiliar" } })}
                    isDisabled={Number(payLoad?.user.mentoria?.tipo_mentoria?.qtd_auxiliares) <= Number(users?.total)}
                    _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
                >
                    Novo auxiliar
                </Button>

                <InputSearch
                    placeholder="Pesquisa auxiliar"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </VStack>

            <Box
                bg="#FFF"
                borderRadius="8px"
                border="1px solid #EAECF0"
            >
                <Table variant="simple" w="full" >
                    <Thead >
                        <Tr justifyContent="space-between">
                            <Th>Nome</Th>
                            <Th display={['none', 'flex', 'flex']}>Email</Th>
                            <Th>Último acesso</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users &&
                            users.data.length > 0 &&
                            users.data.map((i) => {
                                return (
                                    <Tr
                                        cursor={'pointer'}
                                        transition={"0.5s"}
                                        _hover={{
                                            backgroundColor: "gray.100",
                                        }}
                                        key={i.id}
                                        justifyContent="space-between"
                                        onClick={async () => {
                                            await FindUserById(String(i.id))
                                            navigate("/admin/users/view", {
                                                state: {
                                                    id: i.id,
                                                    nome: i.nome,
                                                    email: i.email,
                                                    foto: i.foto,
                                                    perfil: "Auxiliar"
                                                }
                                            })
                                        }
                                        }>
                                        <Td>{i.nome}</Td>
                                        <Td display={['none', 'flex', 'flex']} >{i.email}</Td>
                                        <Td  >{i.ultimo_acesso?formatarDataHora(String(i.ultimo_acesso)):"***"}</Td>
                                        <Td color="blue.400" textAlign="right" fontWeight="bold">

                                        </Td>
                                    </Tr>
                                );
                            })}
                    </Tbody>
                    {users && (
                        <Tfoot >
                            <Tr justifyContent="space-between">
                                <Th>
                                    <Button
                                        variant={"outline"}
                                        onClick={() => {
                                            setPage(page - 1);
                                            handlePage(search, page - 1);
                                        }}
                                        isDisabled={page === 0}
                                        isLoading={isLoading}
                                    >
                                        Anterior
                                    </Button>
                                </Th>
                                <Th textAlign="center">Página {page + 1} de {Math.ceil(users.total / 20)}</Th>
                                <Th textAlign="right">
                                    <Button
                                        variant={"outline"}
                                        onClick={() => {
                                            setPage(page + 1);
                                            handlePage(search, page + 1);
                                        }}
                                        isDisabled={Math.ceil(users.total / 20) === page + 1}
                                        isLoading={isLoading}
                                    >
                                        Próximo
                                    </Button>
                                </Th>
                            </Tr>
                        </Tfoot>
                    )}
                </Table>
            </Box>

        </TemplateAdmin>
    )
}


