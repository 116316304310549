import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Input,
  Textarea,
  Select,
  useToast,
  Divider,
  FormLabel,
  VStack,
  IconButton,
  Flex,
  ModalOverlay,
  useTheme,
} from "@chakra-ui/react";
import { useMentoria } from "../../../../context/MentoriaProvider/useMentoria";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { ICreateStepModal } from "..";
import { CustomModalOverlay } from "./CustomModalOverlay";
import { searchUser } from "../../../../context/AuthProvider/util";
import { IUser } from "../../../../context/AuthProvider/types";
import { FileUpload } from "../../../../components/uploadFile";
import { criarEtapaMentoria } from "../../../../context/MentoriaProvider/util";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { IMentores } from "../DTOs/mentor.dto";
import { timeMapping, minutesToIndex } from "../../../../utils/calculateHours";

export function CreateStepModal({
  isOpen,
  onClose,
  mentoria_id,
}: ICreateStepModal) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [newStepHours, setNewStepHours] = useState<number>(0);
  const [mentoresSelected, setMentoresSelected] = useState<IMentores[]>([]);
  const { payLoad } = useAuth();
  const [mentores, setMentores] = useState<{ id: string; name: string }[]>([]);
  const { ConsultaEtapaMentoria } = useMentoria();
  const toast = useToast();
  const [idEtapa, setIdEtapa] = useState("");
  const [startUpload, setStartUpload] = useState(false);
  const [mentorSelected, setMentorSelected] = useState("");
  const [statusUpload, setStatusUpload] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const theme = useTheme();
  const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;

  useEffect(() => {
    if (isOpen) {
      loadMentor();
    }
  }, [isOpen]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value, 10);
    const minutes = timeMapping[selectedValue];
    setNewStepHours(minutes);
  };

  const addMentor = () => {
    if (mentorSelected !== "" && newStepHours > 0) {
      const selectedMentor = mentores.find(
        (mentor) => mentor.id === mentorSelected
      );
      if (selectedMentor) {
        setMentoresSelected([
          ...mentoresSelected,
          { mentorId: selectedMentor.id, carga_horaria: newStepHours },
        ]);
        setMentorSelected("");
        setNewStepHours(0);
      }
    }
  };

  const removeMentor = (MentorId: string) => {
    setMentoresSelected(
      mentoresSelected.filter((mentor) => mentor.mentorId !== MentorId)
    );
  };

  const handleCreateStep = async () => {
    if (payLoad && payLoad.user && payLoad.user.mentoria_id) {
      setIsDisabled(true);
      const mentoria_id = payLoad.user.mentoria_id;
      var dataMentores: IMentores[]
      if (mentorSelected !== "" && newStepHours > 0){
        dataMentores = [...mentoresSelected, {
          mentorId: mentorSelected, carga_horaria: newStepHours
        }]
      }else{
        dataMentores = mentoresSelected
      }
      const data = await criarEtapaMentoria(
        title,
        description,
        mentoria_id,
        dataMentores
      );
      if (data && data.id) {
        const value: string = data.id;
        setIdEtapa(value);
        new Promise((resolve) => setTimeout(resolve, 500));
        setStartUpload(true);
      }
      if (!statusUpload) {
        onCancel();
      }
    }
  };

  const loadMentor = async () => {
    if (mentoria_id.length > 0) {
      try {
        const response = await searchUser({
          mentoria_id: mentoria_id,
          nome: "",
          pagina: 0,
          perfil: "mentor",
        });
        const prev = response.data.map((i: IUser) => ({
          id: i.id,
          name: i.nome,
        }));
        setMentores(prev);
      } catch (error: any) {
        toast({
          position: "top",
          description: "Error",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const onCancel = async () => {
    setTitle("");
    setMentorSelected("");
    setMentoresSelected([]);
    setDescription("");
    setIdEtapa("");
    await ConsultaEtapaMentoria(mentoria_id);
    onClose();
    setTimeout(async () => await ConsultaEtapaMentoria(mentoria_id), 750);

    setIsDisabled(false);
  };

  return (
    <Modal isOpen={isOpen} closeOnEsc isCentered size={"xl"} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Criar etapa</ModalHeader>
        <ModalCloseButton mt={2} />
        <Divider />
        <ModalBody w={"70%"} mx="auto" mt={2}>
          <Stack spacing={4}>
            <VStack alignItems={"left"} spacing={0}>
              <FormLabel
                fontSize={"sm"}
                fontWeight="bold"
                mb={1}
                htmlFor={"name"}
              >
                Digite o nome da nova etapa:
              </FormLabel>
              <Input
                id={"name"}
                bg="xmentor.background_template"
                type="text"
                fontSize={"sm"}
                color={"black"}
                placeholder="Escreva um nome para a etapa"
                value={title}
                onChange={handleNameChange}
              />
            </VStack>

            <VStack alignItems={"left"} spacing={0}>
              <FormLabel
                fontSize={"sm"}
                fontWeight="bold"
                mb={1}
                htmlFor={"description"}
              >
                Descrição
              </FormLabel>
              <Textarea
                id="description"
                bg="xmentor.background_template"
                fontSize={"sm"}
                color={"black"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Descrição da Etapa"
              />
            </VStack>

            <VStack alignItems={"left"} spacing={0}>
              <Flex
                alignItems={"center"}
                mb={2}
                justifyContent={"space-between"}
              >
                <FormLabel
                  fontSize={"sm"}
                  fontWeight="bold"
                  mb={1}
                  htmlFor={"addMentor"}
                >
                  Adicione mentores e horas
                </FormLabel>
                <IconButton
                  colorScheme="primary"
                  bgColor={"xmentor.primary"}
                  color={"white"}
                  icon={<FaPlus />}
                  aria-label="Add Mentor"
                  onClick={addMentor}
                />
              </Flex>
              <Select
                id="addMentor"
                bg="xmentor.background_template"
                fontSize={"sm"}
                color={"black"}
                placeholder="Adicionar mentores"
                value={mentorSelected}
                onChange={(e) => setMentorSelected(e.target.value)}
              >
                {mentores &&
                  mentores
                    .filter(
                      (itemPrincipal) =>
                        !mentoresSelected.some(
                          (itemExcluir) =>
                            itemExcluir.mentorId === itemPrincipal.id
                        )
                    )
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
              </Select>
              <Select
                mt={3}
                fontSize={"sm"}
                value={minutesToIndex(newStepHours)}
                onChange={handleHoursChange}
                bg={"xmentor.background_template"}
                placeholder="Selecione a quantidade de horas"
              >
                <option value={1}>15 Min</option>
                <option value={2}>30 Min</option>
                <option value={3}>1 Hora</option>
                <option value={4}>1 Hora e 30 Min</option>
                <option value={5}>2 Horas</option>
                <option value={6}>2 Horas e 30 Min</option>
                <option value={7}>3 Horas</option>
              </Select>

              <VStack alignItems={"left"} spacing={2} mt={5}>
                {mentoresSelected.map((mentor, index) => (
                  <Flex key={index} alignItems="center" width="100%">
                    <Input
                      isReadOnly
                      value={
                        mentores.find((m) => m.id === mentor.mentorId)?.name ||
                        ""
                      }
                      bg="xmentor.background_template"
                      fontSize={"sm"}
                      color={"black"}
                      mr={2}
                    />
                   <Select
                      mr={2}
                      fontSize={"sm"}
                      value={minutesToIndex(mentor.carga_horaria)}
                      onChange={(event) => {
                        const selectedValue = parseInt(event.target.value, 10);
                        const minutes = timeMapping[selectedValue];

                        setMentoresSelected((prev) =>
                          prev.map((item) =>
                            item.mentorId === mentor.mentorId
                              ? { ...item, carga_horaria: minutes }
                              : item
                          )
                        );
                      }}
                      bg={"xmentor.background_template"}
                      placeholder="Selecione a carga horária"
                    >
                      <option value={1}>15 Min</option>
                      <option value={2}>30 Min</option>
                      <option value={3}>1 Hora</option>
                      <option value={4}>1 Hora e 30 Min</option>
                      <option value={5}>2 Horas</option>
                      <option value={6}>2 Horas e 30 Min</option>
                      <option value={7}>3 Horas</option>
                    </Select>
                    <IconButton
                      icon={<FaRegTrashAlt />}
                      aria-label="Remove Mentor"
                      onClick={() => removeMentor(mentor.mentorId)}
                    />
                  </Flex>
                ))}
              </VStack>

              <VStack alignItems={"left"} spacing={2} mt={5}>
                <FormLabel
                  fontSize={"sm"}
                  fontWeight="bold"
                  mb={1}
                  htmlFor={"uploadFile"}
                >
                  Faça upload de um arquivo
                </FormLabel>
                <FileUpload
                  maxLenght={0}
                  status={setStatusUpload}
                  sendRequest={startUpload}
                  id={idEtapa}
                  clear={onCancel}
                />
              </VStack>
            </VStack>
          </Stack>
        </ModalBody>
        <Divider />
        <ModalFooter gap={6}>
          <Button
            fontSize={"sm"}
            px={7}
            variant="outline"
            size="lg"
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button
            fontSize={"sm"}
            px={8}
            size="lg"
            bg={"xmentor.primary"}
            textColor={"white"}
            onClick={handleCreateStep}
            isLoading={isDisabled}
            isDisabled={!title.trim()}
            _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
          >
            Criar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
