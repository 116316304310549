import React from "react";
import { Box, Text, Heading, Badge, VStack, HStack, Button, IconButton } from "@chakra-ui/react";
import { FaRegTrashAlt } from "react-icons/fa";
import { useAuth } from "../context/AuthProvider/useAuth";

type EventCardProps = {
  data: string;
  horario: string;
  titulo: string;
  descricao: string;
  onClick: () => void
};

const EventCard: React.FC<EventCardProps> = ({ data, horario, titulo, descricao, onClick }) => {
  const { payLoad } = useAuth()
  const dataFormatada = new Date(data).toLocaleDateString("pt-BR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const diaDaSemana = new Date(data).toLocaleDateString("pt-BR", {
    weekday: "long",
  });
  const mes = new Date(data).toLocaleDateString("pt-BR", {
    month: "long",
  });
  const dia = new Date(data).toLocaleDateString("pt-BR", {
    day: "numeric",
  });

  return (
    <Box
      borderWidth="1px"
      borderRadius="14px"
      borderColor={"xmentor.borderCards"}
      p={"16px"}
      bgColor="white"
      minW={'300px'}
      minH={"158px"}
      w={{ base: "100%", md: "calc(50% - 12px)", "2xl": "calc(33% - 12px)" }}
      position="relative"
    >
      <HStack spacing={"12px"} alignItems="flex-start">
        <VStack
          w={"72px"}
          h={"72px"}
          bgColor={"#F2F4FF"}
          borderRadius={"7px"}
          spacing={0}
          alignItems="center"
        >
          <Text fontSize="10px" color="xmentor.blueCards" fontWeight={400} mt="4px" mb="-6px">
            {mes}
          </Text>
          <Text fontSize="32px" color="xmentor.blueCards" fontWeight={700} mb="-7px">
            {dia}
          </Text>
          <Text fontSize="10px" color="xmentor.blueCards" fontWeight={400} mt="0px">
            {diaDaSemana}
          </Text>

        </VStack>
        <VStack align={"start"} spacing={"4px"} w="100%">
          <Heading fontSize="18px" fontWeight={"500"} color={"#1A181B"}>
            {titulo}   <Badge variant="outline" colorScheme="teal" p={1} ml={2}>
              {horario}
            </Badge>
          </Heading>
          <Text fontSize="15px" fontWeight={"500"} color={"xmentor.text_gray"}>
            {descricao}
          </Text>
        </VStack>
        {payLoad?.user.perfil === "auxiliar" || payLoad?.user.isAdmin &&
          <IconButton
            aria-label={""}
            size="sx"
            icon={<FaRegTrashAlt />}
            onClick={(event) => {
              event.stopPropagation(); // Impede a propagação do evento de clique
              onClick()
            }}
          />
        }
      </HStack>

      {/* <Button
        position="absolute"
        bottom="12px" 
        right="12px" 
        w={"115px"}
        variant={"outline"}
        fontSize={"14px"}
        h={"30px"}
        borderRadius={"15px"}
        borderColor={"xmentor.primary"}
        color={"xmentor.primary"}
      >
        Compartilhar
      </Button> */}
    </Box>
  );
};

export default EventCard;
