export function capitalizeFirstLetter(str: string) {
    // Divida a string em palavras usando espaço como delimitador
    let words = str.split(' ');

    // Para cada palavra, capitalize a primeira letra e mantenha o restante da palavra em minúsculas
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // Junte as palavras de volta em uma string e retorne o resultado
    return words.join(' ');
}
