import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IContext, IDataReordenarEtapasGestao, IEtapaMentoriaGestao, IMentoriaGestaoProvider } from "./types";
import { alterarEtapaMentoria, avancarEtapaMentoria, checkEtapa, consultaEtapaMentoria, criarEtapaMentoria, deletarEtapaMentoria, reordenarEtapasMentoria } from "./util";
import { useToast } from "@chakra-ui/react";


export const MentoriaGestaoContext = createContext<IContext>({} as IContext);

export const MentoriaGestaoProvider = ({ children }: IMentoriaGestaoProvider) => {

  const [etapasMentoria, setEtapasMentoria] = useState<IEtapaMentoriaGestao[]>([])

  function moverMentorado(idEtapaAtual: string, idMentorado: string, idEtapaDestino: string) {
    // Encontrar a etapa atual e a etapa de destino nos dados fornecidos
    const etapaAtualIndex = etapasMentoria.findIndex(etapa => etapa.id === idEtapaAtual);
    const etapaDestinoIndex = etapasMentoria.findIndex(etapa => etapa.id === idEtapaDestino);

    // Se as etapas existirem
    if (etapaAtualIndex !== -1 && etapaDestinoIndex !== -1) {
      // Encontrar o mentorado na etapa atual
      const mentoradoIndex = etapasMentoria[etapaAtualIndex].mentorados.findIndex(mentorado => mentorado.id === idMentorado);

      // Se o mentorado existir na etapa atual
      if (mentoradoIndex !== -1) {
        // Remover o mentorado da etapa atual e adicionar à etapa de destino
        const mentoradoRemovido = etapasMentoria[etapaAtualIndex].mentorados.splice(mentoradoIndex, 1)[0];
        etapasMentoria[etapaDestinoIndex].mentorados.push(mentoradoRemovido);
        // Atualizar o estado com as etapas modificadas
        setEtapasMentoria([...etapasMentoria]);
      } else {
        console.log("O mentorado não foi encontrado na etapa atual.");
      }
    } else {
      console.log("As etapas fornecidas não foram encontradas.");
    }
  }

  const toast = useToast();
  const showSuccessToast = (description: string) => {

    toast({
      position: 'top',
      description,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const showErrorToast = (error: any) => {
    toast({
      position: 'top',
      description: error.response?.data?.message || error || "Error",
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const ConsultaEtapaMentoria = async (mentoria_id: string) => {
    try {
      const response = await consultaEtapaMentoria(mentoria_id)
      setEtapasMentoria(response)
    } catch (error) {
      showErrorToast(error);
    }
  }

  const CriarEtapaMentoria = async (titulo: string, mentoria_id: string, checkList: string[]) => {
    try {
      await criarEtapaMentoria(titulo, mentoria_id, checkList)
    } catch (error) {
      showErrorToast(error);
    } finally {
      new Promise(async resolve => await ConsultaEtapaMentoria(mentoria_id))
      setTimeout(async () => await ConsultaEtapaMentoria(mentoria_id), 750)
    }
  }

  const AvancarEtapaMentoria = async (etapa_atual_id: string, mentoria_id: string, etapa_id: string, usuario_id: string) => {
    try {
      moverMentorado(etapa_atual_id, usuario_id, etapa_id)
      const response = await avancarEtapaMentoria(mentoria_id, etapa_id, usuario_id)
      // setEtapasMentoria(() => response)

      console.log(etapasMentoria)
    } catch (error) {
      showErrorToast(error);
    }
  }

  const DeletarEtapaMentoria = async (mentoria_id: string, etapa_id: string) => {
    try {
      const response = await deletarEtapaMentoria(etapa_id)
      if (response&&etapasMentoria && etapasMentoria.length > 1) {
        let novasEtapas: IEtapaMentoriaGestao[] = etapasMentoria.filter(i => i.id !== etapa_id);
        setEtapasMentoria(() => novasEtapas);
      }
    } catch (error) {
      showErrorToast(error);
    }
  }

  const AlterarEtapaMentoria = async (mentoria_id: string, etapa_id: string, titulo: string) => {
    try {
      const response = await alterarEtapaMentoria(etapa_id, titulo)
      setEtapasMentoria(() => response)
    } catch (error) {
      showErrorToast(error);
    }
  }

  const CheckEtapa = async (
    usuario_id: string,
    mentoria_id: string,
    etapa_id: string,
    ckeck_list_item_id: string
  ) => {
    try {
      const response = await checkEtapa(
        usuario_id,
        mentoria_id,
        etapa_id,
        ckeck_list_item_id
      )
      setEtapasMentoria(() => response)
    } catch (error) {
      showErrorToast(error);
    }
  }


  const ReordenarEtapasMentoria = async (
    data: IDataReordenarEtapasGestao, etapasState: IEtapaMentoriaGestao[]
  ) => {
    try {
      setEtapasMentoria(() => etapasState);
      await reordenarEtapasMentoria(data);
    } catch (error) {
      showErrorToast(error);
    }
  };
  return (
    <MentoriaGestaoContext.Provider value={{
      ConsultaEtapaMentoria,
      CriarEtapaMentoria,
      AvancarEtapaMentoria,
      DeletarEtapaMentoria,
      AlterarEtapaMentoria,
      etapasMentoria,
      CheckEtapa,
      ReordenarEtapasMentoria
    }}>
      {children}
    </MentoriaGestaoContext.Provider>
  );
};