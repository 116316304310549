import React, { useEffect } from 'react';
import {
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { TemplateMentorado } from '../../../components/layout/mentorado/TemplateMentorado';
import EventoCardMentorado from '../../../components/EventoCardMentorado';
import { useEvento } from '../../../context/EventoProvider/useEvento';
import { useAuth } from '../../../context/AuthProvider/useAuth';
import { useNavigate } from 'react-router-dom';
import { EventoProvider } from '../../../context/EventoProvider';
import { formatarHora } from '../../../utils/formatarData';


const MentoradoProgramacaoChildren: React.FC = () => {
  const {ConsultaEvento, eventos} = useEvento()
    const {payLoad} = useAuth()
    const navigate = useNavigate()
    useEffect(() => {
        carregarEventos()
      }, []);
    
      const carregarEventos = async () => {
        try {
          await ConsultaEvento(payLoad?.user.mentoria_id|| " ");
        } catch (error) {
          console.error("Erro ao carregar eventos:", error);
          // Adicione a lógica necessária para lidar com o erro, se necessário.
        }
      };
  return (
    <TemplateMentorado>
      <VStack align="start">
        <Heading fontSize={24} my="24px" fontWeight={700}>
          Eventos
        </Heading>
        <Stack
          spacing={"24px"} gap={"24px"}
          flexWrap={"wrap"} direction={['column', 'row']} borderRadius={14} w="100%" 
        >
          {eventos.length > 0 &&
                eventos.map((evento, index) => (
                    <EventoCardMentorado
                        key={index}
                        data={evento.data_evento || ""}
                        horario={formatarHora(evento.data_evento)|| ""}
                        titulo={evento.nome || ""}
                        descricao={String(evento.duracao || "0")+" min, "+evento.descricao_instrucoes}
                        onClick={()=>{}}
                    />
                ))}
        </Stack>
      </VStack>
    </TemplateMentorado>
  );
};

export default function MentoradoProgramacao(){
  return(
      <EventoProvider>
          <MentoradoProgramacaoChildren />
      </EventoProvider>
  )
}
