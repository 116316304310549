import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  IconButton,
  Icon,
  useToast,
  Stack,
  Input as ChakraInput,
  VStack,
  Text,
  InputProps,
} from "@chakra-ui/react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiCamera } from "react-icons/fi";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { TemplateMaster } from "../../../../components/layout/master/TemplateMaster";

import { BodyContent } from "../../../../components/content/body";
import { MasterProvider } from "../../../../context/MasterProvider";
import { useMaster } from "../../../../context/MasterProvider/useMentoria";
import { Input } from "../../../../components/Form/Input";

interface FormValues {
  nome_mentoria: string;
  descricao_mentoria: string;
  nome_administrador: string;
  email_administrador: string;
  senha_administrador: string;
}

interface Props extends InputProps {}

interface IMentoriaProps{
  id: string
  nome: string
  descricao: string
  administradores: string
  mentores:string
  mentorados: number
  criadoEm: string
  foto: string
  perfil: string
}
function UpdateMentoriaChildren() {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate()
  const resultRoute: IMentoriaProps = location.state || {
    id: "",
    nome: "",
    descricao: "",
    administradores: "",
    mentores:"",
    mentorados: 0,
    criadoEm: "",
    foto: "",
    perfil: "",
  }
  
  const { AtualizarMentoria, AtualizarFotoMentoria } = useMaster();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(`${process.env.REACT_APP_API}/tmp/mentoria/${resultRoute.foto}`||null);
  const [file, setFile] = useState<File | null>(null);

  const [inputData, setInputData] = useState({
    nome_mentoria: resultRoute.nome||"",
    descricao_mentoria: resultRoute.descricao|| "",
  });

  const inputFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!file) {
      return;
    }
    const maxSize = 5 * 1024 * 1024; // Define o tamanho máximo permitido (5 MB)
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    if (!allowedFileTypes.includes(file.type)) {
      toast({
        position: "top",
        description: "Tipo de arquivo inválido. São permitidos apenas arquivos JPEG, PNG e GIF.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (file.size > maxSize) {
      toast({
        position: "top",
        description: "Envie um arquivo menor que 5MB",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result as string);
        setTimeout(()=>handleUpdatePhoto(),1500)
      };
    }
  }, [file, toast]);

  const handleRemove = () => {
    setFile(null);
    setPreviewUrl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files ? event.target.files[0] : null);
    
    
  };

  const handleOpenFileClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleInputChange = (field: keyof FormValues, value: string) => {
    setInputData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleUpdatePhoto =  () => {
    
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }

    try {
      setIsSubmitting(true);
      // Replace the RegisterUser API call with your logic for mentorship registration
      new Promise(resolve=>AtualizarFotoMentoria(resultRoute.id,formData))      
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error in handleRegister:", err);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    

    try {
      setIsSubmitting(true);
      // Replace the RegisterUser API call with your logic for mentorship registration
      new Promise(resolve=>AtualizarMentoria(
        resultRoute.id,
        inputData.nome_mentoria,
        inputData.descricao_mentoria        
      ))
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error in handleRegister:", err);
    }
  };
  return (
    <TemplateMaster>
      <VStack spacing={4} align={"flex-start"} mb={4} w={"100%"}>
        <Text
          fontSize="xl"
          fontWeight={700}
          fontStyle="normal"
          lineHeight="normal"
          letterSpacing={"-0.227px"}
          mb={"60px"}
        >
          Master
        </Text>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <BodyContent>
            <Stack spacing="4" display="flex" w={"100%"}>
              <Box w={"100%"}>
                {previewUrl ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Center>
                      <Box position="relative">
                        <Avatar src={previewUrl} minH={[32, 64]} minW={[32, 64]} />
                        <IconButton
                          rounded={"full"}
                          position="absolute"
                          colorScheme="red"
                          aria-label="Remove File"
                          size="80px"
                          p={5}
                          right={2}
                          bottom={0}
                          zIndex={100}
                          icon={<Icon as={RiDeleteBin5Line} fontSize="32" />}
                          borderWidth={5}
                          borderColor="#FFF"
                          onClick={handleRemove}
                        />
                      </Box>
                    </Center>
                  </motion.div>
                ) : (
                  <Center>
                    <Avatar minH={[32, 64]} minW={[32, 64]}>
                      <ChakraInput
                        ref={inputFileRef} // Adiciona a referência ao input file
                        required
                        type="file"
                        accept=".jpg,.jpeg,.webp,.png"
                        onChange={handleChange}
                        minH={36}
                        position="absolute"
                        width="100%"
                        height="100%"
                        cursor="pointer"
                        opacity={0}
                        onDragOver={() => setIsDragging(true)}
                        onDragLeave={() => setIsDragging(false)}
                      />
                      <IconButton
                        id="OpenFile"
                        rounded={"full"}
                        position="absolute"
                        colorScheme="blue"
                        bg="xmentor.primary"
                        aria-label="Open File"
                        size="80px"
                        p={5}
                        right={2}
                        bottom={0}
                        zIndex={100}
                        icon={<Icon as={FiCamera} fontSize="32" />}
                        borderWidth={5}
                        borderColor="#FFF"
                        onClick={handleOpenFileClick}
                      />
                    </Avatar>
                  </Center>
                )}
              </Box>

              <Input
                required
                type="text"
                label="Nome da mentoria"
                name="nome_mentoria"
                value={inputData.nome_mentoria}
                onChange={(e) => handleInputChange("nome_mentoria", e.target.value)}
              />
              <Input
                required
                type="text"
                label="Descrição da mentoria"
                name="descricao_mentoria"
                value={inputData.descricao_mentoria}
                onChange={(e) => handleInputChange("descricao_mentoria", e.target.value)}
              />              

              <Stack direction="row" spacing={4} mt={4} justifyContent="flex-end">
                <Button variant="outline" size="lg" onClick={()=>navigate("/master/home")}>
                  Voltar
                </Button>
                <Button type="submit" colorScheme="blue" size="lg" isLoading={isSubmitting}>
                  Salvar
                </Button>
              </Stack>
            </Stack>
          </BodyContent>
        </form>
      </VStack>
    </TemplateMaster>
  );
}


export function UpdateMentoria() {
  return (
    <MasterProvider>
      <UpdateMentoriaChildren />
    </MasterProvider>
  );
}
