import React, { useState } from "react";
import { Box, Text, Avatar, VStack, HStack, IconButton, Flex, Button } from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";



interface Idata {
  data: {
    id: string
    foto: string
    nome: string
  }[]
}
const MentoresSection: React.FC<Idata> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const paginatedMentores = data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const navigate = useNavigate()
  return (
    <Box
      bg="white"
      borderRadius="24px"
      p={6}
      boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      flex="1"

      w={{ base: "100%", md: "100%", lg: "auto" }}

    >
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Mentores
      </Text>
      <VStack spacing={4} align="stretch">
        {paginatedMentores && paginatedMentores.map((mentor, index) => (
          <HStack
            key={index}
            p={4}
            bg="white"
            borderRadius="12px"
            boxShadow="sm"
            _hover={{ backgroundColor: "blue.50" }}
            cursor="pointer"
            transition={'0.3s all'}
            onClick={() => {
              navigate("/admin/users/mentor", {
                state: {
                  id: mentor.id,
                  nome: mentor.nome,
                  email: "",
                  foto: mentor.foto,
                  perfil: "Mentor",
                },
              });
            }}
          >
            <Avatar src={`${process.env.REACT_APP_API}/tmp/user/${mentor.foto}`} />
            <Box flex="1">
              <Text fontWeight="bold">{mentor.nome}</Text>
              {/* <Text fontSize="sm" color="gray.500">
                {mentor.role}
              </Text> */}
            </Box>
            {/* <IconButton
              aria-label="Options"
              icon={<FiMoreVertical />}
              variant="ghost"
            /> */}
          </HStack>
        ))}
      </VStack>
      <Flex justify="space-between" mt={4}>
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          isDisabled={currentPage === 0}
          variant="outline"
        >
          Anterior
        </Button>
        <Text>Página {currentPage + 1} de {Math.ceil(data.length / itemsPerPage)}</Text>
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          isDisabled={(currentPage + 1) * itemsPerPage >= data.length}
          variant="outline"
        >
          Próximo
        </Button>
      </Flex>
    </Box>
  );
};

export default MentoresSection;
