import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Center,
  Box,
  Divider,
} from "@chakra-ui/react";
import { UploadEditAvatar } from ".";

interface ImageEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  imagePreview: string | null;
  handleSave: (file: File) => void;
}

const ImageEditModal: React.FC<ImageEditModalProps> = ({
  isOpen,
  onClose,
  imagePreview,
  handleSave,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Imagem</ModalHeader>
        <ModalCloseButton mt={2} />
        <Divider />
        <ModalBody>
          <Center py={4}>
            <Box w="300px">
              <UploadEditAvatar
                imagePreview={imagePreview}
                handleFile={handleSave}
              />
            </Box>
          </Center>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button
            fontSize={"sm"}
            px={7}
            variant="outline"
            size="md"
            onClick={onClose}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImageEditModal;
