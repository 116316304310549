import { Button, Flex, Link, Stack, Text, useToast } from "@chakra-ui/react";
import logo from "../assets/logo.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { Input } from "../components/Form/Input";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider/useAuth";
import Logo from "../components/loading";
import isAuthenticated, { auth } from "../context/AuthProvider/util";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";

type SignInFormData = {
  email: string;
  password: string;
}

export default function SignIn() {
  const navigate = useNavigate();
  const { Auth, payLoad } = useAuth(); // Make sure the useAuth hook is correctly imported
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast()
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const initialValues: SignInFormData = {
    email: "",
    password: "",
  };

  const schema = yup.object().shape({
    email: yup.string().required('E-mail obrigatório').email('Insira um e-mail válido'),
    password: yup.string().required('Senha obrigatória'),
  });

  const { 
    register, 
    handleSubmit, 
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const handleCaptchaChange = (value: string | null) => {
    if (value) {
      setIsCaptchaVerified(true);
    } else {
      setIsCaptchaVerified(false);
    }
  };
  useEffect(()=>{
    if(isAuthenticated()){
      const result = JSON.parse(Cookies.get("@xmentor-user-data")||'null')
      
      // navigate('/')
      if(result.user.perfil == "mentorado"){
        navigate('/mentorado/home');
        return
      }
      if(result.user.perfil == "auxiliar"||(result.user.perfil == "mentor")){
        navigate('/admin/home');
        return
      }
      // if(result.user.perfil == "mentor"){
      //   navigate('/mentor/home');
      //   return
      // }
      if(result.user.perfil == "master"){
        navigate('/master/home');
        return
      }
    }
  },[])
  const handleSignIn: SubmitHandler<SignInFormData> = async (values) => {
    try {
      setIsSubmitting(true);
      
      const response = await auth(values.email, values.password);
      Auth(response)      
      reset(initialValues);
    } catch (err) {
      setIsSubmitting(false);
      toast({
        title: "Erro ao fazer login",
        description: "Verifique suas credenciais e tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // setTimeout(()=>window.location.href = "/",2500)
       // Reset ReCAPTCHA upon authentication failure
       if (recaptchaRef.current) {
        setIsCaptchaVerified(false)
        recaptchaRef.current.reset();
      }
    }
  };

  return (
    <Flex
      w="100vw"
      h="100vh"
      align="center"
      justify="center"
    >
        
       {!isSubmitting? <Flex
          w="100%"
          maxW={360}
          p="8"
          borderRadius={8}
          flexDir="column"
        >
        <form onSubmit={handleSubmit(handleSignIn)}>
          <Flex justifyContent="center" mb={4}>
            <img height={300} width={220} src={logo} alt="XMENTOR" />
          </Flex>
          <Text textAlign="center" mt={8} mb={8}>
            Logue na sua conta
          </Text>
          <Stack spacing="4">
            <Input
              type="email"
              id="email"
              label="E-mail"
              {...register('email')}
              error={errors.email}
              onChange={(e) => setValue('email', e.target.value, { shouldValidate: true })}
            />

            <Input
              type="password"
              id="password"
              label="Senha"
              error={errors.password}
              {...register('password', { required: 'Senha obrigatória', minLength: 4 })}
              onChange={(e) => setValue('password', e.target.value, { shouldValidate: true })}
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={`${process.env.REACT_APP_RECAPTCHA}`}
              onChange={handleCaptchaChange}
              hl="pt-BR" 
            />
            <Button
              type="submit"
              mt="6"
              colorScheme="blue"
              size="lg"
              isLoading={isSubmitting}
              isDisabled={!isCaptchaVerified}
            >
              Entrar
            </Button>

            <Link as={NavLink} to="/reset-password">Esqueci minha senha</Link>
          </Stack>

      </form>
        </Flex>
      :
      <Logo />  
      }
    </Flex>
  );
}
