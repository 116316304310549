import React, { useEffect } from "react";
import {
  Badge,
  Box,
  Button,
  HStack,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";

import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";

import { TemplateMaster } from "../../../components/layout/master/TemplateMaster";
import { MasterProvider } from "../../../context/MasterProvider";
import { useMaster } from "../../../context/MasterProvider/useMentoria";
import { converterData, formatarData } from "../../../utils/formatarData";

function MasterHomeChildren() {
  const { mentorias, ConsultaMentoria } = useMaster()
  const navigate = useNavigate();

  useEffect(() => {
    ConsultaMentoria("", 0)
  }, [])

  return (
    <TemplateMaster>
      <VStack spacing={4} align={"flex-start"} mb={4}>
        <Text
          fontSize="xl"
          fontWeight={700}
          fontStyle="normal"
          lineHeight="normal"
          letterSpacing={"-0.227px"}
          mb={"60px"}
        >
          Master
        </Text>
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Text
            fontSize="xl"
            fontWeight={700}
            fontStyle="normal"
            lineHeight="normal"
            letterSpacing={"-0.227px"}
          >
            Mentorias
          </Text>
          <Button
            type="submit"
            colorScheme="blue"
            size="md"
            bg="xmentor.primary"
            leftIcon={<GoPlus />}
            onClick={() =>
              navigate("/master/register", {
                state: { perfil: "master" },
              })
            }
          >
            Nova mentoria
          </Button>
        </HStack>
      </VStack>

      <Box bg="#FFF" borderRadius="8px" border="1px solid #EAECF0">
        <Table variant="simple" w="full">
          <Thead>
            <Tr justifyContent="space-between">
              <Th>Nome</Th>
              <Th>Mentor/Gestor</Th>
              <Th>Auxiliares</Th>
              <Th>Mentores</Th>
              <Th>Mentorados</Th>
              <Th>Criado em</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {mentorias.map((mentoria) => (
              <Tr key={mentoria.id} justifyContent="space-between">
                <Td>
                  <HStack spacing={4} alignItems="center">
                    <Image
                      src={`${process.env.REACT_APP_API}/tmp/mentoria/${mentoria.foto}`}
                      alt={`${mentoria.nome} Image`}
                      boxSize="50px"
                      objectFit="cover"
                      borderRadius="full"
                    />
                    <VStack display="flex" align="flex-start">
                      <Text>{mentoria.nome}</Text>
                      <Badge>{mentoria.tipo_mentoria?.nome}</Badge>
                    </VStack>
                  </HStack>
                </Td>
                <Td>
                  {mentoria.membros && Array.isArray(mentoria.membros) ? (
                    mentoria.membros.filter((membro) => membro.isAdmin).map((membro) => membro.nome).join(", ")
                  ) : (
                    "Nenhum"
                  )}
                </Td>
                <Td>{mentoria.membros.filter((i) => i.perfil == "auxiliar").length}</Td>
                <Td>{mentoria.membros.filter((i) => i.perfil == "mentor").length}</Td>
                <Td>{mentoria.membros.filter((i) => i.perfil == "mentorado").length}</Td>
                <Td>{converterData(mentoria.data_cadastro)}</Td>
                <Td color="blue.400" textAlign="right" fontWeight="bold" cursor="pointer"
                  onClick={() => navigate("/master/mentoria/view", {
                    state: {
                      id: mentoria.id,
                      nome: mentoria.nome,
                      descricao: mentoria.descricao,
                      administradores: mentoria.membros && Array.isArray(mentoria.membros) ? (
                        mentoria.membros.filter((membro) => membro.perfil == "admin").map((membro) => membro.nome).join(", ")
                      ) : (
                        "Nenhum"
                      ),
                      mentores: mentoria.membros && Array.isArray(mentoria.membros) ? (
                        mentoria.membros.filter((membro) => membro.perfil == "mentor").map((membro) => membro.nome).join(", ")
                      ) : (
                        "Nenhum"
                      ),
                      mentorados: mentoria.membros.filter((i) => i.perfil == "mentorado").length,
                      criadoEm: converterData(mentoria.data_cadastro),
                      foto: mentoria.foto,
                      perfil: "master",
                    }
                  })}
                >
                  Ver
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </TemplateMaster>
  );
}


export function MasterHome() {
  return (
    <MasterProvider>
      <MasterHomeChildren />
    </MasterProvider>
  )
}