import { HStack, Text, Image, Button } from "@chakra-ui/react";

interface Props {
    image: string
    mentor?: string
    onClick?: () => void
}

export function CardMentor({ image, mentor, onClick }: Props) {

    return (
        <HStack
            justifyContent="space-between"
            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.08)"
            rounded={10}
            cursor={"pointer"}
            bg="xmentor.background_content"
            pr={2}
            maxW={'350px'}
            h={"87px"}
            transition={"0.5s"}
            _hover={{
                backgroundColor: "gray.100",
            }}
            onClick={onClick}
        >
            <Image
                rounded={10}
                src={`${process.env.REACT_APP_API}/tmp/user/${image}`}
                w={"86px"}
                h={"86px"}
                objectFit="cover"
            />
            <Text
                color="xmentor.text_black"
                fontStyle="normal"
                fontWeight={600}
                lineHeight="14px"
                w={"160px"}
                letterSpacing='0.75px'
                textAlign="center"
            >
                {mentor}
            </Text>
            <Button onClick={onClick} variant={'unstyled'}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1255_17700)">
                        <path d="M12 24C5.3835 24 0 18.6165 0 12C0 5.3835 5.3835 0 12 0C18.6165 0 24 5.3835 24 12C24 18.6165 18.6165 24 12 24ZM12 1.5C6.21 1.5 1.5 6.21 1.5 12C1.5 17.79 6.21 22.5 12 22.5C17.79 22.5 22.5 17.79 22.5 12C22.5 6.21 17.79 1.5 12 1.5ZM17.9422 12.2865C17.904 12.3787 17.8492 12.4613 17.7795 12.531L14.7803 15.5303C14.634 15.6765 14.442 15.75 14.25 15.75C14.058 15.75 13.866 15.6765 13.7197 15.5303C13.4265 15.237 13.4265 14.763 13.7197 14.4697L15.4395 12.75H6.75C6.336 12.75 6 12.414 6 12C6 11.586 6.336 11.25 6.75 11.25H15.4395L13.7197 9.53025C13.4265 9.237 13.4265 8.763 13.7197 8.46975C14.013 8.1765 14.487 8.1765 14.7803 8.46975L17.7795 11.469C17.8492 11.5387 17.904 11.6213 17.9422 11.7135C18.018 11.8965 18.018 12.1035 17.9422 12.2865Z" fill="#4963FF" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1255_17700">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </Button>

        </HStack>

    )
}