import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  useToast,
  Stack,
  Input as ChakraInput,
  VStack,
  Text,
  InputProps,
  Select,
  FormLabel,
  Divider,
  Box,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { TemplateMaster } from "../../../../components/layout/master/TemplateMaster";

import { BodyContent } from "../../../../components/content/body";
import { MasterProvider } from "../../../../context/MasterProvider";
import { useMaster } from "../../../../context/MasterProvider/useMentoria";
import { Input } from "../../../../components/Form/Input";
import { UploadEditAvatar } from "../../../../components/avatarUpload";

interface FormValues {
  nome_mentoria: string;
  descricao_mentoria: string;
  nome_administrador: string;
  email_administrador: string;
  tipo_mentoria_id: string
}

interface Props extends InputProps { }

function RegisterMentoriaChildren({ ...rest }: Props) {
  const toast = useToast();

  const { CriarMentoria, ConsultaTipoMentoria, tipoMentoria } = useMaster();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const [inputData, setInputData] = useState<FormValues>({
    nome_mentoria: "",
    descricao_mentoria: "",
    nome_administrador: "",
    email_administrador: "",
    tipo_mentoria_id: "",
  });


  useEffect(() => { ConsultaTipoMentoria() }, [])
  useEffect(() => {
    if (!file) {
      return;
    }
    const maxSize = 5 * 1024 * 1024; // Define o tamanho máximo permitido (5 MB)
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    if (!allowedFileTypes.includes(file.type)) {
      toast({
        position: "top",
        description: "Tipo de arquivo inválido. São permitidos apenas arquivos JPEG, PNG e GIF.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (file.size > maxSize) {
      toast({
        position: "top",
        description: "Envie um arquivo menor que 5MB",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result as string);
      };
    }
  }, [file, toast]);



  const handleInputChange = (field: keyof FormValues, value: string) => {
    setInputData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("nome_mentoria", inputData.nome_mentoria);
    formData.append("descricao_mentoria", inputData.descricao_mentoria);

    formData.append("nome_administrador", inputData.nome_administrador);
    formData.append("email_administrador", inputData.email_administrador);
    formData.append("tipo_mentoria_id", inputData.tipo_mentoria_id)

    try {
      setIsSubmitting(true);
      // Replace the RegisterUser API call with your logic for mentorship registration
      await CriarMentoria(formData);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error in handleRegister:", err);
    }
  };


  return (
    <TemplateMaster>
      <VStack spacing={4} align={"flex-start"} mb={4} w={"100%"}>
        <Text
          fontSize="xl"
          fontWeight={700}
          fontStyle="normal"
          lineHeight="normal"
          letterSpacing={"-0.227px"}
          mb={"60px"}
        >
          Master
        </Text>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <BodyContent>
            <Stack spacing="4" display="flex" w={"100%"}>
              <Box>
                <Text>Insira a imagem da mentoria</Text>
                <UploadEditAvatar
                  handleFile={setFile}
                  imagePreview={previewUrl}
                />
              </Box>
              <Divider />
              <Input
                required
                type="text"
                label="Nome da mentoria"
                name="nome_mentoria"
                value={inputData.nome_mentoria}
                onChange={(e) => handleInputChange("nome_mentoria", e.target.value)}
              />
              <Input
                required
                type="text"
                label="Descrição da mentoria"
                name="descricao_mentoria"
                value={inputData.descricao_mentoria}
                onChange={(e) => handleInputChange("descricao_mentoria", e.target.value)}
              />

              <FormLabel >Tipo de mentoria</FormLabel>
              <Select
                required
                placeholder="Selecione o tipo da mentoria"
                value={inputData.tipo_mentoria_id}
                onChange={(e) => handleInputChange("tipo_mentoria_id", e.target.value)}
              >
                {tipoMentoria.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nome} - {item.qtd_mentores} mentores e {item.qtd_auxiliares || 0} auxiliares - ${item.valor}
                  </option>
                ))}
              </Select>
              <Input
                required
                type="text"
                label="Nome da administrador"
                name="nome_administrador"
                value={inputData.nome_administrador}
                onChange={(e) => handleInputChange("nome_administrador", e.target.value)}
              />
              <Input
                required
                type="email"
                name="email_administrador"
                label="E-mail do administrador"
                value={inputData.email_administrador}
                onChange={(e) => handleInputChange("email_administrador", e.target.value)}
              />
              <Stack direction="row" spacing={4} mt={4} justifyContent="flex-end">
                <Button variant="outline" size="lg" type="reset">
                  Cancelar
                </Button>
                <Button type="submit" colorScheme="blue" size="lg" isLoading={isSubmitting}>
                  Continuar
                </Button>
              </Stack>
            </Stack>
          </BodyContent>
        </form>
      </VStack>
    </TemplateMaster>
  );
}


export function RegisterMentoria() {
  return (
    <MasterProvider>
      <RegisterMentoriaChildren />
    </MasterProvider>
  );
}
