import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: FieldError;
  type?: string;
  required?: boolean;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({
  name,
  required,
  label,
  error = null,
  type,
  ...rest
}) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel fontSize={"sm"} fontWeight="bold" mb={1} htmlFor={name}>
          {label}
          {required && (
            <Text display={"inline-block"} color="red">
              *
            </Text>
          )}
        </FormLabel>
      )}
      <ChakraInput
        required={required}
        name={name}
        id={name}
        bg="xmentor.background_template"
        fontSize={"sm"}
        color={"black"}
        type={type}
        maxLength={100}
        rounded={10}
        {...rest}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
