import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    HStack,
    Input,
    Stack,
    Text,
    VStack,
    useDisclosure,
    useTheme,
    useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { TemplateAdmin } from "../../../../components/layout/admin/TemplateAdmin";
import { IoIosArrowRoundBack } from "react-icons/io";
import { ImgUploadConfig } from "../../../../components/ImgUploadConfig";
import ColorPicker from "../../../../components/ColorPicker";
import { useMaster } from "../../../../context/MasterProvider/useMentoria";
import { MasterProvider } from "../../../../context/MasterProvider";


function ConfiguracaoMentoriaChildren() {
    const { isOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const toast = useToast();
    const { payLoad, updateConfigMentoria } = useAuth();
    const { AtualizarConfigMentoria, CriarConfigMentoria, ConsultaTipoConfigMentoria } = useMaster(); // Obtenha a função do contexto
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [nome, setNome] = useState(""); // Estado para armazenar o nome
    const [cor, setCor] = useState("#071872"); // Estado para armazenar a cor selecionada
    const [isDisableInputs, setIsDisableInputs] = useState(false)
    const theme = useTheme();
    const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;


    // useEffect para buscar dados da configuração da mentoria
    useEffect(() => {
        const fetchConfigMentoria = async () => {
            if (!payLoad?.user.mentoria_id) return;
    
            try {
                const config = await ConsultaTipoConfigMentoria(payLoad.user.mentoria_id);
                if (config) {
                    setNome(config.nome || "");
                    setCor(config.cor || "#071872"); // Define a cor inicial
                    setPreviewUrl(config.foto ? `${process.env.REACT_APP_API}/tmp/mentoria/${config.foto}` : null);
                }
            } catch (error) {
                toast({
                    title: "Erro",
                    description: "Erro ao buscar configurações da mentoria.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        };
    
        fetchConfigMentoria();
    }, [payLoad?.user.mentoria_id, ConsultaTipoConfigMentoria, toast]);
    
  
    const HandleSubmit = async () => {
        setIsSubmitting(true);
        
        try {
            if (!file) {
                // Não há novo arquivo, chama a função para atualizar apenas nome e cor
                if (payLoad?.user.mentoria_id) { 
                    const response = await AtualizarConfigMentoria(payLoad.user.mentoria_id, nome, cor);
                    updateConfigMentoria(response);
                    // showSuccessToast("Atualizado com sucesso!");
                    setIsDisableInputs(true);
                }
            } else {
                // Há um novo arquivo, realiza o upload junto com nome e cor
                const formData = new FormData();
                formData.append("file", file);
                formData.append("nome", nome);
                formData.append("cor", cor);
                if (payLoad?.user.mentoria_id) {
                    formData.append("id", payLoad?.user.mentoria_id);
                }
    
                const newConfig = await CriarConfigMentoria(formData); // Chama a função do contexto para salvar
                updateConfigMentoria(newConfig);
                setIsDisableInputs(true);
                // showSuccessToast("Configurações da mentoria salvas com sucesso!");
            }
        } catch (error) {
            toast({
                title: "Erro",
                description: "Erro ao salvar as configurações da mentoria.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
            window.location.reload()
        }
    };

    useEffect(() => {
        if (!file) {
            return;
        }
        const maxSize = 5 * 1024 * 1024; // Define o tamanho máximo permitido (5 MB)
        const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

        if (!allowedFileTypes.includes(file.type)) {
            toast({
                position: "top",
                description: "Tipo de arquivo inválido. São permitidos apenas arquivos JPEG, PNG e GIF.",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
            setFile(null);
        } else if (file.size > maxSize) {
            toast({
                position: "top",
                description: "Envie um arquivo menor que 5MB",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
            setFile(null);
        } else {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                setPreviewUrl(fileReader.result as string);
            };
        }
    }, [file, toast]);

    return (
        <TemplateAdmin>
            <Container h="full" maxW="container.lg" color="#262626">
                <HStack display="flex" w="100%" justifyContent="space-between" mb={2}>
                    <Text fontSize="xl" fontWeight={700}>
                        Configuração Mentoria
                    </Text>
                    <Button
                        px={0}
                        variant={"ghost"}
                        leftIcon={<IoIosArrowRoundBack size={30} />}
                        onClick={() => navigate(-1)}
                    >
                        Voltar
                    </Button>
                </HStack>

                <Box bg="#FFF" borderRadius="8px" border="1px solid #EAECF0" p={6}>
                    <VStack align="flex-start" w="full" spacing={4}>
                        <HStack>
                            <Text w="100%" fontWeight="bold">
                                Logo da mentoria
                            </Text>

                        </HStack>

                        <Stack w="100%" spacing={6} direction={{ base: "column", md: "row" }} alignItems={"flex-start"}>
                            {/* Componente de upload de imagem */}
                            <Box 
                                as="label" 
                                htmlFor="upload-image" 
                                w="100%" 
                                maxW="400px" 
                                border="1px solid #EAECF0" 
                                borderRadius="8px" 
                                overflow="hidden" 
                                aspectRatio={1}
                            >
                                {/* <Input type="file" id="upload-image" display="none" /> */}
                                <VStack id="avatar" spacing={0}>
                                    <ImgUploadConfig 
                                        handleFile={setFile} 
                                        imagePreview={previewUrl} 
                                    />
                                </VStack>
                            </Box>

                            {/* Texto alinhado ao topo */}
                            <Box h="full" flex="1">
                                <Text fontSize="sm">
                                    O logotipo é exibido no topo da tela onde os mentorados têm acesso. É importante lembrar que o logo é melhor visualizado se possuir uma versão horizontal e fundo transparente.
                                </Text>
                                <Text fontSize="sm">
                                    <b>Medidas:</b> 1080px de altura e 1080px largura
                                </Text>
                                <Text fontSize="sm">
                                    <b>Formato:</b> .png
                                </Text>
                                <Text fontSize="sm">
                                    <b>Tamanho Máximo:</b> 100 KB
                                </Text>
                            </Box>
                        </Stack>

                        <HStack w="100%" spacing={6} justifyContent={"space-between"}>
                            <Stack spacing={2} w="full">
                                <Text fontSize="sm" fontWeight="bold" color="black">
                                    Nome
                                </Text>
                                <Input
                                    name="name"
                                    type="text"
                                    fontSize={"sm"}
                                    bg={"xmentor.background_template"}
                                    rounded={"md"}
                                    placeholder="Insira o nome"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                />
                            </Stack>
                            <Stack spacing={2} w="full">
                                <ColorPicker onColorChange={setCor} initialColor={cor} />
                            </Stack>
                        </HStack>
                    </VStack>

                    <Divider my={6} />

                    <Box textAlign="right">
                        <Button fontSize={"sm"} px={7} variant="outline" size="md" mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button
                            fontSize={"sm"}
                            px={8}
                            size="md"
                            bg={"xmentor.primary"}
                            textColor={"white"}
                            _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
                            type="button"
                            onClick={HandleSubmit}
                            // isDisabled={!file}
                            isLoading={isSubmitting}
                        >
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </Container>
        </TemplateAdmin>
    );
}


export function ConfiguracaoMentoria() {
    return (
        <MasterProvider>
            <ConfiguracaoMentoriaChildren />
        </MasterProvider>
    );
}
