import { Flex, Button, Text } from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
interface IGoogleAuthContainer {
  cb: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
}
export default function GoogleAuthContainer(props : IGoogleAuthContainer) {
  return (
    <Flex
      w="100%"
      h="100%"
      justify={"center"}
      align={"center"}
      p={"24px"}
      borderRadius={"xl"}
    >
      <Flex
        w="100%"
        h="100%"
        justify={"center"}
        align={"center"}
        p={"24px"}
        bg={"#fff"}
        flexDir={"column"}
        gap={"24px"}
      >
        
        <Text fontWeight={'600'}>
          É necessário inicar a sessão com a Google para a utilização de alguns
          recursos.
        </Text>
        <Button onClick={props.cb} justifyContent={'center'} gap={'12px'}><FcGoogle/>Iniciar sessão com Google</Button>
      </Flex>
    </Flex>
  );
}
