import {
  Box, Flex, useDisclosure, useToast,
  Text,
  Button,
  Progress,
  VStack,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { Header } from "../Header";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import isAuthenticated from "../../../context/AuthProvider/util";
import apiXmentor from "../../../server/apiServer";
import Cookies from "js-cookie";
import { Input } from "../../Form/Input";
import logo from "../../../assets/logo.svg";
import { Sidebar } from "./Sidebar";
import { motion } from "framer-motion";

export interface Props {
  children: ReactNode;
  fixedHeader?: boolean;
  updateNotification?: () => void
}

export function TemplateMentorado({ children, fixedHeader, updateNotification }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen: isOpen2, onOpen, onClose } = useDisclosure();
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const json = Cookies.get("@xmentor-user-data");
    if (json) {
      const user = JSON.parse(json);
      if (user.questions.length > 0) {
        const value = user.questions;
        setQuestions(value);
        onOpen();
      }
      console.log(user);
    }

    if (!isAuthenticated()) {
      navigate('/');
    }
    const timer = setInterval(() => {
      if (!isAuthenticated()) {
        toast({
          position: 'top-right',
          description: "Sua sessão encerrou!",
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        navigate('/');
      }
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [navigate, toast]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Flex background="xmentor.background_template" minH={"100vh"}>
      {isAuthenticated() && (
        <>
          {isOpen2 ? (
            <TelaPerguntas questions={questions} onClose={onClose} />
          ) : (
            <>
              <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} setIsExpanded={setIsExpanded} isMenu={isExpanded} />
              <Flex
                direction="column"
                maxW={{ base: '100%', lg: isExpanded ? 'calc(100% - 312px)' : 'calc(100% - 80px)' }}

                flex="1"
                ml={{ base: 0, lg: isExpanded ? "312px" : "80px" }}
                transition="margin-left 0.3s ease"
              >
                <Header sendInfo={toggleSidebar} fixed={fixedHeader || false} isMenu={isExpanded} updateNotification={updateNotification ? updateNotification : () => { }} />
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                  <Box
                    overflow={"hidden"}
                    id="arrastadm"
                    p="4"
                    px={{ base: "16px", lg: "64px" }}
                  >
                    {children}
                  </Box>
                </motion.div>
              </Flex>
            </>
          )}
        </>
      )}
    </Flex>
  );
}

interface Pergunta {
  id: string;
  titulo: string;
  texto: string;
}

interface IProps {
  onClose: () => void;
  questions: Pergunta[];
}

function TelaPerguntas(props: IProps) {
  const [perguntaAtual, setPerguntaAtual] = useState(0);
  const [resposta, setResposta] = useState('');
  const [error, setError] = useState('');
  const [init, setInit] = useState(true);

  async function responderAnotacoes(
    id: string,
    resposta: string
  ) {
    try {
      const request = await apiXmentor.patch(`/anotacoes-admin`, {
        id,
        resposta
      });
      return request.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  function remove(id: string) {
    const json = Cookies.get("@xmentor-user-data");
    if (json) {
      const user = JSON.parse(json);
      if (user.questions.length > 0) {
        user.questions = user.questions.filter((i: Pergunta) => i.id !== id);
        Cookies.set("@xmentor-user-data", JSON.stringify(user));
      }
    }
  }

  const onClose = async () => {
    if (resposta.trim() === '' && perguntaAtual !== 0) {
      setError('Por favor, responda a pergunta antes de avançar.');
    } else {
      setError('');
      if (perguntaAtual <= props.questions.length - 1) {
        props.questions.map(async (pergunta, index) => {
          if (index === perguntaAtual - 1) {
            await responderAnotacoes(pergunta.id, resposta);
            remove(pergunta.id);
          }
        });

        setPerguntaAtual(perguntaAtual + 1);
        setResposta('');
      } else {
        props.questions.map(async (pergunta, index) => {
          if (index === perguntaAtual - 1) {
            await responderAnotacoes(pergunta.id, resposta);
            remove(pergunta.id);
          }
        });

        props.onClose();
      }
    }
  };

  const progressPercent = (perguntaAtual / (props.questions.length)) * 100;

  return (
    <Stack display="flex" w="100%" bg="xmentor.background_content">
      {!init && <Progress color={"xmentor.primary"} value={progressPercent} size='lg' />}
      {!init && <Flex mb={4}>
        <img height={300} width={220} src={logo} alt="XMENTOR" />
      </Flex>}
      <Box display="flex" minW="60%" m="auto" justifyContent="center" alignItems="center">
        {init ?
          <VStack textAlign="center" maxW="60%">
            <Flex justifyContent="center" mb={4}>
              <img height={300} width={220} src={logo} alt="XMENTOR" />
            </Flex>
            <Text fontSize="xx-large" fontWeight="bold">Bem vindo mentorado</Text>

            <Text>
              Para dar início à sua jornada de mentoria, gostaríamos de conhecê-lo melhor.   </Text>
            <Text>Por favor, responda ao questionário elaborado pelos administradores da mentoria.  </Text>
            <Text> Este é o primeiro passo para garantir que possamos oferecer a você a melhor experiência de mentoria possível.
            </Text>
            <Text>
              Agradecemos seu compromisso e entusiasmo em participar deste programa de mentoria. Estamos ansiosos para embarcar nesta jornada de crescimento e aprendizado junto com você!
            </Text>
            <Button mt={5} colorScheme="blue" px={28} bg="xmentor.primary" mr={3} onClick={() => {
              setPerguntaAtual((prev) => prev + 1);
              setInit(false);
            }}>
              Continuar
            </Button>
          </VStack>
          :
          <VStack w="100%" display="flex" justifyContent="flex-start" alignItems="flex-start">
            <HStack>
              <Text>{perguntaAtual} -</Text>
              <Text fontWeight="bold" color="xmentor.primary">{props.questions.length > 0 && props.questions[perguntaAtual - 1].titulo}</Text>
            </HStack>
            <Input
              label=""
              value={resposta}
              name="resposta"
              bg="xmentor.background_template"
              border="none"
              placeholder="Digite aqui..."
              onChange={(e) => setResposta(e.target.value)}
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {!init &&
              <Button px={16} colorScheme="blue" bg="xmentor.primary" mr={3} onClick={onClose}>
                {perguntaAtual < props.questions.length ? "Continuar" : "Enviar"}
              </Button>
            }
          </VStack>
        }
      </Box>
    </Stack>
  );
}