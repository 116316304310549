import apiXmentor from "../../server/apiServer"
import { IAtualizaEvento, IEvento, INewEvento } from "./types"


export async function criarEvento(form: INewEvento) {
  try {
    const request = await apiXmentor.post("/evento", form)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}

export async function consultaEvento(mentoria_id: string) {
  try {
    const request = await apiXmentor.get(`/evento/${mentoria_id}`)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}

export async function atualizarEvento(form: IAtualizaEvento){
  try {
    const request = await apiXmentor.put(`/evento`, form)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}

export async function deletarEvento(id: string) {
  try {
    const request = await apiXmentor.delete(`/evento/${id}`)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}
