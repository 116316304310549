import React from "react";

import { TemplateMentor } from "../../../components/layout/mentor/TemplateMentor";
import { ViewUser } from "../../admin/users/view";
import { MentoriaProvider } from "../../../context/MentoriaProvider";



export function ViewUserMentor() {

  return (
    <TemplateMentor>
      <MentoriaProvider>
        <ViewUser />
      </MentoriaProvider>
    </TemplateMentor>
  );
}
