import apiXmentor from "../../server/apiServer"
import { IConfigMentoria } from "./types"


export async function criarMentoria(form: FormData) {
  try {
    const request = await apiXmentor.post("/mentoria", form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}

export async function criarConfigMentoria(form: FormData) : Promise<IConfigMentoria> {
  try {
    const request = await apiXmentor.put("/mentoria-config-img", form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}

export async function atualizarConfigMentoria(id: string, nome: string,  cor: string){
  try {
    const request = await apiXmentor.put(`/mentoria-config`, {id, nome, cor})
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}


export async function consultaTipoConfigMentoria(id: string): Promise<IConfigMentoria> {
  try {
    const request = await apiXmentor.get(`/mentoria-config/${id}`)
    return request.data as IConfigMentoria
  } catch (error) {
    return Promise.reject(error)
  }  
}


export async function consultaMentoria(
  nome: string,
  pagina: number
) {
  try {
    const request = await apiXmentor.get(`/mentoria?&nome=${nome}&pagina=${pagina}`)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}

export async function atualizarMentoria(id: string, nome: string,  descricao: string){
  try {
    const request = await apiXmentor.put(`/mentoria`, {id, nome, descricao})
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}



export async function atualizarFotoMentoria(id: string, form: FormData){
  try {
    const request = await apiXmentor.patch(`/mentoria/${id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}

export async function deletarMentoria(id: string) {
  try {
    const request = await apiXmentor.delete(`/mentoria/${id}`)
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}

export async function consultaTipoMentoria() {
  try {
    const request = await apiXmentor.get("/mentoria/tipos")
    return request.data
  } catch (error) {
    return Promise.reject(error)
  }  
}