import React, { useEffect, useState } from "react";
import {
  Button,
  Circle,
  Flex,
  HStack,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import EventoCardMentorado from "../../../components/EventoCardMentorado";
import { GoPlus } from "react-icons/go";
import { EventoProvider } from "../../../context/EventoProvider";
import { useEvento } from "../../../context/EventoProvider/useEvento";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import { useNavigate } from "react-router-dom";
import { formatarHora } from "../../../utils/formatarData";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { CustomModalOverlay } from "../trilha/components/CustomModalOverlay";
import { IoWarningOutline } from "react-icons/io5";
import { ModalAddEvent } from "./register/modalAddEvent";
import { FaPlus } from "react-icons/fa";

function EventoChildren() {
  const { ConsultaEvento, eventos } = useEvento();
  const { payLoad } = useAuth();
  const navigate = useNavigate();
  const [idEvento, setIdEvento] = useState("");
  const [isModalDeleteEvento, setIsModalDeleteEvento] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    carregarEventos();
  }, [ ,isOpen]);

  const carregarEventos = async () => {
    try {
      await ConsultaEvento(payLoad?.user.mentoria_id || " ");
    } catch (error) {
      console.error("Erro ao carregar eventos:", error);
      // Adicione a lógica necessária para lidar com o erro, se necessário.
    }
  };

  return (
    <TemplateAdmin>
      <VStack align="start">
        <HStack w={"100%"} justifyContent={"space-between"}>
          <HStack spacing={5}>
            <Heading fontSize={24} my="24px" fontWeight={700}>
              Eventos
            </Heading>
            {/*<Tooltip
              label="Crie e gerencie todos os eventos em grupos que acontecerão em sua mentoria."
              fontSize="sm"
              borderRadius={"5px"}
            >
              <QuestionOutlineIcon fontSize={"20px"} />
            </Tooltip>*/}
          </HStack>
          <IconButton
            colorScheme="primary"
            bgColor={"xmentor.primary"}
            color={"white"}
            icon={<FaPlus />}
            aria-label="Edit"
            ml={{ base: 0, md: "auto" }}
            mt={{ base: 4, md: 0 }}
            onClick={onOpen}
          />
        </HStack>
        <Stack
          spacing={"24px"}
          gap={"24px"}
          flexWrap={"wrap"}
          direction={["column", "row"]}
          borderRadius={14}
          w="100%"
        >
          {eventos.length > 0 &&
            eventos.map((evento) => (
              <EventoCardMentorado
                key={evento.id}
                data={evento.data_evento || ""}
                horario={formatarHora(evento.data_evento) || ""}
                titulo={evento.nome || ""}
                descricao={
                  String(evento.duracao || "0") +
                  " min, " +
                  evento.descricao_instrucoes
                }
                onClick={() => {
                  setIdEvento(() => evento.id);
                  setIsModalDeleteEvento(true);
                }}
              />
            ))}
        </Stack>
      </VStack>
      <ModalDeleteEvento
        id={idEvento}
        isOpen={isModalDeleteEvento}
        onClose={() => setIsModalDeleteEvento(false)}
        mentoria_id={payLoad?.user.mentoria_id || " "}
      />
      {/* Modal para adicionar um novo evento */}
      <ModalAddEvent isOpen={isOpen} onClose={onClose} />
    </TemplateAdmin>
  );
}

export function Evento() {
  return (
    <EventoProvider>
      <EventoChildren />
    </EventoProvider>
  );
}

interface IModalDelete {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  mentoria_id: string;
}

function ModalDeleteEvento({ isOpen, onClose, id, mentoria_id }: IModalDelete) {
  const { DeletarEvento } = useEvento();

  const [isLoading, setIsLoading] = useState(false);
  async function handleDelete() {
    setIsLoading(true);
    await DeletarEvento(id, mentoria_id);
    onClose();
    setIsLoading(false);
  }
  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <CustomModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Circle bg="yellow.100" p={2}>
            <IoWarningOutline color="red" size={28} />
          </Circle>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>Deseja excluir o evento?</ModalBody>
        <ModalFooter gap={2}>
          <Button variant="outline" size="lg" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            size="lg"
            onClick={handleDelete}
            isLoading={isLoading}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
