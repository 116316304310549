import React, { useState } from "react";
import {
  Avatar,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Icon,
  MenuGroup,
  MenuDivider,
  Box,
  Flex,
} from "@chakra-ui/react";
import { FaRegCheckCircle } from "react-icons/fa";
import { IItemMentorado } from "..";
import { useMentoriaGestao } from "../../../../context/MentoriaGestaoProvider/useMentoriaGestao";
import { useNavigate } from "react-router-dom";
import { MentoradoChecklistModal } from "./MentoradoChecklistModal";
import { FaGear } from "react-icons/fa6";

export function ItemMentorado({
  foto,
  nome,
  mentoria_id,
  usuario_id,
  etapa,
  etapa_id,
}: IItemMentorado) {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);
  const navigate = useNavigate();
  const iconRotation = isMenuExpanded ? 180 : 0;
  const { etapasMentoria, AvancarEtapaMentoria } = useMentoriaGestao();

  // Função para alternar o estado do menu
  const handleMenuToggle = () => {
    setIsMenuExpanded(!isMenuExpanded);
  };

  // Função para abrir o modal de checklist
  const openChecklistModal = () => {
    setIsChecklistModalOpen(true);
  };

  // Função para fechar o modal de checklist
  const closeChecklistModal = () => {
    setIsChecklistModalOpen(false);
  };

  // Calcular quantos checkpoints foram concluídos para esta etapa específica do mentorado
  const etapaMentoria = etapasMentoria.find(
    (etapaItem) => etapaItem.titulo === etapa
  );
  const checkpointsConcluidos =
    etapaMentoria?.CheckListGestao.flatMap(
      (checklist) => checklist.CheckListGestaoUsuario
    ).filter((usuario) => usuario.check && usuario.usuario_id === usuario_id)
      .length || 0;

  // Calcular o total de checkpoints para esta etapa específica do mentorado
  const totalCheckpoints = etapaMentoria?.CheckListGestao.length || 0;

  return (
    <>
      <HStack
        cursor={"pointer"}
        onClick={openChecklistModal}
        w="100%"
        borderRadius={"14px"}
        justifyContent="space-between"
        transition="0.4s"
        _hover={{ backgroundColor: "gray.100" }}
      >
        <HStack w="full" p={2}>
          <Avatar
            mr="2"
            borderRadius={"14px"}
            src={`${process.env.REACT_APP_API}/tmp/user/${foto}`}
            h={"72px"}
            w="72px"
          />
          <Box display="flex" flexDirection="column">
            <Text color={"xmentor.cardsment"} fontSize={"15px"}>
              {nome}
            </Text>
            <HStack>
              {etapa !== "Início" &&
                etapaMentoria &&
                etapaMentoria.CheckListGestao.length > 0 && (
                  <>
                    {totalCheckpoints &&
                    checkpointsConcluidos === totalCheckpoints ? (
                      <Icon as={FaRegCheckCircle} color="green" />
                    ) : (
                      <Icon as={FaRegCheckCircle} color="xmentor.primary" />
                    )}
                    <Text fontSize="sm" color={"xmentor.cardsment"}>
                      {totalCheckpoints &&
                        `(${checkpointsConcluidos}/${totalCheckpoints})`}
                    </Text>
                  </>
                )}
            </HStack>
          </Box>
        </HStack>
        <Menu size="sm">
          <MenuButton
            variant={"ghost"}
            size={"sm"}
            as={Button}
            display={"flex"}
            alignItems={"center"}
            onClick={(e) => {
              handleMenuToggle(); // Use a função aqui
              e.stopPropagation(); // Adicione esta linha para interromper a propagação do evento de clique
            }}
          >
            <HStack>
              <Icon
                transition={"0.7s"}
                as={FaGear}
                boxSize={"20px"}
                transform={`rotate(${iconRotation}deg)`}
              />
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuGroup title="Mover mentorado">
              <MenuDivider />
              {etapasMentoria &&
                etapasMentoria.map((i, index) => (
                  <MenuItem
                    key={index}
                    px={4}
                    fontSize={"16px"}
                    onClick={(e) => {
                      AvancarEtapaMentoria(
                        etapa_id,
                        mentoria_id,
                        i.id,
                        usuario_id
                      );
                      e.stopPropagation();
                    }}
                  >
                    {i.titulo}
                  </MenuItem>
                ))}
            </MenuGroup>
          </MenuList>
        </Menu>
      </HStack>
      {/* Modal de Checklist */}
      <MentoradoChecklistModal
        isOpen={isChecklistModalOpen}
        onClose={closeChecklistModal}
        nomeMentorado={nome}
        mentoriaId={mentoria_id}
        usuarioId={usuario_id}
        foto={""}
      />
    </>
  );
}
