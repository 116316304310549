import { Divider, 
  Button, 
  Text, 
  Flex, 
  Icon, 
  Box, 
  useBreakpointValue, 
  Drawer, 
  DrawerOverlay, 
  DrawerContent, 
  DrawerCloseButton, 
  useTheme,
  Image } from "@chakra-ui/react";
import { FaCalendarAlt, FaHome, FaUserAlt } from "react-icons/fa";
import { RiSettings3Fill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { ReactNode, useState } from "react";
import { LogoSvg } from "../LogoSvg";
import { ButtonNavigation } from "../ButtonNavigation";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { motion } from "framer-motion";
import { LogoMenor } from "../LogoMenor";

interface Props {
  children?: ReactNode;
  isMenu?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsExpanded: (isExpanded: boolean) => void;
}

export function Sidebar({ children, setIsExpanded, isMenu, isOpen, setIsOpen }: Props) {
  const { logout, payLoad } = useAuth();
  const [isExpandedLocal, setIsExpandedLocal] = useState(false);
  const isVisible = useBreakpointValue({ base: isMenu ? "flex" : "none", lg: "flex" });
  const theme = useTheme();
  const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;
  const logoSidebar = payLoad?.configMentoria?.foto

  const handleMouseEnter = () => {
    setIsExpandedLocal(true);
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpandedLocal(false);
    setIsExpanded(false);
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={() => setIsOpen(false)}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <Flex
              flexDir="column"
              // background="#4963FF"
              background= {"xmentor.primary"}
              color="white"
              paddingTop={16}
              paddingBottom={16}
              height="100%"
              overflow="hidden"
            >
              <Flex
                flexDir="column"
                w="100%"
                padding="0px 16px"
                alignItems="center"
                position="relative"
              >
                {/* <LogoSvg /> */}
                {logoSidebar ? (
                  <Image
                    src={`${process.env.REACT_APP_API}/tmp/mentoria/${logoSidebar}`}
                    alt="Logo"
                    width={"70px"} // Ajusta o tamanho da imagem conforme o estado expandido
                    height={"70px"} // Ajusta a altura conforme o estado expandido
                  />
                ) : <LogoMenor />
                }
                {children && (
                  <Box position="absolute" right={0} top={0}>
                    {children}
                  </Box>
                )}
                <Divider my={5} opacity={0.2} />
              </Flex>
              <Flex
                padding="0px 16px"
                flexDirection="column"
                alignItems="flex-start"
                gap="16px"
                h="full"
              >
                <ButtonNavigation key={1} title="Início" url="/mentorado/home" icon={FaHome} isExpanded={true} />
                <ButtonNavigation key={2} title="Trilha da mentoriaaaa" url="/mentorado/trilha" icon={FaUserAlt} isExpanded={true} />
                <ButtonNavigation key={3} title="Programação" url="/mentorado/programacao" icon={FaCalendarAlt} isExpanded={true} />
                <ButtonNavigation key={4} title="Configurações" url="/mentorado/config" icon={RiSettings3Fill} isExpanded={true} />
              </Flex>
              <Flex flexDir="column" w="100%" alignItems="center">
                <Divider my={5} opacity={0.2} />
                <Button
                  display="flex"
                  padding={4}
                  alignItems="center"
                  color="white"
                  lineHeight="20px"
                  bg={"xmentor.primary"}
                  onClick={logout}
                  colorScheme="facebook"
                >
                  <Text mr={4}>Sair</Text>
                  <Icon as={FiLogOut} fontSize='20' />
                </Button>
              </Flex>
            </Flex>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <motion.div
        onHoverStart={handleMouseEnter}
        onHoverEnd={handleMouseLeave}
        initial={{ width: 80 }}
        animate={{ width: isExpandedLocal ? 312 : 80 }}
        transition={{ duration: 0.3 }}
        style={{
          display: isVisible,
          flexDirection: "column",
          // background: "#4963FF",
          background: theme.colors.xmentor.primary,
          colorScheme: "facebook",
          color: "white",
          paddingTop: 16,
          paddingBottom: 16,
          height: "100%",
          position: "fixed",
          zIndex: 2,
          overflow: "hidden"
        }}
      >
        <Flex
          flexDir="column"
          w="100%"
          padding="0px 16px"
          alignItems="center"
          position="relative"
        >
          {/* {isExpandedLocal ? <LogoSvg /> : <LogoMenor />} */}
          {logoSidebar ? (
            <Image
              src={`${process.env.REACT_APP_API}/tmp/mentoria/${logoSidebar}`}
              alt="Logo"
              width={isExpandedLocal ? "auto" : "50px"} // Ajusta o tamanho da imagem conforme o estado expandido
              height={isExpandedLocal ? "100px" : "50px"} // Ajusta a altura conforme o estado expandido
            />
          ) : isExpandedLocal ? (
            <LogoSvg />
          ) : (
            <LogoMenor />
          )}
          {children && isExpandedLocal && (
            <Box position="absolute" right={0} top={0}>
              {children}
            </Box>
          )}
          <Divider my={5} opacity={0.2} />
        </Flex>
        <Flex
          padding="0px 16px"
          flexDirection="column"
          alignItems={isExpandedLocal ? "flex-start" : "center"}
          gap="16px"
          h="full"
        >
          <ButtonNavigation key={1} title="Início" url="/mentorado/home" icon={FaHome} isExpanded={isExpandedLocal} />
          <ButtonNavigation key={2} title="Trilha da mentoria" url="/mentorado/trilha" icon={FaUserAlt} isExpanded={isExpandedLocal} />
          <ButtonNavigation key={3} title="Programação" url="/mentorado/programacao" icon={FaCalendarAlt} isExpanded={isExpandedLocal} />
          <ButtonNavigation key={4} title="Configurações" url="/mentorado/config" icon={RiSettings3Fill} isExpanded={isExpandedLocal} />
        </Flex>
        <Flex flexDir="column" w="100%" alignItems="center">
          <Divider my={5} opacity={0.2} />
          <Button
            display="flex"
            padding={4}
            alignItems="center"
            color="white"
            lineHeight="20px"
            background={"xmentor.primary"}
            _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
            onClick={logout}
            colorScheme="facebook"
          >
            {isExpandedLocal && <Text mr={4}>Sair</Text>}
            <Icon as={FiLogOut} fontSize='20' />
          </Button>
        </Flex>
      </motion.div>
    </>
  );
}
