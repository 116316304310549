import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAtualizaEvento, IContext, IEvento, IEventoProvider, INewEvento } from "./types";
import { useToast } from "@chakra-ui/react";
import { atualizarEvento, consultaEvento, criarEvento, deletarEvento } from "./util";

export const EventoContext = createContext<IContext>({} as IContext);

export const EventoProvider = ({ children }: IEventoProvider) => {
  const navigate = useNavigate();
  const [eventos, setEventos] = useState<IEvento[]>([])
  const toast = useToast();

  const showSuccessToast = (description: string) => {
    toast({
      position: 'top',
      description,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const showErrorToast = (error: any) => {
    toast({
      position: 'top',
      description: error.response?.data?.message || error || "Error",
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const ConsultaEvento = async (mentoria_id: string) => {
    try {
      const response = await consultaEvento(mentoria_id)
      setEventos(response)
    } catch (error) {
      showErrorToast(error);
    }
  }
  const CriarEvento = async (form: INewEvento) => {
    try {
      await criarEvento(form)
      showSuccessToast("Sucesso ao criar uma novo evento!")
      setTimeout(() => navigate("/admin/eventos"), 2000)
    } catch (error) {
      showErrorToast(error);
    } finally {
      await ConsultaEvento(form.mentoria_id)
      setTimeout(() => ConsultaEvento(form.mentoria_id), 1500)
    }
  }
  const AtualizarEvento = async (form: IAtualizaEvento) => {
    try {
      await atualizarEvento(form)
      showSuccessToast("Salvo com sucesso!")
      setTimeout(() => window.location.href = "/admin/eventos", 2000)
    } catch (error) {
      showErrorToast(error);
    }
  }
  const DeletarEvento = async (id: string, mentoria_id: string) => {
    try {
      const response = await deletarEvento(id)
      setEventos(response)
    } catch (error) {
      showErrorToast(error);
    } finally {
      await ConsultaEvento(mentoria_id)
      setTimeout(() => ConsultaEvento(mentoria_id), 700)
    }
  }
  return (
    <EventoContext.Provider value={{
      ConsultaEvento,
      CriarEvento,
      AtualizarEvento,
      DeletarEvento,
      eventos
    }}>
      {children}
    </EventoContext.Provider>
  );
};