import { Button, Flex, FormControl, FormLabel, HStack, Heading, Link, PinInput, PinInputField, Stack, Text, useToast } from "@chakra-ui/react";
import logo from "../assets/logo.svg";
import { Input } from "../components/Form/Input";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import isAuthenticated, { postCodeRecoverPassword, postCreatePassword } from "../context/AuthProvider/util";
import Cookies from "js-cookie";

interface Props{
  email: string
  password: string
}

export function ResetPassword() {
  const [ email, setEmail ] = useState('')
  const [ isCode, setIsCode ] = useState(false)
  const [ isSuccess, setIsSuccess ] = useState(false)
  const [ code,setCode ] = useState("")
  const [ pass, setPass ] = useState('')
  const [ confirmPass, setConfirmPass ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)


  const navigate = useNavigate()
  let isMobile = false  
  const toast = useToast()
  if (typeof window !== "undefined") {
    isMobile = window.innerWidth <= 768
  }
  const [login, setLogin] = useState<Props>({
    email: "",
    password: ""
  })

  const handleRemove = () => {
    setLogin({
      email: "",
      password: ""
    })
  };

  useEffect(()=>{
    if(isAuthenticated()){
      const result = JSON.parse(Cookies.get("@xmentor-user-data")||'null')
      console.log(result)
      // navigate('/')
      if(result.user.perfil == "mentorado"){
        navigate('/mentorado/home');
        return
      }
      if(result.user.perfil == "admin"){
        navigate('/admin/gestao');
        return
      }
      if(result.user.perfil == "mentor"){
        navigate('/mentor/home');
        return
      }
      if(result.user.perfil == "master"){
        navigate('/master/home');
        return
      }
    }
  },[])

  async function CodeRecoverPassword(email:string) {
    if(email.length > 0)
    await postCodeRecoverPassword(email)
    .then((response)=>{
      setIsCode(true)
      toast({
        position: 'top',
        description: "Um código foi enviado para seu E-MAIL",
        status: 'success',
        duration: 5000,
        isClosable: true,
      }) 
    })
    .catch((error)=>{
      toast({
        position: 'top',
        description: error.response.data.message,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })   
    })   
    setEmail('')  
    setIsLoading(false)    
  }

  async function NewPassword(code: string, pass: string, confirmPass: string) {
    if(code.length == 6)
    await postCreatePassword(code, pass, confirmPass)
    .then((response)=>{
      setIsSuccess(true)
      setIsCode(false)
      setTimeout(()=>navigate('/'),750)
    })
    .catch((error)=>{
      toast({
        position: 'top',
        description: error.response.data.message,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })   
    })   
    setCode('')      
    setPass('')      
    setConfirmPass('')  
    setIsLoading(false)     
  }
  return (
    <Flex
      w="100vw"
      h="100vh"
      align="center"
      justify="center"
    >
        
        <Flex
          w="100%"
          maxW={360}
          p="8"
          borderRadius={8}
          flexDir="column"
        >
          <Flex justifyContent="center" mb={4}>
            <img height={300} width={220} src={logo} alt="XMENTOR" />
          </Flex>
          <Text textAlign="center" mt={8} mb={8}>
            Recuperar senha
          </Text>
          <Stack spacing="4">
          {!isCode && !isSuccess &&
            <>
              <Input
                type="email" 
                name="email" 
                label="E-mail"  
                value={email}
                onChange={(e)=>setEmail(e.target.value)}                 
              />   
              
              <Button
                mt="3"
                colorScheme="blue"
                size="lg"        
                onClick={()=>{
                  CodeRecoverPassword(email)
                  setIsLoading(true)
                }}
                isLoading={isLoading}
              >ENVIAR</Button>
              
            </>          
          }

          {isCode  &&
            <>
            
              <FormControl>
                <FormLabel htmlFor="Código" >Código</FormLabel>
                <HStack>
                  <PinInput 
                    focusBorderColor="blue.500"                
                    id="Código" 
                    type='alphanumeric' 
                    value={code}
                    onChange={(e)=>setCode(e.toUpperCase())} 
                  >
                    <PinInputField borderColor="gray.500" />
                    <PinInputField borderColor="gray.500" />
                    <PinInputField borderColor="gray.500" />
                    <PinInputField borderColor="gray.500" />
                    <PinInputField borderColor="gray.500" />
                    <PinInputField borderColor="gray.500" />
                  </PinInput>
                </HStack>  
              </FormControl>
              
              <Input
                type="password" 
                name="password"   
                label="Nova senha"
                value={pass}
                onChange={(e)=>setPass(e.target.value)}                 
              /> 
              <Input
                name="password" 
                label="Confirme senha"
                type="password"
                value={confirmPass}
                onChange={(e)=>setConfirmPass(e.target.value)}                 
              /> 
              <Button                
                colorScheme="blue"
                size="lg"            
                onClick={()=>{
                  NewPassword(code, pass, confirmPass)
                  setIsLoading(true)
                }}
                isLoading={isLoading}
              >
                ENVIAR
              </Button>
              
            </>          
          }
          {isSuccess && 
            <Heading  mt={10} mb={10} size='lg' fontWeight='normal' fontFamily='fonts.body' >Senha atualizada!</Heading>
          }
           <Link onClick={()=>navigate('/')}> Faça login</Link> 
          </Stack>
        </Flex>      
    </Flex>
  );
}
