import React from "react";
import {
    Button, Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter
} from "@chakra-ui/react";
import { CustomModalOverlay } from "../pages/admin/gestao/components/CustomModalOverlay";
interface IModalTutorial {
    idYouTube: string
    isOpen: boolean
    onClose: () => void
}
export function ModalTutorial({ idYouTube, isOpen, onClose }: IModalTutorial) {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <CustomModalOverlay />
                <ModalContent>
                    <ModalHeader>Tutorial</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div>
                            <iframe width="100%" height="400" src={`https://www.youtube.com/embed/${idYouTube}?si=aBEo0yHaYrV2oziG`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
