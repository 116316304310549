import React, { useState } from "react";
import {
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Input,
  Text,
  Textarea,
  useToast,
  useTheme,
} from "@chakra-ui/react";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { IEvento, INewEvento } from "../../../../context/EventoProvider/types";
import { useEvento } from "../../../../context/EventoProvider/useEvento";

interface ModalAddEventType {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalAddEvent({ isOpen, onClose }: ModalAddEventType) {
  const { CriarEvento } = useEvento();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { payLoad } = useAuth();
  const toast = useToast();
  const theme = useTheme();
  const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;

  const [inputData, setInputData] = useState<INewEvento>({
    nome: "",
    data_evento: "",
    descricao_instrucoes: "",
    duracao: 0,
    mentoria_id: payLoad?.user.mentoria_id || "",
    usuario_criador_id: payLoad?.user.id || "",
  });

  const handleInputChange = (field: keyof IEvento, value: string) => {
    setInputData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSubmit = async () => {
    if (!inputData.nome) {
      toast({
        title: "Nome do evento inválido",
        description: "Por favor, insira o nome do evento.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!inputData.data_evento || !inputData.data_evento.includes("T")) {
      toast({
        title: "Data do evento inválida",
        description: "Por favor, selecione a data e hora do evento.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!inputData.descricao_instrucoes) {
      toast({
        title: "Descrição inválida",
        description: "Por favor, insira a descrição do evento.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!inputData.duracao || inputData.duracao <= 0) {
      toast({
        title: "Duração inválida",
        description: "Por favor, insira a duração do evento em minutos.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsSubmitting(true);
      await CriarEvento(inputData);
      setIsSubmitting(false);
      onClose();
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error in handleRegister:", err);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent rounded={"xl"}>
        <ModalHeader>Criar novo evento</ModalHeader>
        <ModalCloseButton mt={2} />
        <Divider />
        <ModalBody
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Stack spacing="4" w={"60%"} my="4">
            <Stack spacing={2} w="full">
              <Text fontSize="sm" fontWeight="bold" color="black">
                Nome do evento
              </Text>
              <Input
                name="name"
                type="text"
                fontSize={"sm"}
                bg={"xmentor.background_template"}
                rounded={"md"}
                placeholder="Insira o nome do evento"
                value={inputData.nome}
                onChange={(e) => handleInputChange("nome", e.target.value)}
              />
            </Stack>

            <Stack spacing={2} w="full">
              <Text fontSize="sm" fontWeight="bold" color="black">
                Descrição
              </Text>
              <Textarea
                name="description"
                fontSize={"sm"}
                bg={"xmentor.background_template"}
                rounded={"md"}
                placeholder="Insira a descrição do evento"
                maxLength={100}
                value={inputData.descricao_instrucoes}
                onChange={(e) =>
                  handleInputChange("descricao_instrucoes", e.target.value)
                }
              />
            </Stack>

            <Stack spacing={2} w="full">
              <Text fontSize="sm" fontWeight="bold" color="black">
                Data do evento
              </Text>
              <Input
                name="date-local"
                type="datetime-local"
                fontSize={"sm"}
                bg={"xmentor.background_template"}
                rounded={"md"}
                value={inputData.data_evento}
                onChange={(e) =>
                  handleInputChange("data_evento", e.target.value)
                }
              />
            </Stack>

            <Stack spacing={2} w="full">
              <Text fontSize="sm" fontWeight="bold" color="black">
                Duração (min)
              </Text>
              <Input
                name="duration"
                type="number"
                fontSize={"sm"}
                bg={"xmentor.background_template"}
                rounded={"md"}
                placeholder="Duração do evento (min)"
                value={inputData.duracao}
                onChange={(e) =>
                  setInputData((prevData) => ({
                    ...prevData,
                    duracao: Number(e.target.value),
                  }))
                }
              />
            </Stack>
          </Stack>
        </ModalBody>
        <Divider />
        <ModalFooter gap={2}>
          <Button
            fontSize={"sm"}
            px={7}
            variant="outline"
            size="md"
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            fontSize={"sm"}
            px={8}
            size="md"
            bg={"xmentor.primary"}
            textColor={"white"}
            _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
            type="submit"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Criar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
