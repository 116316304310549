import React, { useEffect, useState, FormEvent } from 'react';
import { VStack, Text, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, useDisclosure, Badge, Avatar, Circle, Table, Tbody, Td, Th, Thead, Tr, HStack, Textarea, Select, IconButton, Icon, Box } from '@chakra-ui/react';
import { FaPlus, FaRegCalendar } from 'react-icons/fa';
import { CustomModalOverlay } from '../../trilha/components/CustomModalOverlay';
import { IAcao } from '../../../../context/AuthProvider/types';
import { useAuth } from '../../../../context/AuthProvider/useAuth';
import { DownloadIcon } from '@chakra-ui/icons';
import { downloadFile } from '../../../../utils/downloadFile';
import { formatarData } from '../../../../utils/formatarData';
import { IoWarningOutline } from 'react-icons/io5';
import { FaDownload, FaRegTrashAlt, FaTrashAlt } from "react-icons/fa";

interface CardProps {
  actions: IAcao[];
}

export const CardAcoes: React.FC<CardProps> = ({ actions }) => {
  const { isOpen: isActionDetailsModalOpen, onOpen: onActionDetailsModalOpen, onClose: onActionDetailsModalClose } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAction, setSelectedAction] = useState<IAcao | null>(null);


  const handleRowClick = (action: IAcao) => {
    setSelectedAction(action);
    onActionDetailsModalOpen();
  };
  return (
    <VStack
      align={"start"}
      whiteSpace="pre-wrap"
      w={"100%"}
    >

      <Table variant={'simple'} w="100%" overflowX="auto">
        <Thead>
          <Tr>
            <Th px={0}>Nome da Tarefa</Th>
            <Th>Mentor</Th>
            <Th>Etapa</Th>
            <Th>Data de Validade</Th>
            <Th>Status</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {actions.map((action, index) => (
            <Tr key={index} onClick={() => handleRowClick(action)} cursor="pointer">
              <Td maxW="200px" px={0} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{action.titulo}</Td>
              <Td>
                <HStack>
                  <Avatar name={action.mentor.nome} src={`${process.env.REACT_APP_API}/tmp/user/${action.mentor.foto}`} size="sm" />
                  <Text>{action.mentor.nome}</Text>
                </HStack>
              </Td>
              <Td fontWeight="500">{action.etapa.titulo}</Td>
              <Td>
                <HStack>
                  <FaRegCalendar />
                  <Text mt={1}>{formatarData(action.data_fato)}</Text>
                </HStack>
              </Td>
              <Td>
                <Badge p={1} borderRadius={'8px'} colorScheme={action.status === false ? 'yellow' : 'green'}>
                  {action.status === false ? 'Pendente' : 'Concluído'}
                </Badge>
              </Td>
              <Td>
                <IconButton
                  icon={<FaRegTrashAlt />}
                  variant="ghost"
                  aria-label="Delete"
                  color={"xmentor.text_gray"}
                  ml={{ base: 0, md: "auto" }}
                  mt={{ base: 3, md: 0 }}
                  onClick={(event) => {
                    event.stopPropagation(); // Impede a propagação do evento de clique
                    setSelectedAction(action);
                    onOpen();
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>



      <Modal isCentered isOpen={isActionDetailsModalOpen} onClose={onActionDetailsModalClose}>
        <CustomModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes da Tarefa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedAction && (
              <>
                <Text fontWeight="bold">Nome da Tarefa:</Text>
                <Box bg="gray.100" p="10px 14px 10px 14px" rounded="8px">
                  <Text>{selectedAction.titulo}</Text>
                </Box>
                <Text mt={4} fontWeight="bold">Mentor:</Text>

                <HStack mb={5}>
                  <Avatar
                    src={`${process.env.REACT_APP_API}/tmp/user/${selectedAction.mentor.foto || ""}`}
                    w="48px"
                    h="48px"
                    bgColor="white"
                    borderRadius="50%"
                    alignSelf="center"
                    transition="0.5"
                  />
                  <Text fontWeight="bold">{selectedAction.mentor.nome}</Text>
                </HStack>


                <Text>{selectedAction.mentor.nome}</Text>
                <Text mt={4} fontWeight="bold">Data de Validade:</Text>

                <Box bg="gray.100" p="10px 14px 10px 14px" rounded="8px">
                  <Text>{formatarData(selectedAction.data_fato)}</Text>
                </Box>
                <Text mt={4} fontWeight="bold">Status:</Text>

                <Box bg="gray.100" p="10px 14px 10px 14px" rounded="8px">
                  <Text>{selectedAction.status === false ? 'Pendente' : 'Concluído'}</Text>
                </Box>
                {selectedAction.status && <>
                  {selectedAction.file === false ? (
                    <>
                      <Text mt={4} fontWeight="bold">Resposta do mentorado:</Text>
                      <Textarea mt='1' placeholder="" value={selectedAction.resposta} />
                    </>
                  ) : selectedAction.url &&
                  <>
                    <Text mt={4} fontWeight="bold">Resposta do mentorado:</Text>
                    <Text>
                      <IconButton
                        aria-label='Search database'
                        p={1}
                        mr={1}
                        size='60px'
                        icon={<Icon as={DownloadIcon} fontSize={20} />}
                        onClick={() => downloadFile({ src: `${process.env.REACT_APP_API}/tmp/step_attachment/${selectedAction.url}`, uri: String(selectedAction.url) })}
                      />
                      {selectedAction.url?.substring(String(selectedAction.url).indexOf('-') + 1)}
                    </Text>
                  </>
                  }
                </>
                }
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onActionDetailsModalClose} variant="outline">
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        idAtividade={String(selectedAction?.id)}
        idUser={String(selectedAction?.usuario_cliente_id)}
        name={String(selectedAction?.titulo)}
      />
    </VStack>
  );
};




interface IModalDeleteAtividade {
  isOpen: boolean;
  onClose: () => void;
  idAtividade: string;
  idUser: string;
  name: string;
}

function ModalDelete({ isOpen, onClose, idUser, idAtividade, name }: IModalDeleteAtividade) {
  const { DeletarAcao } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  async function handleDelete() {
    setIsLoading(true);
    await DeletarAcao(idAtividade, idUser);
    onClose();
    setIsLoading(false);
  }
  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <CustomModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Circle bg="yellow.100" p={2}>
            <IoWarningOutline color="red" size={28} />
          </Circle>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>Deseja excluir a atividade "{name}"?</ModalBody>
        <ModalFooter gap={2}>
          <Button variant="outline" size="lg" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="blue"
            size="lg"
            onClick={handleDelete}
            isLoading={isLoading}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
