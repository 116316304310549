import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Box,
  Button,
  HStack,
  Stack,
  FormControl,
  FormLabel,
  Heading,
  useToast,
  VStack,
  Flex,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { BodyContent } from "../../../components/content/body";
import { Input } from "../../../components/Form/Input";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { TemplateAdmin } from "../../../components/layout/admin/TemplateAdmin";
import { useLocation, useNavigate } from "react-router-dom";
import { UploadEditAvatar } from "../../../components/avatarUpload";
import { ModalAddMentorRegisterUser } from "./components/ModalAddMentorRegisterUser";
import { QuestionList } from "./components/QuestionList";

interface FormValues {
  nome: string;
  email: string;
  contato: string;
  perfil?: string | undefined;
  data_nascimento?: string | undefined;
  ocupacao?: string | undefined;
  nome_empresa?: string | undefined;
  observacao_finaceira?: string | undefined;
}

export function RegisterNewUser() {
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const resultRoute: any = location.state;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const { RegisterUser, payLoad } = useAuth();
  const [questions, setQuestions] = useState<string[]>([]);
  const [selectedMentores, setSelectedMentores] = useState<string[]>([]);
  const theme = useTheme();
  const darkenedPrimaryColor = theme.colors.xmentor.primaryDark;
  const primaryColor = theme.colors.xmentor.primary;
  const initialValues: FormValues = {
    email: "",
    nome: "",
    contato: "",
    perfil: resultRoute.perfil,
    data_nascimento: "",
    nome_empresa: "",
    ocupacao: "",
    observacao_finaceira: "",
  };

  const schema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
    contato: Yup.string().required("Campo obrigatório"),
    perfil: Yup.string(),
    data_nascimento: Yup.string(),
    nome_empresa: Yup.string(),
    ocupacao: Yup.string(),
    observacao_finaceira: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const HandleSubmit: SubmitHandler<FormValues> = async (data) => {
    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }
    formData.append("email", data.email);
    formData.append("nome", data.nome);
    formData.append("contato", data.contato);
    formData.append("observacao_finaceira", " ");
    formData.append("data_nascimento", data.data_nascimento || "");
    formData.append("ocupacao", data.ocupacao || "");
    formData.append("nome_empresa", data.nome_empresa || "");
    formData.append("redes_sociais_empresa", " ");
    formData.append("perfil", resultRoute.perfil);
    formData.append("questoes", questions.join("|"));
    formData.append("mentores", selectedMentores.join("|"));
    if (payLoad?.user.mentoria_id) {
      formData.append("mentoria_id", payLoad?.user.mentoria_id);
    }

    setIsSubmitting(true);
    try {
      await RegisterUser(formData);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    const maxSize = 5 * 1024 * 1024; // Define o tamanho máximo permitido (5 MB)
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];

    if (!allowedFileTypes.includes(file.type)) {
      toast({
        position: "top",
        description:
          "Tipo de arquivo inválido. São permitidos apenas arquivos JPEG, PNG e GIF.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (file.size > maxSize) {
      toast({
        position: "top",
        description: "Envie um arquivo menor que 5MB",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result as string);
      };
    }
  }, [file, toast]);

  return (
    <TemplateAdmin>
      <HStack display="flex" w="100%" justifyContent="space-between" mb={2}>
        <Heading size="md">Cadastro</Heading>
        <Button
          px={0}
          variant={"ghost"}
          leftIcon={<IoIosArrowRoundBack size={30} />}
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
      </HStack>
      <form onSubmit={handleSubmit(HandleSubmit)}>
        <BodyContent>
          <Stack
            display="flex"
            spacing={8}
            flexDirection={["column", "row"]}
            w="100%"
          >
            <FormControl>
              <VStack id="avatar" spacing={0}>
                <FormLabel mb={0} fontWeight={'bold'}>Foto do {resultRoute.perfil}</FormLabel>
                <UploadEditAvatar
                  handleFile={setFile}
                  imagePreview={previewUrl}
                />
              </VStack>
            </FormControl>
            <Stack gap="24px" display="flex" w="100%">
              <Flex direction={["column", "row"]} gap={7} >
                <Input
                  required={true}
                  minW="50%"
                  type="text"
                  label="Nome completo"
                  id="nome"
                  rounded={"md"}
                  {...register("nome")}
                  error={errors.nome}
                  onChange={(e) =>
                    setValue("nome", e.target.value, { shouldValidate: true })
                  }
                />

                <Input
                  required={true}
                  type="email"
                  id="email"
                  label="E-mail"
                  rounded={"md"}
                  {...register("email")}
                  error={errors.email}
                  onChange={(e) =>
                    setValue("email", e.target.value, { shouldValidate: true })
                  }
                />
              </Flex>

              <Flex direction={["column", "row"]} gap={7}>
                <Input
                  required={true}
                  minW="145px"
                  type="text"
                  id="contato"
                  label="Telefone"
                  rounded={"md"}
                  {...register("contato")}
                  error={errors.contato}
                  onChange={(e) =>
                    setValue("contato", e.target.value, {
                      shouldValidate: true,
                    })
                  }
                />
                <Input
                  type="date"
                  id="data_nascimento"
                  label="Nascimento"
                  rounded={"md"}
                  {...register("data_nascimento")}
                  error={errors.data_nascimento}
                  onChange={(e) =>
                    setValue("data_nascimento", e.target.value, {
                      shouldValidate: true,
                    })
                  }
                />
              </Flex>

              <VStack
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-start"
                w="100%"
              >
                {resultRoute.perfil !== "auxiliar" && (
                  <Flex direction={["column", "row"]}  w='100%' gap={7}>
                    <Input 
                    w={'100%'}
                      type="text"
                      id="ocupacao"
                      label="Ocupação(cargo)"
                      rounded={"md"}
                      {...register("ocupacao")}
                      onChange={(e) =>
                        setValue("ocupacao", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                    <Input
                      type="text"
                      w={'100%'}
                      id="nome_empresa"
                      label="Nome da empresa"
                      rounded={"md"}
                      {...register("nome_empresa")}
                      onChange={(e) =>
                        setValue("nome_empresa", e.target.value, {
                          shouldValidate: true,
                        })
                      }
                    />
                  </Flex>
                )}

                {resultRoute.perfil === "mentorado" && (
                  <VStack w="100%" mt={3}>
                    <ModalAddMentorRegisterUser
                      mentoria_id={payLoad?.user.mentoria_id || ""}
                      onMentoresChange={setSelectedMentores}
                    />
                    <QuestionList sendQuestionList={setQuestions} />
                  </VStack>
                )}
              </VStack>
            </Stack>
          </Stack>
          <Divider />
          <Stack direction="row" spacing={4} w="100%" justifyContent="flex-end">
            <Button variant="outline" size="lg" fontSize={"sm"} type="reset">
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              bg = {primaryColor} 
              size="lg"
              fontSize={"sm"}
              isDisabled={!file}
              isLoading={isSubmitting}
              _hover={{ background: darkenedPrimaryColor, color: "#E2E8F0" }}
            >
              Cadastrar
            </Button>
          </Stack>
        </BodyContent>
      </form>
    </TemplateAdmin>
  );
}
