import React, { useEffect, useState } from "react";
import { Box, Button, HStack, Input, InputGroup, InputLeftElement, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { InputSearch } from "../../../../components/Form/InputSearch";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { TemplateMentor } from "../../../../components/layout/mentor/TemplateMentor";

export function UsersMentoradoMentor() {
    const navigate = useNavigate()
    const { SearchUser, users, payLoad, FindUserById } = useAuth()
    const [page, setPage] = useState(0)
    const [search, setSearch] = useState("")
    const [name, setName] = useState("")
    const [idUser, setIdUser] = useState("")

    useEffect(() => {
        handlePage("", page)
    }, [])
    useEffect(() => {
        if (search.length >= 3) {
            handlePage(search, page)
        } else if (search.length == 0) {
            handlePage("", 0)
        }
    }, [search, page])
    function handlePage(search: string, page: number) {
        if (page >= 0)
            if (payLoad?.user.mentoria_id)
                SearchUser({
                    nome: search,
                    perfil: "mentorado",
                    pagina: page,
                    mentoria_id: payLoad?.user.mentoria_id
                })
    }

    return (
        <TemplateMentor>
            <VStack spacing={4} align={"flex-start"} mb={4}>
                <Text
                    fontSize="xl"
                    fontWeight={700}
                    fontStyle="normal"
                    lineHeight="normal"
                    letterSpacing={"-0.227px"}
                >Mentorados</Text>

                <InputSearch
                    placeholder="Pesquisa mentorado"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </VStack>

            <Box
                bg="#FFF"
                borderRadius="8px"
                border="1px solid #EAECF0"
            >
                <Table variant="simple" w="100%" >
                    <Thead >
                        <Tr justifyContent="space-between">
                            <Th>Nome</Th>
                            <Th>Email</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users &&
                            users.data.length > 0 &&
                            users.data.map((i) => {
                                return (
                                    <Tr
                                        cursor={'pointer'}
                                        transition={"0.5s"}
                                        _hover={{
                                            backgroundColor: "gray.100",
                                        }}
                                        key={i.id}
                                        justifyContent="space-between"
                                        onClick={async () => {
                                            await FindUserById(String(i.id))
                                            navigate("/mentor/users-mentorados/view", {
                                                state: {
                                                    id: i.id,
                                                    nome: i.nome,
                                                    email: i.email,
                                                    foto: i.foto,
                                                    perfil: "Mentorado"
                                                }
                                            })
                                        }}
                                    >
                                        <Td>{i.nome}</Td>
                                        <Td >{i.email}</Td>
                                        <Td></Td>
                                    </Tr>
                                );
                            })}
                    </Tbody>
                    {users && (
                        <Tfoot >
                            <Tr justifyContent="space-between">
                                <Th>
                                    <Button
                                        variant={"outline"}
                                        onClick={() => {
                                            setPage(page - 1);
                                            handlePage(search, page - 1);
                                        }}
                                        isDisabled={page === 0}
                                    >
                                        Anterior
                                    </Button>
                                </Th>
                                <Th textAlign="center">Página {page + 1} de {Math.ceil(users.total / 20)}</Th>
                                <Th textAlign="right">
                                    <Button
                                        variant={"outline"}
                                        onClick={() => {
                                            setPage(page + 1);
                                            handlePage(search, page + 1);
                                        }}
                                        isDisabled={Math.ceil(users.total / 20) === page + 1}
                                    >
                                        Próximo
                                    </Button>
                                </Th>
                            </Tr>
                        </Tfoot>
                    )}
                </Table>
            </Box>
        </TemplateMentor>
    )
}


