import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { TemplateMentorado } from "../../../../components/layout/mentorado/TemplateMentorado";
import { ArrowBackIcon } from "@chakra-ui/icons";
import EmailOptionCard from "../../../../components/cards/EmailOptionCard";

export function MentoradoEmailNotifications() {
  const onGoBackPressed = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
  };

  return (
    <TemplateMentorado>
      <Flex
        px={{ base: "16px", md: "64px" }}
        flexDirection={"column"}
        gap={"24px"}
      >
        <VStack w={"fit-content"} h={"fit-content"}>
          <Box position={"relative"} w={"100%"} h={"24px"}>
            <Button
              variant={"unstyled"}
              onClick={onGoBackPressed}
              w={"100%"}
              position={"absolute"}
              color={"GrayText"}
            >
              <HStack>
                <ArrowBackIcon />
                <Text fontWeight={"500"} color={"Metoria"}>
                  Configurações
                </Text>
              </HStack>
            </Button>
          </Box>
          <Text fontSize={"24px"} fontWeight={"700"}>
            Notificações por Email
          </Text>
        </VStack>
        <VStack mt={"24px"} gap={"12px"}>
          <EmailOptionCard value={true} cb={(e) => console.log(e)}>
            Permitir que receba notificações no E-mail
          </EmailOptionCard>
          <EmailOptionCard value={true} cb={(e) => console.log(e)}>
            Não me envie E-mails promocionais
          </EmailOptionCard>
          <EmailOptionCard value={true} cb={(e) => console.log(e)}>
            Permitir que receba notificações no WhatsApp
          </EmailOptionCard>
        </VStack>
      </Flex>
    </TemplateMentorado>
  );
}
