import { Avatar, Box, Center, Flex, Icon, IconButton, Input, Image, Text, HStack, useToast, VStack, Button, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react';
import { BsTrash } from 'react-icons/bs';
import { FaCloudUploadAlt, FaCloud, FaRegTrashAlt } from 'react-icons/fa';

import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { createRef, useEffect, useState } from 'react';

interface UploadProps {
  file: File | null;
  typesFile?: string
  previewUrl: string | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemove: () => void;
  brandColor: string;
  bg: string;
  borderColor: string;
  isIMG?: boolean;
  isFile?: boolean;
  multiple?: boolean;
}

interface UploadEditProps {
  imagePreview: string | null
  handleFile: (data: File) => void
}

export function UploadAvatar({ 
  file, 
  isFile, 
  typesFile, 
  previewUrl, 
  handleChange, 
  handleRemove, 
  brandColor, 
  bg, 
  borderColor, 
  isIMG,
  multiple
 }: UploadProps) {
  return (
    <>
      {previewUrl ? (
        !isFile ?
          <Center>
            <Box position="relative" textAlign="left">
              {!isIMG ?
                <Avatar src={previewUrl} minH={32} minW={32} me="10px" />
                :
                isIMG &&
                <Box position="relative">
                  <Image
                    src={previewUrl}
                    w={60}
                    h={60}
                    rounded="lg"
                    objectFit="cover"
                    position="absolute"
                    inset={0}
                    filter="blur(16px)"
                    zIndex={0}
                    transform="scale(1.03, 1.03)"
                  />
                  <Image
                    zIndex={100}
                    position="relative"
                    src={previewUrl}
                    w={60}
                    h={60}
                    rounded="lg"
                    objectFit="cover"
                  />
                </Box>
              }
              <IconButton
                onClick={handleRemove}
                isRound={true}
                variant='solid'
                colorScheme='red'
                aria-label='Done'
                size="xs"
                fontSize='10px'
                icon={<FaRegTrashAlt />}
                p={2}
                right={0}
                top={0}
                zIndex={100}
                position="absolute"
              />
            </Box>
          </Center>
          :
          <HStack textAlign="left" mr={8}>
            <Text>{file?.name}</Text>
            <IconButton
              onClick={handleRemove}
              isRound={true}
              variant='solid'
              colorScheme='red'
              aria-label='Done'
              size="xs"
              fontSize='10px'
              icon={<FaRegTrashAlt />}
              p={2}
              zIndex={100}
            />
          </HStack>
      ) : (
        <Flex
          align="center"
          justify="center"
          bg={bg}
          border="1px dashed"
          borderColor={borderColor}
          rounded={3}
          w="100%"
          cursor="pointer"
          p={5}
          position="relative"
        >
          <Input
            required
            type="file"
            accept={typesFile ? typesFile : ".jpg,.jpeg,.webp,.png"}
            onChange={handleChange}
            position="absolute"
            cursor="pointer"
            opacity={0}
            h="100%"
            multiple={multiple}
          />
          <Center flexDirection="column">
            <Icon as={FaCloudUploadAlt} w="80px" h="80px" color={"gray.300"} />
            <Flex justify="center" mx="auto" mb="12px">
              <Text fontSize="xs" fontWeight="700" color={brandColor}>
                Clique para adicionar ou arraste
              </Text>
            </Flex>
            <Text fontSize="xs" color={brandColor}>
              {/* PDF, jpg, jpeg e png (max. 800x400px) */}
            </Text>
          </Center>
        </Flex>
      )}
    </>
  );
};


export function UploadEditAvatar({ imagePreview, handleFile }: UploadEditProps) {
  const [zoom, setZoom] = useState(5)
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("#");
  const cropperRef = createRef<ReactCropperElement>();
  const toast = useToast();
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    setPreviewUrl(imagePreview && imagePreview)
  }, [imagePreview])


  const onChange = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop(); // Extrai a extensão do nome do arquivo
      setFileName(fileName);
    }
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const dataURLtoFile = (dataURL: string, fileName: string): File => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedDataUrl = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      setCropData(croppedDataUrl);
      const croppedImageFile = dataURLtoFile(croppedDataUrl, fileName);
      setFile(croppedImageFile);
      handleFile(croppedImageFile)
    }
  };
  const handleRemove = () => {
    setFile(null);
    setPreviewUrl(null);
    setImage("")
    setCropData("#")
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    const maxSize = 15 * 1024 * 1024; // Define o tamanho máximo permitido (15 MB)
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];

    if (!allowedFileTypes.includes(file.type)) {
      toast({
        position: "top",
        description: "Tipo de arquivo inválido. São permitidos apenas arquivos JPEG, PNG e GIF.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (file.size > maxSize) {
      toast({
        position: "top",
        description: "Envie um arquivo menor que 15MB",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      // const fileReader = new FileReader();
      // fileReader.readAsDataURL(file);
      // fileReader.onload = () => {
      //   setPreviewUrl(fileReader.result as string);
      // };
    }
  }, [file, toast]);

  return (
    <>
      {previewUrl ? (
        <Center>
          <Box position="relative" textAlign="left">
            <Image rounded={5} src={previewUrl} w="200px" h="200px" me="10px" objectFit={'cover'} />
            <IconButton
              onClick={handleRemove}
              isRound={true}
              variant='solid'
              colorScheme='red'
              aria-label='Done'
              size="xs"
              fontSize='10px'
              icon={<FaRegTrashAlt />}
              p={2}
              right={3}
              top={0}
              zIndex={100}
              position="absolute"
            />
          </Box>
        </Center>
      ) : (
        <div style={{ width: "100%" }}>
          {!image &&
            <Flex
              align="center"
              justify="center"
              border="1px dashed"              
              w="100%"
              h="230px"
              cursor="pointer"
              p={5}
              borderColor="gray.300"
              rounded={10}
              bg="xmentor.background_template"
              position="relative"
              _hover={{ opacity: 0.5 }}
            >
              <Input
                required
                type="file"
                accept={".jpg,.jpeg,.gif,.png"}
                onChange={onChange}
                position="absolute"
                cursor="pointer"
                opacity={0}
                h="100%"
              />
              <Center flexDirection="column">
                <Icon as={FaCloudUploadAlt} w="80px" h="80px" color={"gray.300"} />
                <Flex justify="center" mx="auto" mb="12px">
                  <Text fontSize="xs" fontWeight="700" >
                    Clique para adicionar ou arraste
                  </Text>
                </Flex>
                <Text fontSize="xs" >
                  {/* PDF, jpg, jpeg e png (max. 800x400px) */}
                </Text>
              </Center>
            </Flex>
          }
          {image && <>
            <VStack>
              <Cropper
                ref={cropperRef}
                style={{ height: 200, width: "100%" }}
                zoomTo={zoom / 10}
                initialAspectRatio={1}
                aspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={200}
                minCropBoxWidth={200}
                cropBoxResizable={false}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
              />
            </VStack>
            <Slider min={0} max={10} aria-label='slider-ex-1' value={zoom} onChange={(e) => setZoom(e)}  >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Center >
              <VStack >{/* 
                  {cropData &&
                    <Image rounded={5} w={200} h={200} src={cropData} />
                  } */}
                <HStack>
                  <Button variant="outline" onClick={getCropData} colorScheme='blue'>
                    Cortar imagem
                  </Button>
                  <Button variant="outline" onClick={() => {
                    handleRemove()
                  }} colorScheme='red'>
                    Cancelar
                  </Button>
                </HStack>
              </VStack>
            </Center>
          </>
          }
        </div>
      )}
    </>
  );
}