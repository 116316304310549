import { saveAs } from 'file-saver';
interface Props {
    src: string;
    uri: string;
}
export function downloadFile({src, uri}: Props) {
    const typeFile = uri.split('.').pop()?.toLowerCase()
    let type = '';
   
    const url = src
    try {
        fetch(url)
        .then(response => response.blob())
        .then(blob => {            
            const file = new File([blob], uri.substring(String(uri).indexOf('-') + 1), { type: type });
            saveAs(file);
        });
    } catch (error) {
        console.log(error)
    }
    
}